<div class="verifyEmailContainer" *ngIf="verifyEmailError">
  <div class="col-md-8 col-sm-6 verifyemail_container">
    <div id="emailVerifiedMsg" *ngIf="emailAlreadyVerifiedError">
      <p class="email_success_v">
        <img src="assets/icons/warning_red.png" alt="warningIcon"
             class="warningIconEvent"/>
        <span class="email_verified_container">{{
          "userRegistration.emailVerification.alreadyVerified"
            | cxTranslate
          }}<a href="javascript:void(0)"
               (click)="redirectToLogin()">{{"userRegistration.emailVerification.signIn" | cxTranslate}}</a></span>
      </p>
    </div>
    <div id="emailIsNotExistMsg" *ngIf="emailNotRegisteredError">
      <p class="email_success_v">
        <img src="assets/icons/warning_red.png" alt="warningIcon"
             class="warningIconEvent"/>
        <span class="email_verified_container">{{
          "userRegistration.emailVerification.emailNotRegistered"
            | cxTranslate
          }}</span>
      </p>
    </div>

    <form
      [formGroup]="emailVerificationForm"
      id="emailInputBox"
      (ngSubmit)="sendVerificationEmail()"
    >
      <div class="col-xs-12 col-sm-12 col-md-8 p0 Fields-container">
        <label for="email"
               class="Fields-label verifyEmailLabel">{{"userRegistration.emailVerification.emailLabel" | cxTranslate}}</label>
        <input id="verifyEmail" name="verifyEmail" data-valid="valid_mail" type="text" class="Fields-input"
               data-view="newemail" formControlName="email" value="" autocomplete="off">
        <span
          *ngIf="
                !!emailVerificationForm.get('email').value &&
                emailVerificationForm.get('email').invalid &&
                emailVerificationForm.get('email').touched
              "
          class="defaultMessage"
          role="alert"
          aria-live="assertive"
        >{{
          "userRegistration.userInformation.email.invalidEmail"
            | cxTranslate
          }}</span
        >
        <span
          *ngIf="
                emailVerificationForm.get('email').errors?.required &&
                !emailVerificationForm.get('email').valid &&
                emailVerificationForm.get('email').touched
              "
          class="defaultMessage"
          role="alert"
          aria-live="assertive"
        >{{
          "userRegistration.userInformation.requiredMsg" | cxTranslate
          }}
            </span>

      </div>
      <button type="submit" class="resendVerifyEmailButton btn btn-default blue-button">
        {{"userRegistration.emailVerification.sendEmail" | cxTranslate}}
      </button>
    </form>
    <div id="emailSentMsg" *ngIf="verifyEmailSent">
      <p class="email_success_v">
        <span class="email_success_container">{{"userRegistration.emailVerification.emailSent" | cxTranslate}}</span>
      </p>
    </div>

  </div>
</div>
