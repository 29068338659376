import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input, OnDestroy, OnInit,
  ViewChild
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { CsAgentAuthService } from '@spartacus/asm/root';
import {
  GlobalMessageService, GlobalMessageType,
  LanguageService,
  OccEndpointsService,
  RoutingService,
  TranslationService
} from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Subscription } from 'rxjs';
import * as _ from 'underscore';
import { CustomAddToCartService } from '../../../custom-add-to-cart/custom-add-to-cart.service';
import { ProductDetailsComponent } from '../../../custom-add-to-cart/custom-add-to-cart/product-details/product-details.component';
import { CustomInfluenzaService } from '../../../custom-influenza/custom-influenza.service';
import { ProductDetailsInfluenzaComponent } from '../../../custom-influenza/product-details-influenza/product-details-influenza.component';
import { MakeAPaymentService } from '../../../custom-make-a-payment/make-a-payment.service';
import { CustomPdpService } from '../../../custom-pdp/custom-pdp.service';
import { CustomProfileAndPreferencesService } from '../../../custom-profile-and-preferences/custom-profile-and-preferences.service';
import { DataLayerService } from '../../services/data-layer.service';
import { AddCardComponent } from '../add-card/add-card.component';
import { AddCardService } from '../add-card/add-card.service';
import { CheckoutService } from '../checkout/checkout.service';
import { FileUploadService } from '../file-upload/file-upload.service';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss'],
})
export class CheckoutComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() cartDetails: any;
  @Input() stdCartType: boolean;
  @Input() accountLocData: any;
  @Input() activeSeasonId: any;

  @ViewChild('continueCheckout', { static: false })
  private readonly continueCheckout;
  sessionStorage = sessionStorage;
  paymentsArray: any = [];
  chosenPaymentMethod = 0;
  chosenCard: any;
  screenwidth: any;
  selectedHeader: any = 'Checkout';
  cartId: any;
  userId: any;
  standardCartDetails: any;
  cartContainsDirectProducts = false;
  cartContainsWholesalerProducts = false;
  productEntries: any = [];
  public images: any;
  updateEntriesData: any = [];
  subTotal: number;
  baseUrl: string;
  editNotClicked = true;
  userCardDataArray: any = [];
  userTemporaryCard: any;
  editStdForm = new FormGroup({
    firstName: new FormControl('', [
      Validators.required,
      Validators.pattern("^[a-zA-Z0-9-' ]+('[a-zA-Z0-9-])?[-a-zA-Z0-9]*$"),
    ]),
    mobile: new FormControl('', [Validators.required,
    Validators.pattern('^[0-9]{8,10}$'),
    ]),
    // mobile: new FormControl('', [Validators.required,
    // Validators.pattern('^[0-9]{4}-[0-9]{2}-[0-9]{2}-[0-9]{2}$'),
    // ]),
    phone: new FormControl('', [
      Validators.pattern('^[0-9]{8,10}$'),
    ]),
    // phone: new FormControl('', [
    //   Validators.pattern('^[0-9]{3}-[0-9]{2}-[0-9]{2}-[0-9]{2}$'),
    // ]),
    emailId: new FormControl(''),
    newemailId: new FormControl('', [Validators.pattern('^[a-z0-9_.]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
  });
  userCardData: any = [];
  childCarts: any = [];
  orderContact: any;
  emailsArray: any = [];
  estimatedDate: any = '';
  getuserData: any;
  userAccountgetData: any;
  selectedData: any;
  fallbackImage = '../../assets/i18n-assets/sanofi_missing_product.jpg';
  chosenEmail: any;
  orderNo: string;
  billingAddress: any;
  soldToAddress: any;
  selectedPaymentMethod: any;
  selectedcardHolderName: string;
  selectedcardNumber: string;
  selectedcardType: string;
  selectedexpDate: string;
  totalInfluenzaProducts: any = 0;
  totalInfluenzaDoses: number = 0;
  displayPaymentMethod: any;
  deleteEmail: boolean;
  usergetDataObj: any;
  disabled = true;
  addCurrentcard = false;
  freightProduct = false;
  @ViewChild('myPopover') myPopover: NgbPopover;
  userFname: any;
  userLname: any;
  userEmail: any;
  productsForExcel: any = [];
  checkoutSubscriber: any;
  hidePaymentMethod = false;
  langSelected: any;
  activeSeasonSubscriber: any;
  cardExpiredFlag = false;
  influenzaCartIdSubscriber: any;
  influenzaCreateCartSubscriber: any;
  addCardSubscriber: any;
  userAccountSubscriber: any;
  userAccountDetailsSubscriber: any;
  stdChekoutSubscriber: any;
  cardDetailsPfSubscriber: any;
  updateContactSubscriber: any;
  updatedStdOrderSubscriber: any;
  updatedInfluenzaOrderDetailsSubscriber: any;
  emailSubscriber: any;
  prodCodeSubscriber: any;
  uploadedFile: any;
  asmAgentSub: Subscription;
  agentEmulating: any;
  accountIdSubscription: Subscription;
  creditLimitDateSubscription: Subscription;
  userType = sessionStorage.getItem('accountType');
  showcreditlimiterror = false;
  constructor(
    private readonly influenzaService: CustomInfluenzaService,
    private readonly checkoutService: CheckoutService,
    private readonly user: UserAccountFacade,
    private readonly addToCartService: CustomAddToCartService,
    private readonly ref: ChangeDetectorRef,
    private readonly modalService: NgbModal,
    private readonly pfService: CustomProfileAndPreferencesService,
    private readonly router: RoutingService,
    private readonly occEndPoint: OccEndpointsService,
    private readonly pdpService: CustomPdpService,
    private readonly addCardService: AddCardService,
    private readonly globalMessage: GlobalMessageService,
    private readonly translation: TranslationService,
    private readonly lang: LanguageService,
    private readonly _csAgentAuthService: CsAgentAuthService,
    private readonly _fileUpload: FileUploadService,
    private readonly dataLayerService: DataLayerService,
    private readonly makeAPaymentserv: MakeAPaymentService
  ) {
    this.screenwidth = window.screen.width;
  }
  ngDoCheck() {
    if (this.userTemporaryCard && this.addCurrentcard) {
      this.chosenCard = 0;
      this.addCurrentcard = false;
    }
  }
  ngOnInit() {
    this.showcreditlimiterror = false;
    this.lang.getActive().subscribe((res) => {
      if (res) {
        this.langSelected = res;
        this.paymentsArray = [];
        if (res === 'en') {
          this.paymentsArray = [
            { name: 'Bill Me Later', code: 0 },
            { name: 'Pay by Card', code: 1 },
            { name: 'Manage Payment Methods', code: 2 },
          ];
        } else {
          this.paymentsArray = [
            { name: 'Facturez-moi plus tard', code: 0 },
            { name: 'Payer par carte', code: 1 },
            { name: 'Gérer méthodes de paiement', code: 2 },
          ];
        }
        this.displayPaymentMethod = this.paymentsArray.find((item) => item.code === 0).name;
      }
    });
    this.baseUrl = this.occEndPoint.getBaseUrl({
      baseUrl: true,
      prefix: false,
      baseSite: false,
    });
    this.user.get().subscribe((res: any) => {
      if (res) {
        this.userId = res?.userName;
        this.userFname = res?.firstName;
        this.userLname = res?.lastName;
        this.userEmail = res?.email;
        this.influenzaService.getCartIdofUser(this.userId);
        this.influenzaCartIdSubscriber = this.influenzaService.cartIdActiveOfUser.subscribe((cartIdData) => {
          if (cartIdData) {
            if (cartIdData?.carts?.length > 0) {
              const cartIdResponse = cartIdData?.carts;

              let activeCartIdArray = []
              activeCartIdArray = cartIdResponse?.filter((item) => item?.saveTime === undefined);
              this.cartId = activeCartIdArray[activeCartIdArray?.length - 1]?.code;

            }
            // else {
            //   this.influenzaService.CreateCartIdofUser(this.userId);
            //   this.influenzaCreateCartSubscriber = this.influenzaService.createCartOfUser.subscribe((responseCartId) => {
            //     if (responseCartId) {
            //       this.cartId = responseCartId?.code;
            //     }
            //   });
            // }

          }
        });
        if(this.userType == 'DIRECT'){
            this.accountIdSubscription = this.makeAPaymentserv.getAccountId(this.userId).subscribe((resp) => {
            if (resp) {
              this.makeAPaymentserv.getCreditLimit({
                accountId: resp?.defaultB2BUnit?.uid,
                fields: 'DEFAULT',
              });
            }
          });
        }
        this.settingStdCartAccountDetals();
        this.addToCartService.getAllCarts(res?.userName);
        if (this.stdCartType) {
          this.stdCartCheckoutDetails();

        } else {
          this.influenzaCartCheckoutDetails();
        }
      }
    });

    if(this.userType == 'DIRECT'){
      this.creditLimitDateSubscription = this.makeAPaymentserv.creditLimitDate$.subscribe((res) => {
        if (res !== false && res.length !== 0) {
          this.showcreditlimiterror = res.showMsg;
          this.ref.detectChanges();
        }
      });
    }
    
    this.chosenPaymentMethod = 0;
    this.addCardSubscriber = this.addCardService.cardAdded.subscribe((res) => {
      if (res) {
        if (!res?.defaultPayment) {
          let card: any;
          if (this.langSelected === 'fr') {
            card = `${res?.cardType?.code} Carte(XXXX-XXXX-XXXX-${res?.cardNumber.substring(6, 10)})`;
          } else {
            card = `${res?.cardType?.code} Card(XXXX-XXXX-XXXX-${res?.cardNumber.substring(6, 10)})`;
          }
          this.userTemporaryCard = {
            name: card,
            code: 0,
            cardHolderName: res?.accountHolderName,
            cardNumber: res?.cardNumber,
            cardType: res?.cardType?.code,
            expDate: res?.validTo,
            creditCardDefault: false
          };
          this.addCurrentcard = true;
        }
      }
    });
    this.asmAgentSub = this._csAgentAuthService.isCustomerEmulated().subscribe((res)=> {
      this.agentEmulating = res;
    })
  }
  ngAfterViewInit(): void {
    if (!this.stdCartType) {
      this.myPopover.open();
    }
  }

  getUploadFile(file): void {
    this.uploadedFile = file;
  }

  ngOnDestroy() {
    this.influenzaCartIdSubscriber?.unsubscribe();
    this.influenzaCreateCartSubscriber?.unsubscribe();
    this.addCardSubscriber?.unsubscribe();
    this.activeSeasonSubscriber?.unsubscribe();
    this.checkoutSubscriber?.unsubscribe();
    this.userAccountSubscriber?.unsubscribe();
    this.userAccountDetailsSubscriber?.unsubscribe();
    this.stdChekoutSubscriber?.unsubscribe();
    this.cardDetailsPfSubscriber?.unsubscribe();
    this.updateContactSubscriber?.unsubscribe();
    this.updatedStdOrderSubscriber?.unsubscribe();
    this.updatedInfluenzaOrderDetailsSubscriber?.unsubscribe();
    this.emailSubscriber?.unsubscribe();
    this.prodCodeSubscriber?.unsubscribe();
    this.asmAgentSub?.unsubscribe();
    this.accountIdSubscription?.unsubscribe();
    this.creditLimitDateSubscription?.unsubscribe();
  }
  orderNoUpdate() {
    this.dwnloadAsExcel();
  }
  creditlimiterrorclose(){
    this.showcreditlimiterror = false;
  }

  dwnloadAsExcel() {

    this.productsForExcel = [];

    this.productEntries.forEach((entries, index) => {
      this.productsForExcel.push({
        'Customer': this.standardCartDetails?.shipToParty?.billingAddress?.unitID,
        'Material ID': entries?.product?.code,
        'Quantity': entries?.quantity,
        'PO Number': this.orderNo
      });
      if (index + 1 === this.productEntries.length) {
        this.influenzaService.dataForExcel = this.productsForExcel;

      }
    });




  }
  influenzaCartCheckoutDetails() {
    this.influenzaService.getActiveSeasonsFromDb(this.userId);
    this.activeSeasonSubscriber = this.influenzaService.activeSeaons.subscribe((data) => {
      if (data) {

        this.activeSeasonId = data?.categoryData?.[0]?.code;
        this.activeSeasonSubscriber?.unsubscribe();
      }
    });
    this.checkoutSubscriber = this.influenzaService.checkoutInfluenza$.subscribe((res: any) => {
      if (res) {
        let stdCartDetails = [];
        stdCartDetails = res?.childCarts;
        if (stdCartDetails) {
          stdCartDetails = stdCartDetails.filter(
            (item) => item.cartType === 'INFLUENZA'
          );
          this.standardCartDetails = Object.assign({}, ...stdCartDetails);
          this.orderContact = this.standardCartDetails.orderContact;
          for (const cartDetails of stdCartDetails) {
            for (let entries of cartDetails.entries) {
              if (entries?.product?.onsn === 'ON') {
                this.estimatedDate = entries?.estimatedDeliveryDate;

              }
              if (entries?.freightProduct) {
                this.freightProduct = entries?.freightProduct;
              }
              const imagesFormat = _.clone(
                _.findWhere(entries.product?.images, { format: 'thumbnail' })
              );
              if (imagesFormat) {
                imagesFormat.url = `${this.baseUrl}${imagesFormat?.url}`;
              }
              this.images = { PRIMARY: imagesFormat };
              entries = { ...entries, ...this.images };
              this.totalInfluenzaProducts = 0;
              this.totalInfluenzaDoses = 0;
              this.productEntries.push(entries);

            }
          }
          let onsnCounter = 0;
          this.productEntries?.forEach((element, index) => {
            if (element?.product?.onsn === 'ON') {
              onsnCounter += 1;
            }
            this.totalInfluenzaProducts += parseInt(element.quantity);
            const a = parseInt(element.quantity);
            const b = parseInt(element.product.quantityPerUnit);
            this.totalInfluenzaDoses = this.totalInfluenzaDoses + (a * b);

            if (index + 1 === this.productEntries.length) {
              if (onsnCounter === 0) {
                this.hidePaymentMethod = true;
                this.ref.detectChanges();
              }
              if (this.standardCartDetails?.shipToParty) {
                this.selectedData = this.standardCartDetails?.shipToParty?.shippingAddresses.filter((item) => item.unitID === this.standardCartDetails.shipToParty.uid);
                this.selectedData = _.first(this.selectedData);
              } else {
                if (this.getuserData && this.getuserData?.length > 0) {
                  this.selectedData = _.first(this.getuserData.filter((item) => item.unitID === this.userAccountgetData?.uid));
                }
              }
              this.dwnloadAsExcel();
            }

            this.ref.detectChanges();
          });
          this.ref.detectChanges();
        }
      }
      this.checkoutSubscriber?.unsubscribe();
    });
  }
  settingStdCartAccountDetals() {
    this.checkoutService.getUserAccountDataFromDb(this.userId);
    this.userAccountSubscriber = this.checkoutService.userAccountData.subscribe((userData) => {
      if (
        userData.res != null &&
        userData.res !== undefined &&
        userData.res?.length !== 0
      ) {
        this.usergetDataObj = userData.res.defaultB2BUnit;
        const accountId = this.usergetDataObj?.uid;
        //this.getCardDetailsForStdCart(accountId);
        this.checkoutService.getUserAccountDetailsDataFromDb(
          this.userId,
          accountId
        );
      }
    });
    this.userAccountDetailsSubscriber = this.checkoutService.userAccountDetails$.subscribe((userDataget) => {
      if (userDataget) {
        this.getuserData = userDataget.res.shippingAddresses;

      }
    });
    this.ref.detectChanges();

  }
  stdCartCheckoutDetails() {

    this.stdChekoutSubscriber = this.addToCartService.checkoutDetails$.subscribe((res: any) => {
      if (res) {
        let stdCartDetails = [];
        this.productEntries = [];
        stdCartDetails = res?.childCarts;
        if (stdCartDetails) {
          stdCartDetails = stdCartDetails.filter(
            (item) => item.cartType === 'STANDARD'
          );
            this.dataLayerService.beginCheckoutDataLayer(
              stdCartDetails,
              'standard'
            );
          this.standardCartDetails = Object.assign({}, ...stdCartDetails);
          this.orderContact = this.standardCartDetails.orderContact;
          for (const cartDetails of stdCartDetails) {
            for (let entries of cartDetails.entries) {
              if (this.estimatedDate === '' || this.estimatedDate == null)
              {
                this.estimatedDate = entries?.estimatedDeliveryDate;
              }
              if (entries?.freightProduct) {
                this.freightProduct = entries?.freightProduct;
              }
              const imagesFormat = _.clone(
                _.findWhere(entries.product?.images, { format: 'thumbnail' })
              );
              if (imagesFormat) {
                imagesFormat.url = `${this.baseUrl}${imagesFormat?.url}`;
              }
              this.images = { PRIMARY: imagesFormat };
              entries = { ...entries, ...this.images };
              if (!this.cartContainsWholesalerProducts && !!entries?.wholesalerProduct) {
                this.cartContainsWholesalerProducts = true;
              } else if (!this.cartContainsDirectProducts && !entries?.wholesalerProduct) {
                this.cartContainsDirectProducts = true;
              }
              this.productEntries.push(entries);
            }
          }
          if (this.standardCartDetails?.shipToParty) {
            this.selectedData = this.standardCartDetails?.shipToParty?.shippingAddresses.filter((item) => item.unitID === this.standardCartDetails.shipToParty.uid);
            this.selectedData = _.first(this.selectedData);
          } else {
            if (this.getuserData && this.getuserData?.length > 0) {
              this.selectedData = _.first(this.getuserData.filter((item) => item.unitID === this.userAccountgetData?.uid));
            }
          }
          this.ref.detectChanges();
        }
      }
    });
  }
  getCardDetailsForStdCart(accountId) {
    this.selectedcardHolderName = '';
    this.selectedcardNumber = '';
    this.selectedcardType = '';
    this.selectedexpDate = '';
    this.selectedPaymentMethod = 'BILL_ME_LATER';

    this.pfService.getCardFromDb(accountId);

    this.cardDetailsPfSubscriber = this.pfService.cardDetails.subscribe((data) => {
      if (data) {
        this.userCardDataArray = [];
        if (this.userTemporaryCard) {
          this.userCardDataArray.push(this.userTemporaryCard);
        }
        this.userCardData = data.sanofiPaymentsResponseList;
        if (this.langSelected === 'fr') {
          if (this.userCardData.length > 0) {
            this.userCardData.forEach((element, index) => {
              const card = `${element.cardType} Carte(XXXX-XXXX-XXXX-${element.cardNumber.substring(6, 10)})`;
              const objCard = {
                name: card,
                code: index + 1,
                cardHolderName: element.creditCardName,
                cardNumber: element.cardNumber,
                cardType: element.cardType,
                expDate: element.expDate,
                creditCardDefault: element.creditCardDefault
              };
              this.userCardDataArray.push(objCard);
            });

          }

        }
        else {
          if (this.userCardData.length > 0) {
            this.userCardData.forEach((element, index) => {
              const card = `${element.cardType} Card(XXXX-XXXX-XXXX-${element.cardNumber.substring(6, 10)})`;
              const objCard = {
                name: card,
                code: index + 1,
                cardHolderName: element.creditCardName,
                cardNumber: element.cardNumber,
                cardType: element.cardType,
                expDate: element.expDate,
                creditCardDefault: element.creditCardDefault,
                cardExpired: element.cardExpired
              };
              this.userCardDataArray.push(objCard);
            });

          }
        }
        if (!this.userTemporaryCard) {
          this.chosenCard = this.userCardDataArray.find(
            (item) => item.creditCardDefault === true
          ).code;
        } else {
          this.chosenCard = 0;
        }
        let sltdCardDeatils = this.userCardDataArray.filter(
          (item) => item.code === this.chosenCard
        );
        sltdCardDeatils = Object.assign({}, ...sltdCardDeatils);
        this.selectedcardHolderName = sltdCardDeatils.cardHolderName;
        this.selectedcardNumber = sltdCardDeatils.cardNumber;
        this.selectedcardType = sltdCardDeatils.cardType;
        this.selectedexpDate = sltdCardDeatils.expDate;
        this.cardExpiredFlag = sltdCardDeatils.cardExpired;
        this.ref.detectChanges();
      }
    });
  }

  updateOrderContact(): void {
    const params = {
      email: this.editStdForm.controls.emailId.value,
      mobileNo: this.editStdForm.controls.mobile.value
        ? this.editStdForm.controls.mobile.value
        : '',
      name: this.editStdForm.controls.firstName.value,
      phoneNo: this.editStdForm.controls.phone.value
        ? this.editStdForm.controls.phone.value
        : '',
      standardCart: true,
    };
    if (this.chosenEmail === 'Add Other E-mail Address') {
      params['addEmail'] = this.editStdForm.controls.newemailId.value;
      params.email = '';
      this.editStdForm.controls['newemailId'].setValidators([Validators.required, Validators.pattern('^[a-z0-9_.]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]);
      this.editStdForm.controls['newemailId'].updateValueAndValidity();
      this.editStdForm.controls['emailId'].clearValidators();
      this.editStdForm.controls['emailId'].updateValueAndValidity();
    } else {
      this.editStdForm.controls['emailId'].setValidators(Validators.required);
      this.editStdForm.controls['emailId'].updateValueAndValidity();
      this.editStdForm.controls['newemailId'].clearValidators();
      this.editStdForm.controls['newemailId'].updateValueAndValidity();
    }
    if (this.editStdForm.valid) {
      this.checkoutService.updateContact(params, this.userId, this.cartId);
      this.updateContactSubscriber = this.checkoutService.updatedContact.subscribe((res) => {
        if (res) {
          this.orderContact = res;
          this.ref.detectChanges();
        }
      });
      this.editNotClicked = true;
    }
  }
  placeOrder() {

    if (this.selectedPaymentMethod === 'BILL_ME_LATER') {
      this.selectedcardHolderName = '';
      this.selectedcardNumber = '';
      this.selectedcardType = '';
      this.selectedexpDate = '';
    }
    const params = {
      cardHolderName: this.selectedcardHolderName,
      cardNumber: this.selectedcardNumber,
      cardType: this.selectedcardType,
      expDate: this.selectedexpDate,
      paymentMethod: 'BILL_ME_LATER',
      purchaseOrderNumber: this.orderNo ? this.orderNo : '',
      seasonId: !this.stdCartType ? this.activeSeasonId : '',
      standardCart: this.stdCartType,
    };

    this.checkoutService.updateOrder(this.userId, this.cartId, params);
    this.modalService.dismissAll();
    this.updatedStdOrderSubscriber = this.checkoutService.updatedStdOrderDetails$.subscribe((res) => {
      if (res && this.stdCartType) {
        this.router.goByUrl('/place-order-confirmation');
      }
    });

    this.updatedInfluenzaOrderDetailsSubscriber = this.checkoutService.updatedOrderDetails.subscribe((res) => {
      if (!this.stdCartType && res) {
        this.addToCartService.getAllCarts(this.userId);
        this.influenzaService.checkoutInfluenza?.next(false);
        this.router.goByUrl('/influenza/orderConfirmation');

      }
    });
  }
  emailsList(): void {
    this.checkoutService.getEmails(this.userId, this.usergetDataObj?.uid);
    this.emailSubscriber = this.checkoutService.emails$.subscribe((emailsList) => {
      if (emailsList) {
        this.emailsArray = [];
        this.emailsArray = emailsList;
        if (!this.emailsArray?.includes('Add Other E-mail Address')) {
          this.emailsArray.push('Add Other E-mail Address');
        }
        this.checkoutService.emails.next(false);
      }

    });
  }
  editContact() {
    let altPhone = this.orderContact?.phoneNo.replace('+','')
    altPhone = altPhone.split(' ').join('');
    if (altPhone != null && altPhone.length != 10) {
      this.editStdForm.controls['phone'].markAsDirty();
    }
    let altMobile;
    if(this.orderContact?.mobileNo != undefined) {
      altMobile = this.orderContact?.mobileNo.split(' ').join('');
    }
    this.editNotClicked = false;
    this.emailsList();
    this.editStdForm.patchValue({
      firstName: this.orderContact?.name,
      emailId: this.orderContact?.email,
      phone: altPhone ? altPhone : '',
      mobile: altMobile ? altMobile : '',
    });
    this.chosenEmail = this.orderContact?.email ? this.orderContact.email : '';
  }
  onCancelClick() {
    this.editNotClicked = true;
  }
  getPaymentDetailsOfUser(event) {

    this.selectedPaymentMethod = event.name;
    if (event.code === 2) {
      this.router.goByUrl('/my-account/profile');
      this.checkoutService.tabSelection('Payment Settings');
    }
    if (event.code === 0) {
      this.selectedcardHolderName = '';
      this.selectedcardNumber = '';
      this.selectedcardType = '';
      this.selectedexpDate = '';
      this.selectedPaymentMethod = 'BILL_ME_LATER';
      this.displayPaymentMethod = this.paymentsArray.find((item) => item.code === 0).name;
    }
    if (event.code === 1) {
      this.selectedPaymentMethod = '0000';
      this.displayPaymentMethod = this.paymentsArray.find((item) => item.code === 1).name;
      if (this.cardExpiredFlag) {
        this.translation.translate('addToCartItems.cardExpMsg').subscribe((res) => {
          this.globalMessage.add(res,
            GlobalMessageType.MSG_TYPE_ERROR
          );
        });
      }
    }
  }
  getCardDetailsOfUser(event) {
    this.selectedcardHolderName = event.cardHolderName;
    this.selectedcardNumber = event.cardNumber;
    this.selectedcardType = event.cardType;
    this.selectedexpDate = event.expDate;
    this.cardExpiredFlag = event.cardExpired;
    if (this.cardExpiredFlag) {
      this.translation.translate('addToCartItems.cardExpMsg').subscribe((res) => {
        this.globalMessage.add(res,
          GlobalMessageType.MSG_TYPE_ERROR
        );
      });
    }
  }
  // openBlurModal(event){
  //   const value=this.userCardDataArray.length;
  //   if(value===this.chosenCard){
  //     const modref = this.modalService.open(AddCardComponent);
  //     modref.componentInstance.isCheckbox = true;
  //   }

  // }
  openModal(event) {
    const modref = this.modalService.open(AddCardComponent);
    modref.componentInstance.isCheckbox = true;
  }
  toggle(popover) {
    if (popover.isOpen()) {
      popover.close();
    } else {
      popover.open();
    }
  }
  navigateToCart() {


    if (this.stdCartType) {
      this.router.goByUrl('/cart');
    } else {
      this.router.goByUrl('/influenza/cart');
    }
  }
  checkUser() : void {
    if (this.agentEmulating) {
      if (this.uploadedFile) {
        this.checkout();
      }
    } else {
      this.checkout();
    }
  }
  checkout(): void {

    if (this.cardExpiredFlag) {
      this.translation.translate('addToCartItems.cardExpMsg').subscribe((res) => {
        this.globalMessage.add(res,
          GlobalMessageType.MSG_TYPE_ERROR
        );
      });
    } else {
      if (this.selectedcardNumber || this.displayPaymentMethod === 'Bill Me Later' || this.displayPaymentMethod === 'Facturez-moi plus tard') {
        this.modalService.open(this.continueCheckout);
      } else {
        this.translation.translate('addToCartItems.invalidPayment').subscribe((res) => {
          this.globalMessage.add(res,
            GlobalMessageType.MSG_TYPE_ERROR
          );
        });
      }
    }
  }

  ordrConfirmation(): void { }
  productDetails(productDetails): void {
    if (this.stdCartType) {
      const modalRef = this.modalService.open(ProductDetailsComponent, {
        windowClass: 'product-details-modal',
      });
      modalRef.componentInstance.productDetails = productDetails;
      modalRef.componentInstance.standardCartCode = this.standardCartDetails.code;
      modalRef.componentInstance.unitSection = 'NoUnits';
    } else {
      this.pdpService.getPDPCategory(productDetails.product.code);
      this.prodCodeSubscriber = this.pdpService.productCode$.subscribe((res) => {
        if (res && res?.code === productDetails.product.code) {
          this.modalService.dismissAll();
          const modalRef = this.modalService.open(ProductDetailsInfluenzaComponent,
            { windowClass: 'product-details-inf-modal' });
          modalRef.componentInstance.productDetails = res;
          modalRef.componentInstance.standardCartCode = res.code;
        }
      });
    }

  }
  billingAccount(): void {
    this.router.goByUrl('/my-account/profile');
    this.checkoutService.tabSelection('Account information');
  }
  editOrder(): void {
    this.modalService.dismissAll();
  }
  delEmail() {
    this.editStdForm.controls['newemailId'].setValue('');
    this.chosenEmail = this.orderContact.email;
  }
}
