import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { RoutingService } from '@spartacus/core';
import { VscaAuthService } from '../../../../../../../core/auth/user-auth/facade/vsca-auth.service';
import { DataLayerService } from '../../../../../../shared/services/data-layer.service';
import { VscaLoginFormComponentService } from './vsca-login-form-component.service';
@Component({
  selector: 'vsca-cx-login-form',
  templateUrl: './vsca-login-form.component.html',
  styleUrls: ['./vsca-login-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VscaLoginFormComponent implements OnInit, OnDestroy {
  emailVerified = true;
  loginSubsciption: any;

  constructor(
    protected service: VscaLoginFormComponentService,
    private readonly route: RoutingService,
    protected authService: VscaAuthService,
    private readonly ref: ChangeDetectorRef,
    private dataLayerService: DataLayerService
  ) {}

  form: FormGroup = this.service.form;
  isUpdating$ = this.service.isUpdating$;

  @HostBinding('class.user-form') style = true;
  login: any;
  password: any;

  ngOnInit() {
    this.loginSubsciption = this.service.busyObservable.subscribe(res => {
      if (!res){
        this.emailVerified = this.authService.emailVerified;
        this.ref.detectChanges();
      }
    });
  }

  hideEmailVerificationError() {
    this.emailVerified = true;
    this.ref.detectChanges();
  }

  ngOnDestroy() {
    this.loginSubsciption?.unsubscribe();
  }

  onSubmit(): void {
    this.service.login();
    this.dataLayerService.loginDataLayer();
  }

  gotoLoginHelp() {
    this.route.goByUrl('/login/loginhelp');
  }
}
