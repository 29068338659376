<div class="container header-Container">
  <div class="row">
    <div class="col-lg-3 col-xl-3 ">
      <span class="activeSeasonId">
        {{activeSeasonId}} {{'selectVaccines.season'|cxTranslate}}
      </span>

    </div>
    <div class="col-7 col-lg-7 col-xl-7 bc-Dash-Div no-print">
      <span class="back-To-Dashbaord" (click)='backToDashBoard(content,false)'>
        <img src='assets/img/chevron-left.svg' class="svg-Images" alt="left">
        {{'selectVaccines.back_to_db'|cxTranslate}}</span>
    </div>
    <div class="col-3 col-lg-1 col-xl-1 print-Div no-print">
      <span class="print" (click)="OnFluPrint()">
        <img src='assets/img/print.svg' class="svg-Images" alt="print">
        {{'selectVaccines.print'|cxTranslate}}</span>
    </div>

  </div>
</div>

<app-flu-header [selectedHeader]="header"></app-flu-header>


<!---------------------------------------select vaccine------------------------------->

<section class="container select-Container">
  <section class="row">
    <!-- =====================iphone======================= -->
    <section class="container proceed-small-Container proceed-BtnView no-print">
      <section class="row">
        <section class="col-md-12 col-lg-12 col-xl-12">
          <button class="btn proceed-Btn" [ngClass]="{'block-Click':apiCallUnderProcess}"
            [disabled]="duplicateProdCheckArray.length===0 || disableButton" (click)="moveProductsToCart()">
            <i class="fa fa-spinner fa-spin" *ngIf="apiCallUnderProcess"></i>
            {{'selectVaccines.proceed_toCart'|cxTranslate}}</button>
        </section>
      </section>
    </section>
    <!--left sec-->
    <section class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 bord-Right">
      <section class="row">
        <section class="col-xl-12 col-lg-12 select-Header-Section">
          <section class="select-Header">
            {{'selectVaccines.select_Inf_Msg'|cxTranslate}}
          </section>

        </section>
      </section>
      <hr>
      <section class="row row-Gap" *ngFor="let user of fluProductsList; let i = index" id="{{user.code}}">
        <section class="col-2 col-sm-1 col-md-1 col-lg-1 col-xl-1 prod-Image-Section">
          <a (click)="productDetails(user)">
            <img *ngIf="user.thumbNailImg !== ''" src="{{baseUrl}}{{user.thumbNailImg}}" class="prod-Image"
              title="{{user.name}}" alt="prodImg" />
            <img *ngIf="user.thumbNailImg == ''" [src]="fallbackImage" class="prod-Image" title="{{user.name}}"
              alt="defImg" />
          </a>
        </section>
        <section class="col-10 col-sm-4 col-md-6 col-lg-6 col-xl-6 prod-Desc-Div">
          <section class="container">
            <section class="row">

              <section class="col-md-12 col-lg-12 col-xl-12">
                <a (click)="productDetails(user)" title="{{user.name}}" class="prod-Name" [innerHTML]="user.name"></a>
              </section>
              <section class="col-md-12 col-lg-12 col-xl-12 product-Desc-Section" [innerHTML]="user.description">
              </section>
              <section class="col-md-12 col-lg-12 col-xl-12 prod-No">
                {{'selectVaccines.product_number'|cxTranslate}}:
                <span class="prodct-No">{{user.code}}</span>
              </section>
            </section>
          </section>
        </section>
        <!-- ============================Price=========================================== -->
        <section class="col-7 col-sm-7 col-md-3 col-lg-3 col-xl-3 i-Padding">
          <section class="container">
            <section class="row">
              <section class="col-md-12 col-lg-12 col-xl-12">
                <span class="your-Price">{{'selectVaccines.yourPrice'|cxTranslate}}</span>
              </section>
              <section class="col-md-12 col-lg-12 col-xl-12">
                <span class="prod-Price">{{user?.price?.value | localCurrency}} {{user?.price?.currencyIso}}</span>
              </section>
              <section class="col-md-12 col-lg-12 col-xl-12 vol-Pricing" *ngIf="!user?.discontinued && user?.available">
                <a class="link vol-Price" placement="top" *ngIf="user?.volumePrices?.length > 0"
                  [ngbPopover]="popContent" popoverTitle="{{'selectVaccines.volumePrice'|cxTranslate}}">
                  {{'selectVaccines.volumePrice'|cxTranslate}}
                </a>
                <ng-template #popContent>
                  <table class="tb" id="vol-price-pdp" aria-describedby="volTable">
                    <thead>
                      <th scope="col"></th>
                    </thead>
                    <tbody *ngFor="let prices of user?.volumePrices; let i=index">
                      <tr>
                        <td>{{prices?.minQuantity}} - {{prices?.maxQuantity}}
                          {{'selectVaccines.units'|cxTranslate}}:
                        </td>
                        <td>{{prices?.value | localCurrency}}</td>
                        <td><span *ngIf="i>0" class="save-price">{{'selectVaccines.save'|cxTranslate}}
                            {{user?.volumePrices[0].value - user?.volumePrices[i].value | localCurrency}}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </ng-template>
              </section>
            </section>
          </section>
        </section>
        <!-- ==============================units========================================== -->
        <section class="col-5 col-sm-5 col-md-2 col-xs-6 p0 pad-Port">
          <section class="container p0">
            <section class="units" *ngIf="(user.available===true && user.discontinued===false) else NotAccept">
              <b class="prodUnits">{{'selectVaccines.units'|cxTranslate}}</b>
              <section class="unit-area">
                <span class="disabled " aria-hidden="true" id="{{'minusIcon'+user?.code}}">
                  <img src="../../assets/icons/less.svg" id="{{'unitMinus'+user?.code}}" class="disabled-img" alt=""
                    (click)="performQuantityComputation(2,user?.code,user?.quantityPerUnit)">
                  <img src="../../assets/icons/less-disable.svg" id="{{'unitMinusDisable'+user?.code}}" alt="">
                </span>
                <input type="number" class="prod-quantity text-center"
                  value="{{user?.totalQuantity ? user?.totalQuantity : '0'}}"
                  onkeypress="return /[0-9a-zA-Z]/i.test(event.key)"
                  (keyup)="performQuantityComputation(null,user?.code,user?.quantityPerUnit)"
                  id="{{'prodQuantity'+user?.code}}" min="0" max="99999" />
                <span aria-hidden="true" id="{{'plusIcon'+user?.code}}">
                  <img src="../../assets/icons/more.svg"
                    (click)="performQuantityComputation(1,user?.code,user?.quantityPerUnit)"
                    id="{{'unitPlus'+user?.code}}" alt="">
                  <img src="../../assets/icons/more-disable.svg" id="{{'unitPlusDisable'+user?.code}}"
                    class="disabled-img" alt="">
                </span>
              </section>


              <section class="doses-section" id="{{'noOfDoses'+user?.code}}">
                {{user?.totalDoses ? user?.totalDoses : '' }} {{user?.totalDoses ? 'Doses' : ''}}
              </section>
              <span class="available-now"
                *ngIf="user.onsn === 'ON' && user.discontinued===false && user.available===true">
                {{'selectVaccines.available_now'|cxTranslate}}
              </span>
            </section>

            <ng-template #NotAccept>
              <section class="units-Non-Accepting">
                <span *ngIf="!user.available && user.discontinued===false" class="Not-Available">
                  {{user?.availabilityText ? user?.availabilityText :
                  ('selectVaccines.product_unavailable'|cxTranslate)}}
                </span>
                <span class="" *ngIf="user.discontinued===true" class="Not-Available">
                  {{'selectVaccines.product_discontinued'|cxTranslate}}</span>
              </section>
            </ng-template>

          </section>
        </section>
        <section class='col-md-12 col-lg-12 col-xl-12' *ngIf="fluProductsList.length>1">
          <hr>
        </section>
      </section>
    </section>
    <!--down sec-->
    <section class="container square-Container banner-Section small-Device-Section">
      <section class="row">
        <section class="col-md-12 col-lg-12 col-xl-12">
          <app-flu-dashboard-banner [bannerComponent]="compName"></app-flu-dashboard-banner>
        </section>
      </section>
    </section>
    <!--right sec-->
    <section class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 no-print">
      <section class="row proceed-Container proceed-BtnNormal">
        <section class="col-md-12 col-lg-12 col-xl-12">
          <button class="btn proceed-Btn" (click)="moveProductsToCart()" [ngClass]="{'block-Click':apiCallUnderProcess}"
            [disabled]="duplicateProdCheckArray.length===0 || disableButton">
            <i class="fa fa-spinner fa-spin" *ngIf="apiCallUnderProcess"></i>
            {{'selectVaccines.proceed_toCart'|cxTranslate}}</button>
        </section>

      </section>
      <section class="banner-Section big-Device-Section">
        <app-flu-dashboard-banner [bannerComponent]="compName"></app-flu-dashboard-banner>
      </section>
    </section>

  </section>
</section>
<section class="custome-modal">
  <ng-template #content let-modal id="content-modal-data-id">
    <section class="modal-header">
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <img alt="" src="../../assets/icons/close.png">
      </button>
      <h4 class="modal-title" id="modal-basic-title">{{'selectVaccines.discard_Changes'|cxTranslate}}</h4>
    </section>
    <section class="modal-body modal-body-discard">

      <span>{{'selectVaccines.leavePage_Msg'|cxTranslate}}</span>
    </section>
    <section class="modal-footer">
      <section class="row w-100 m-0">
        <section class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
          <button type="button" class="btn yesBtn-Css" (click)="modal.close('Save click'); backToDashBoard('',true) ">
            {{'selectVaccines.yes'|cxTranslate}}
          </button>
        </section>
        <section class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
          <button type="button" class="btn cancelBtn-Css" (click)="modal.close('Save click')">
            {{'selectVaccines.no'|cxTranslate}}
          </button>
        </section>
      </section>

    </section>
  </ng-template>


</section>