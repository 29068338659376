import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NgbAccordion } from '@ng-bootstrap/ng-bootstrap';
import { FaqService } from '../faq.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
})
export class FaqComponent implements OnInit, OnDestroy {
  @ViewChild('a') accordion: NgbAccordion;
  faq = [];
  openById = {};
  expandAll = true;
  FaqDataSubscription: any;

  constructor(
    private readonly faqService: FaqService,
    private readonly cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.faqService.getFAQ();
    this.FaqDataSubscription = this.faqService.faqList$.subscribe((res) => {
      if (res) {
        this.faq = res;
        this.cd.detectChanges();
      }
    });
  }

  toggleAll(): void {
    this.expandAll = !this.expandAll;
    if (this.expandAll) {
      this.accordion.collapseAll();
    } else {
      this.accordion.expandAll();
    }
  }

  ngOnDestroy() {
    this.FaqDataSubscription.unsubscribe();
  }
}
