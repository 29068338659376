
    <!--Failure screen-->
    <div
      *ngIf="showUserDetail === false"
      class="
        col-xs-12 col-sm-12 col-md-12
        Registration-split
        recovery-split
        border-align
      "
    >
      <h3 class="login-help-title">
        {{ "passwordRecoveryFailure.assistanceTitle" | cxTranslate }}
      </h3>
      <span class="security-Notify">{{
        "passwordRecoveryFailure.assistanceMessage" | cxTranslate
      }}</span>
      <div class="col-xs-12 col-sm-8 col-md-12 p0">
        <div class="col-xs-12 col-sm-12 col-md-12 p0 Fields-container">
          <label class="Fields-label">{{
            "forgotUserPassword.emailAddress" | cxTranslate
          }}</label>
          <span class="recovery-common-txt">{{ userInfo?.email }}</span>
        </div>
      </div>
      <br />
    </div>
    <!--Success screen-->
    <div
      *ngIf="showUserDetail"
      class="
        col-xs-12 col-sm-12 col-md-12
        Registration-split
        recovery-split
        border-align
      "
    >
      <h3 class="login-help-title" *ngIf="userData?.forgotUsername">
        {{ "passwordRecoveryFailure.emailInfoMessage" | cxTranslate }}
      </h3>
      <h3 class="login-help-title" *ngIf="userData?.forgotPassword">
        {{ "passwordRecoveryFailure.passwordLinkInfoMessage" | cxTranslate }}
      </h3>
      <div class="col-xs-12 col-sm-8 col-md-8 p0">
        <div class="col-xs-12 col-sm-12 col-md-12 p0 Fields-container">
          <label class="Fields-label">{{
            "forgotUserPassword.emailAddress" | cxTranslate
          }}</label>
          <span class="recovery-common-txt">{{ userData.email }}</span>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 p0 Fields-container">
          <label class="Fields-label">{{
            "passwordRecoveryFailure.accountNumber" | cxTranslate
          }}</label>
          <span class="recovery-common-txt">{{ userData.accountNumber }}</span>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 p0 Fields-container">
          <label class="Fields-label">{{
            "passwordRecoveryFailure.name" | cxTranslate
          }}</label>
          <span class="recovery-common-txt">{{ userData.name }}</span>
        </div>
      </div>
      <hr class="Fields-container-line" />
      <span class="recovery-common-txt"
        >{{ "passwordRecoveryFailure.spamMessageInfo" | cxTranslate
        }}<br /><br />{{
          "passwordRecoveryFailure.assistanceNumber" | cxTranslate
        }}</span
      >
    </div>