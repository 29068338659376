import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {SelectWhsPopupService} from './services/select-whs-popup.service';
import {RoutingService} from '@spartacus/core';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-switch-whs-popup',
  templateUrl: './select-whs-popup.component.html',
  styleUrls: ['./select-whs-popup.component.scss']
})
export class SelectWhsPopupComponent implements OnInit, OnDestroy {
  selectedWhs: string;
  b2bUnits: any;
  searchedB2bUnitList: any;
  wholesalerId: string;
  userAccountData: any;
  b2bUnitsByAccountTypeSub: Subscription;

  constructor(
    private readonly selectWhsPopupService: SelectWhsPopupService,
    private readonly ref: ChangeDetectorRef,
    private readonly router: RoutingService,
  ) {
  }

  ngOnInit(): void {
    this.selectWhsPopupService.disableParent();
    this.selectWhsPopupService.getB2BUnitsByAccountType();
    this.b2bUnitsByAccountTypeSub = this.selectWhsPopupService.b2bUnitsByAccountType$.subscribe(res => {
      this.b2bUnits = res;
      this.searchedB2bUnitList = this.b2bUnits;
      this.ref.detectChanges();
    });
  }

  selectWhsForB2BUnit(): void {
    this.selectWhsPopupService.updateWholesalerForCurrentCustomer(this.wholesalerId)
      .then(() => {
        this.selectWhsPopupService.fetchCurrentCustomer();
        this.router.goByUrl('my-account/account-overview');
      });
  }

  getWholesalerId(b2bUnit): void {
    if (b2bUnit.uid !== undefined && b2bUnit.uid !== null) {
      this.wholesalerId = b2bUnit.uid;
    }
  }

  searchB2BName(value): void {
    if (value.length > 0) {
      this.searchedB2bUnitList = this.b2bUnits.filter(x =>
        (x.locName && x.locName.toLowerCase().indexOf(value.toLowerCase()) >= 0)
        || (!x.locName && x.name && x.name.toLowerCase().indexOf(value.toLowerCase()) >= 0)
      );
    }
    else {
      this.searchedB2bUnitList = this.b2bUnits;
    }
  }

  ngOnDestroy(): void {
    this.selectWhsPopupService.enableParent();
    this.b2bUnitsByAccountTypeSub?.unsubscribe();
  }

}
