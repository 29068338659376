import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit,Renderer2 } from '@angular/core';
import { CmsService, LanguageService, RoutingService } from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { CustomInfluenzaService } from '../custom-influenza.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-influenza-dashboard',
  templateUrl: './influenza-dashboard.component.html',
  styleUrls: ['./influenza-dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class InfluenzaDashboardComponent implements OnInit,OnDestroy {


  userData: any = [];
  idofReservation: any;
  orderHistory: any = ['1'];
  chosenReservation: any;
  reserveNowClicked = false;
  statusDisplay:any;
  infContact: any;
  activeSeasons: any;
  cmsResponse: any;
  fluParagraph: any;
  endDate: any;
  todayDate: Date;
  displayActiveSeasons = true;
  startDate: any;
  orderHistorySubscriber: any;
  activeSeaonsSubscriber: any;
  languageUsed: string;
  constructor(private readonly cmsData: CmsService, private readonly influenzaService: CustomInfluenzaService,
    private readonly user: UserAccountFacade,
    private readonly renderer: Renderer2,
    private readonly ref: ChangeDetectorRef, private readonly router: RoutingService,
    private readonly sharedService:SharedService,
    private readonly languageService:LanguageService) {

  }


  ngOnInit() {
    this.languageService.getActive().subscribe((langRes) => {
     
      this.languageUsed=langRes;
      this.ref.detectChanges();
    });
  this.renderer.listen('document', 'click', (event) => {
    if (event.target.classList.contains('close-banner')) {
      const displayInflunenzaBanner = document.getElementsByClassName('FluParaMiddleSlottop');
      for (let i = 0; i < displayInflunenzaBanner.length; i++) {
        displayInflunenzaBanner[i].className += ' displayNone';
      }
    }
  });


    this.user.get().subscribe((res: any) => {
      if (res) {
        this.userData = res;
      
      }
    });
  this.activeSeaonsSubscriber=  this.influenzaService.activeSeaons.subscribe((data) => {
      if (data) {
        this.activeSeasons = data.categoryData[0]?.code;
        this.influenzaService.getUserOrderHistory(this.userData.userName,this.activeSeasons);
        this.sharedService.setSessionStorage('activeSeasons',this.activeSeasons);
        this.todayDate = new Date();
        this.endDate = data.categoryData[0]?.endDate;
        this.startDate = data.categoryData[0]?.startDate;
        if(this.todayDate > new Date(this.endDate) || this.todayDate < new Date(this.startDate) || !data.categoryData?.[0] ) {
          this.displayActiveSeasons = false;
          this.ref.detectChanges();
         
        }
      } 
      this.activeSeaonsSubscriber?.unsubscribe();
    });
    this.orderHistorySubscriber=this.influenzaService.ordersHistory.subscribe((orderData) => {
      if(orderData){
        this.orderHistory=orderData?.orders;
        
        this.chosenReservation=this.orderHistory?.[0]?.guid;
        this.idofReservation=this.chosenReservation;
        this.statusDisplay=this.orderHistory?.[0]?.statusDisplay;
        if(this.idofReservation){
        this.influenzaService.getDetailedReservation(this.userData.userName,this.idofReservation,false);
        }
        this.ref.detectChanges();
      }
      this.orderHistorySubscriber?.unsubscribe();

    });
    this.getFluParagraph();
    this.ref.detectChanges();

  }
  ngOnDestroy(){
    this.activeSeaonsSubscriber?.unsubscribe();
    this.orderHistorySubscriber?.unsubscribe();
  }
  changeReservation(data){
    data!==null?this.idofReservation=data.guid: this.idofReservation=this.chosenReservation;
    data!==null?this.statusDisplay=data.statusDisplay:'';
    
    this.influenzaService.getDetailedReservation(this.userData.userName,this.idofReservation,false);
  }
  viewReservation() {
    this.influenzaService.getDetailedReservation(this.userData.userName, this.idofReservation, true);
  }
  proceedToCategory(event) {
    this.infContact = event;
  }
  reserveNow() {
    if (this.infContact) {
      this.router.goByUrl('/influenza/category');
    } else {
      this.reserveNowClicked = true;
    }
  }
  getFluParagraph() {
    this.cmsData.getComponentData('FluParagraphComponent').subscribe((res) => {
      this.cmsResponse = res;
    }
    );
    if (this.cmsResponse && this.cmsResponse.content) {
      this.fluParagraph = this.cmsResponse.content;
    }
  }
}
