<!--details sectuion-->
<section class="container payMethod-Container" *ngIf="urlPath==='reservation-overview'">

  <section class="row">
    <section class="col-md-12 col-lg-12 col-xl-12">
      <span class="payment-header">{{'orderConfirmation.details'|cxTranslate}}</span>
    </section>
    <section class="col-6 col-md-6 ">
      <section class="col-md-12 details-Labels">
        <span class="billing-Addr-Header">{{'orderConfirmation.po'|cxTranslate}}</span>
      </section>
      <section class="col-md-12 details-Labels">
        <span class="">{{poId}}</span>
      </section>
      <section class="col-md-12 details-Labels">
        <span class="billing-Addr-Header">{{'orderConfirmation.soldTo'|cxTranslate}}</span>
      </section>
      <section class="col-md-12 details-Labels">
        <span class="">{{soldToAddress?.unitID ? soldToAddress?.unitID : '' }}</span>
      </section>
      <section class="col-md-12 details-Labels">
        <span class="billing-Addr-Header">{{'orderConfirmation.orderDate'|cxTranslate}}</span>
      </section>
      <section class="col-md-12 details-Labels">
        <span class="">{{orderDate | slice:0:10 | date : 'dd/MM/yyyy' }}</span>
      </section>
      <section class="col-md-12 details-Labels">
        <span class="billing-Addr-Header">{{'orderConfirmation.status'|cxTranslate}}</span>
      </section>
      <section class="col-md-12 details-Labels">
        <span class="">{{status}}</span>
      </section>
    </section>
    <section class="col-6 col-md-6">
      <section class="container">
        <section class="row">
          <section class="col-md-12 col-lg-12 col-xl-12 ship-To">
            <span class="billing-Addr-Header">{{'orderConfirmation.shipTo'|cxTranslate}}</span>
          </section>
          <section class="col-12 col-md-12 col-lg-12 col-xl-12">
            <span>{{ "orderConfirmation.accountNo" | cxTranslate }}:
              {{ selectedData?.unitID ?
                selectedData?.unitID : "" }}</span>
          </section>
          <section class="col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12">
            <span class="">{{selectedData?.companyName ? selectedData?.companyName : '' }}</span>
          </section>
          <section class="col-md-12 col-lg-12 col-xl-12">
            <span>{{
              selectedData?.line1 ? selectedData?.line1 : ""
              }},
              {{
              selectedData?.line2 ? selectedData?.line2 : ""
              }}</span>
          </section>
          <section class="col-md-12 col-lg-12 col-xl-12">
            <span>{{ selectedData?.town ? selectedData?.town : "" }}, {{ selectedData?.region?.isocodeShort ? selectedData?.region?.isocodeShort + ',' : "" }}
              {{
              selectedData?.postalCode
              ? selectedData?.postalCode
              : ""
              }}</span>
          </section>
        </section>
      </section>
    </section>
  </section>
</section>
<!--details sectuion end-->
<section class="container payMethod-Container">
  <section class="row">
    <section class="col-md-12 col-lg-12 col-xl-12">
      <span class="payment-header">{{'orderConfirmation.paymentMethod'|cxTranslate}}</span>
    </section>
    <section class="note-Size">
      <img *ngIf = "paymentType !== '0000' || !cardType || (cardType !== 'Visa' && cardType !== 'Mastercard')"
      src="../../assets/icons/card.svg" alt="card" />
      <img *ngIf = "cardType === 'Visa'" src="/assets/img/VISA.svg" alt="visa" />
      <img *ngIf = "cardType === 'Mastercard'" src="/assets/img/MC.svg"  alt="mastercard" />
      <span class="payment-method-css">{{displayName}}</span>
        <div class="pay-Type" *ngIf = "paymentType === '0000' && 
        cardType">{{
          cardType ? cardType : ""
          }} {{'orderConfirmation.endingin'|cxTranslate}} {{
            ccNumber ? ccNumber : ""
          }}</div>
    </section>
    <section class="col-md-12 col-lg-12 col-xl-12 multiple-Shipments-Div">
      {{'orderConfirmation.multipleShipmentsMsg'|cxTranslate}}
    </section>
  </section>
</section>
<!--patment end-->
<section class="container">
  <section class="row">
    <section class="col-md-12 col-lg-12 col-xl-12">
      <section class="col-12 col-sm-12 col-md-12 payment-header">
        {{'orderConfirmation.reservationContact'|cxTranslate}}
      </section>
      <section class="col-md-12 col-lg-12 col-xl-12 reservation-Msg-Div">
        <span class="reservation-Msg">{{'selectVaccines.contactMsg'|cxTranslate}}
        </span>
      </section>
    </section>
  </section>
  <!--contact details-->
  <section class="row contact-Main-Row">
    <section class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 reseName-Div">
      <span class="rese-Name">{{'selectVaccines.name'|cxTranslate}}</span>
    </section>
    <section class="
          col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8
          reseName-Value-Div
        ">
        <span class="">{{infContact?.name}}</span>
    </section>
    <section class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 resePhone-Div" *ngIf="infContact?.fax">
      <span class="rese-Phone">{{'selectVaccines.fax'|cxTranslate}}</span>
    </section>
    <section class="col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8" *ngIf="infContact?.fax" >
      <span class="">{{infContact?.fax}}</span>
    </section>
    <section class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 reseEmail-Div">
      <span class="rese-Email">{{'orderConfirmation.email'|cxTranslate}}</span>
    </section>
    <section class="col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8 reseEmail-Value-Div">
      <a href="{{'mailto:'+(infContact?.email ? infContact?.email : userEmail )}}" class="email-Value">{{ infContact?.email ?  infContact?.email : userEmail}}</a>
    </section>
    <section class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 resName-Div">
      <span class="res-Name">{{
        "selectVaccines.placedBy" | cxTranslate
        }}</span>
    </section>
    <section class="col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8 resName-Value-Div">
      <span class="">{{userName}}</span>
    </section>
  </section>
  <!--address-->
  <section class="row addr-Div">
    <section class="col-md-6 col-lg-12 col-xl-6 billing-Div">
      <section class="container">
        <section class="row">
          <section class="col-md-12 col-lg-12 col-xl-12 billing-Addr-Div">
            <span class="billing-Addr-Header">{{'orderConfirmation.billingAddr'|cxTranslate}}</span>
          </section>
          <section class="col-12 col-md-12 col-lg-12 col-xl-12">
            <span>{{ "orderConfirmation.accountNo" | cxTranslate }}:
              {{ billingAddress?.unitID ?
                billingAddress?.unitID : "" }}</span>
          </section>
          <section class="col-md-12 col-lg-12 col-xl-12">
            <span>{{billingAddress?.companyName ? billingAddress?.companyName : '' }}</span>
          </section>
          <section class="col-md-12 col-lg-12 col-xl-12">
            <span>{{
              billingAddress?.line1 ? billingAddress?.line1 : ""
              }},
              {{
              billingAddress?.line2 ? billingAddress?.line2 : ""
              }}</span>
          </section>
          <section class="col-md-12 col-lg-12 col-xl-12">
            <span>{{ billingAddress?.town ? billingAddress?.town : "" }}, {{ billingAddress?.region?.isocodeShort ? billingAddress?.region?.isocodeShort + ',' : "" }}
              {{
              billingAddress?.postalCode
              ? billingAddress?.postalCode
              : ""
              }}</span>
          </section>
        </section>
      </section>
    </section>
    <section class="col-md-6 col-lg-12 col-xl-6 billing-Div">
      <section class="container">
        <section class="row">
          <section class="col-md-12 col-lg-12 col-xl-12 sold-Addr-Div">
            <span class="sold-Addr-Header">{{'orderConfirmation.soldToAddr'|cxTranslate}}</span>
          </section>
          <section class="col-12 col-md-12 col-lg-12 col-xl-12">
            <span>{{ "orderConfirmation.accountNo" | cxTranslate }}:
              {{ soldToAddress?.unitID ?
                soldToAddress?.unitID : "" }}</span>
          </section>
          <section class="col-md-12 col-lg-12 col-xl-12">
            <span>{{soldToAddress?.companyName ? soldToAddress?.companyName : '' }}</span>
          </section>
          <section class="col-md-12 col-lg-12 col-xl-12">
            <span>{{ soldToAddress?.line1 ? soldToAddress?.line1 : "" }}
              {{
              soldToAddress?.line2 ? soldToAddress?.line2 : ""
              }}</span>
          </section>
          <section class="col-md-12 col-lg-12 col-xl-12">
            <span>{{ soldToAddress?.town ? soldToAddress?.town : "" }}, {{ soldToAddress?.region?.isocodeShort ? soldToAddress?.region?.isocodeShort : "" }} ,
              {{
              soldToAddress?.postalCode
              ? soldToAddress?.postalCode
              : ""
              }}</span>
          </section>
        </section>
      </section>
    </section>
    <section class="col-md-12 manage-Bill-Div">
      <a target="_blank" (click)="billingAccount()">
        <span class="manage-Bill-info">{{'orderConfirmation.manageBillInfo'|cxTranslate}}</span>
      </a>
    </section>
  </section>
</section>