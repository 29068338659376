import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {UserAccountFacade} from '@spartacus/user/account/root';
import {CustomProfileAndPreferencesService} from 'src/app/custom-profile-and-preferences/custom-profile-and-preferences.service';
import {LoactionStripService} from './loaction-strip.service';
import {SelectWhsPopupService} from '../switch-whs-popup/services/select-whs-popup.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-location-strip',
  templateUrl: './location-strip.component.html',
  styleUrls: ['./location-strip.component.scss'],
})
export class LocationStripComponent implements OnInit, OnDestroy {
  usergetData: any;
  usergetDataObj: any;
  userAccountgetData: any;
  showLocStrip = false;
  currentCustomer: any;
  currentCustomerSub: Subscription;
  enableParentSub: Subscription;
  sessionStorage = sessionStorage;

  constructor(
    private readonly locService: LoactionStripService,
    private readonly user: UserAccountFacade,
    private readonly ref: ChangeDetectorRef,
    private readonly pfService: CustomProfileAndPreferencesService,
    private readonly selectWhsPopupService: SelectWhsPopupService,
  ) {
  }

  getUserAccountData(userId): void {
    this.locService.getUserAccountDataFromDb(userId);
    this.locService.userAccountData.subscribe((userData) => {
      if (userData.res != null && userData.res != undefined && userData.res?.length != 0) {
        this.usergetData = userData.res.defaultB2BUnit.shippingAddress;
        this.usergetDataObj = userData.res.defaultB2BUnit;
        this.ref.detectChanges();
      }
    });
  }

  ngOnInit() {
    this.user.get().subscribe((res) => {
      if (res != null && res != undefined) {
        this.userAccountgetData = res;
        this.showLocStrip = true;
        this.getUserAccountData(this.userAccountgetData.userName);
      } else {
        this.showLocStrip = false;
      }
      this.enableParentSub = this.selectWhsPopupService.enableParent$.subscribe(isEnabled => {
        if (res !== null && res !== undefined) {
          this.showLocStrip = isEnabled;
          this.ref.detectChanges();
        }
      });
      this.selectWhsPopupService.fetchCurrentCustomer();
      this.currentCustomerSub = this.selectWhsPopupService.currentCustomer$.subscribe(currentCustomer => {
        if (currentCustomer !== null && currentCustomer !== undefined) {
          this.currentCustomer = currentCustomer;
          this.ref.detectChanges();
        }
      });
    });
  }

  ngOnDestroy(): void {
    this.currentCustomerSub?.unsubscribe();
    this.enableParentSub?.unsubscribe();
  }
}
