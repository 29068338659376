<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 bg-color">
    <div class="col-lg-5 col-md-5 col-sm-0 col-xs-12 p0"></div>
    <div class="col-lg-8 col-md-3 col-sm-6 col-xs-12 middle-row p0 mt0 continue-button">
        <a (click)="navigateSaveOrder()" target="" id="continueShoppingId" data-di-id="#continueShoppingId">
            <img src="../../assets/icons/chevron-left.svg" alt="" class="chevron-left continue-icon">
            {{'addToCartItems.backToSavedOrder'|cxTranslate}}</a>
    </div>
</div>
<div id="main-container" class="container saved-orders">

    <div class="row row-20" id="leftCont-rightNav">

        <section class="col-xs-12 col-sm-8 col-md-8 left-section testcart" style="min-height: 165px;">
            <section class="edit-saved-cart">
                <div class="row add-to-cart">
                    <div class="col-sm-7 col-md-5 col-lg-5" id="cart-value1">
                        <img class="save-cart-icon float-left" src="../../assets/icons/cart.png" alt="">
                        <h5 class="bold-12 light-color" id="cartHeading">{{cartData?.name}}</h5>
                    </div>
                    <div class="col-sm-9 col-md-5 col-lg-3 hidden-xs p0 align-right-view">
                        <span class="actionOptions">
                            <a id="linkViewEdit" aria-labelledby="cartHeading" aria-describedby="linkViewEdit" target=""
                                class="edit" data-di-id="#linkViewEdit" [ngbPopover]=popRenameContent
                                [popoverTitle]=popRenameTitle [placement]="popRenamePosition" triggers="manual" #p2="ngbPopover"
                                (click)="toggle(p2)" container="body" popoverClass="popover-links"><img
                                    class="edit-icon" src="../../assets/icons/editIcon.png"
                                    alt="">{{'addToCartItems.rename'|cxTranslate}}</a>
                            <a href="javascript:void(0)" class="trash save-cart" data-toggle="popover"
                                data-placement="top" data-target="save-cart-0" data-original-title=""
                                id="linkViewDelete" aria-labelledby="cartHeading" data-di-id="#linkViewDelete"
                                [ngbPopover]=popDeltContent [popoverTitle]=popDeltTitle [placement]="popDeltPosition"
                                #p3="ngbPopover" triggers="manual" (click)="toggle(p3)" container="body"
                                popoverClass="popover-links"> <img class="delt-icon" src="../../assets/icons/trash.svg"
                                    alt="">{{'addToCartItems.delete'|cxTranslate}}</a>
                            <!--Popover content for Save cart start-->
                            <!--Popover content for Save cart end-->
                            <ng-template #popRenameTitle><span
                                    class="text-header rename-in-savedcart">{{'addToCartItems.renameInSavedOrder'|cxTranslate}}</span>
                            </ng-template>
                            <ng-template #popRenameContent>
                                <input type="text" class="save-order input-in-savedcart" #name="ngModel" maxlength="30"
                                    required [(ngModel)]="renameInSavedCart" (keypress)="omit_special_char($event)">
                                <span *ngIf="name.errors?.required" class="common-error required">{{errorMsg}}</span>
                                <span class="common-error required">{{errorMsg}}</span>
                                <button type="reset" class="popover-close btn white-button popover-cancel-button"
                                    data-di-id="di-id-d01008be-fb6e135c" (click)="toggle(p2)">
                                    {{'addToCartItems.cancel'|cxTranslate}}</button>
                                <button class="btn blue-button popover-cancel-button" type="submit"
                                    data-di-id="di-id-3064f17d-e97cc628" (click)="saveOrder()">
                                    {{'addToCartItems.save'|cxTranslate}}</button>
                            </ng-template>
                            <ng-template #popDeltTitle><span class="text-header">{{'addToCartItems.delete'|cxTranslate}}
                                    {{cartData?.name}} {{'addToCartItems.deleteTitleSavedOrder'|cxTranslate}}</span>
                            </ng-template>
                            <ng-template #popDeltContent>
                                <button type="reset" class="popover-close btn white-button popover-cancel-button"
                                    data-di-id="di-id-d01008be-fb6e135c" (click)="toggle(p3)">
                                    {{'addToCartItems.cancel'|cxTranslate}}</button>
                                <button class="btn blue-button popover-cancel-button" type="submit"
                                    data-di-id="di-id-3064f17d-e97cc628" (click)="deleteCart()">
                                    {{'addToCartItems.delete'|cxTranslate}}</button>
                            </ng-template>
                        </span>
                    </div>
                    <div class="btn-section">
                        <div class="button-combo display-inline editsavedbuttons">
                            <button type="button" class="btn btn-default white-button"
                                (click)="navigateSaveOrder()">{{'addToCartItems.cancel'|cxTranslate}}</button>
                            <button type="submit" name="saveCartChanges" class="btn btn-default blue-button"
                                [disabled]="disabled"
                                (click)="updateCart()">{{'addToCartItems.saveChanges'|cxTranslate}}</button>
                        </div>
                    </div>
                </div>
                <div class="row cart-price-data">
                    <div class="col-md-9 col-sm-12">
                        <div class="table-responsive">
                            <table class="table table-saved-cart" aria-describedby="editCart">
                                <thead>
                                    <tr>
                                        <th id="restoreTableCol1">
                                            {{'addToCartItems.mySavedOrder_TotalUnits'|cxTranslate}}</th>
                                        <th id="restoreTableCol2">
                                            {{'addToCartItems.mySavedOrder_LastOrdered'|cxTranslate}}</th>
                                        <th id="restoreTableCol3">
                                            {{'addToCartItems.mySavedOrder_LastUpdated'|cxTranslate}}</th>
                                        <th id="restoreTableCol4">
                                            {{'addToCartItems.mySavedOrder_dateCreated'|cxTranslate}}</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td headers="restoreTableCol1">
                                            {{getQuantity(cartData?.childCarts)}}</td>
                                        <td headers="restoreTableCol2">
                                            {{getOrders(cartData?.childCarts)}}
                                        </td>
                                        <td headers="restoreTableCol3">{{cartData?.modifiedTime | slice:0:10 | date:'dd/MM/yyyy'}}
                                        </td>
                                        <td headers="restoreTableCol4">{{cartData?.saveTime | slice:0:10 | date:'dd/MM/yyyy'}}
                                        </td>

                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="sanofi-accordion">
                    <div class="panel-group">
                        <div class="panel panel-default">
                            <div class="panel-heading">
                                <div class="firstLevel-acc">
                                    <label class="fs-14 light-color">
                                        <img src="../../assets/icons/location.svg" class="accordion-img" alt="location">
                                        {{selectedLocation}}</label>
                                </div>
                            </div>
                            <div id="pdp-list2" class="panel-collapse">
                                <div class="panel-body">
                                    <div class="checkout-details">
                                        <div class="edit-prod-list" *ngFor="let productEntry of productEntries">
                                            <div class="row row-20">
                                                <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7 pr0 products-heading">


                                                    <a (click)="productDetails(productEntry)">
                                                        <img class="image-product"
                                                            *ngIf="productEntry?.PRIMARY!==undefined"
                                                            src="{{productEntry?.PRIMARY?.url}}" alt="">

                                                        <img class="image-product"
                                                            *ngIf="productEntry?.PRIMARY===undefined"
                                                            [src]="fallbackImage" alt="">
                                                        <h2 class="prod-title" [innerHTML]="productEntry?.product?.name"></h2>
                                                    </a>

                                                </div>
                                            </div>
                                            <div class="row row-20 m0">
                                                <!--Entity for space-->
                                                <div class="col-lg-1 col-md-1"></div>
                                                <div class="col-sm-5 col-md-5 col-lg-6 p0">
                                                    <div class="checkout-data">
                                                        <p [innerHTML]="productEntry?.product?.description"></p>
                                                        <p><span>{{'plp.productNumber'|cxTranslate}} :
                                                                {{productEntry?.product?.code}}</span></p>
                                                        <p *ngIf="!!productEntry?.wholesalerDisplayName"><span>{{'plp.soldBy'|cxTranslate}}&nbsp;
                                                                 {{productEntry?.wholesalerDisplayName}}</span></p>
                                                    </div>
                                                </div>
                                                <div class="col-xs-6 col-sm-3 col-md-3 col-lg-2 pl-xs-0">
                                                </div>
                                                <div class="col-xs-6 col-sm-3 col-md-3 px0">
                                                    <section class="unitCount-comp text-center">
                                                        <section class="addUnits-head bold">
                                                            {{'plp.units'|cxTranslate}}
                                                        </section>

                                                        <section class="unit-area">
                                                            <span class="disabled " aria-hidden="true"
                                                                id="{{'minusIcon'+productEntry?.product?.code}}"
                                                                (click)="performQuantityComputation(2,productEntry?.product?.code,productEntry?.product?.quantityPerUnit)">
                                                                <img src="../../assets/icons/less.svg"
                                                                    id="{{'unitMinus'+productEntry?.product?.code}}"
                                                                    [ngClass]="{'disabled-img':productEntry?.quantity===1}"
                                                                    alt="">
                                                                <img src="../../assets/icons/less-disable.svg"
                                                                    id="{{'unitMinusDisable'+productEntry?.product?.code}}"
                                                                    [ngClass]="{'disabled-img':productEntry?.quantity>1}"
                                                                    alt="">
                                                            </span>
                                                            <input type="number" class="prod-quantity text-center"
                                                                value={{productEntry?.quantity}}
                                                                (keyup)="performQuantityComputation(null,productEntry?.product?.code,productEntry?.product?.quantityPerUnit)"
                                                                id="{{'prodQuantity'+productEntry?.product?.code}}"
                                                                min="1" max="9999"
                                                                onkeypress="return /[0-9a-zA-Z]/i.test(event.key)" />
                                                            <span class="" aria-hidden="true"
                                                                id="{{'plusIcon'+productEntry?.product?.code}}"
                                                                (click)="performQuantityComputation(1,productEntry?.product?.code,productEntry?.product?.quantityPerUnit)">
                                                                <img src="../../assets/icons/more.svg"
                                                                    id="{{'unitPlus'+productEntry?.product?.code}}"
                                                                    alt="">
                                                                <img src="../../assets/icons/more-disable.svg"
                                                                    id="{{'unitPlusDisable'+productEntry?.product?.code}}"
                                                                    class="disabled-img" alt="">
                                                            </span>
                                                        </section>
                                                        <section class="doses-section"
                                                            id="{{'noOfDoses'+productEntry?.product?.code}}">
                                                            {{productEntry?.product?.quantityPerUnit*productEntry?.quantity}}
                                                            {{'plp.doses'|cxTranslate}}
                                                        </section>
                                                    </section>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </section>
        <aside class="col-xs-12 col-sm-4 col-md-4 col-lg-4 right-navigation savedcart-right">
            <section class="ad-product-details cartProductSaveDetails"
                     *ngIf="sessionStorage.getItem('accountType') === 'DIRECT'">
                <h3>{{'addToCartItems.addProducts'|cxTranslate}}</h3>
                <h6>{{'addToCartItems.quickOrder'|cxTranslate}}</h6>
                <p>{{'addToCartItems.searchItems'|cxTranslate}}</p>
                <app-quick-order></app-quick-order>
            </section>
        </aside>
    </div>
</div>
