<!-- <div class="row account-overview-header">
    <div class="col-xs-4 col-sm-5 col-md-7">
      <h3 class="reservation-details">
        {{ "accountOverview.FluReservations.title" | cxTranslate }}
      </h3>
    </div>
  </div>
  <hr />
  <app-flu-content></app-flu-content> -->



<aside
class="
  col-12 col-xs-12 col-sm-5 col-md-4
  right-navigation
  float-right
  my-acc-aside
"
>
<div class="ad-acc-details displayNo">
  <div class="titletext1" role="heading" aria-level="2">
    {{ "accountOverview.myAccount_label" | cxTranslate }}
  </div>
  <div class="row acc-numb">
    <div class="col-8 col-xs-8 col-md-8 acc-title">
      <img src="/../../../assets/icons/user-account.svg" alt="user account" />
      <span
        >{{ "accountOverview.account_label" | cxTranslate }} &nbsp;
        {{ accountNumber }}</span
      >
    </div>
    <div class="col-4 col-xs-4 col-md-4 acc-title-manage">
      <a
        (click)="navigateToAccountDetails()"
        data-di-id="di-id-9b8eeaed-3b366785"
        >{{ "accountOverview.view_label" | cxTranslate }}</a
      >
    </div>
  </div>
</div>
<div class="line-divider"></div>

<!-- <section class="displayNo col-xs-12 col-sm-7 col-md-12">
  <app-news-updates></app-news-updates>
</section> -->
<!-- <section class="displayNo banner-Align">
  <app-flu-dashboard-banner
    [bannerComponent]="compNameAdBanner1"
  ></app-flu-dashboard-banner>
</section>
<section class="displayNo banner-Align">
  <app-flu-dashboard-banner
    [bannerComponent]="compNameAdBanner2"
  ></app-flu-dashboard-banner>
</section>
<section class="displayNo banner-Align">
  <app-flu-dashboard-banner
    [bannerComponent]="compNameAdBanner3"
  ></app-flu-dashboard-banner>
</section> -->
<section class="displayNo banner-Align"></section>
</aside>
