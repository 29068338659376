<div class="container">
    <div class="row">
      <div 
      [ngClass]="{'col-lg-3 col-xl-3':languageUsed!='fr',
      'col-lg-2 col-xl-2':languageUsed==='fr'}">
        <span class="activeSeasonId">
          {{activeSeasonId}} Season
        </span>
      </div>
      <div class="bc-Dash-Div"
      [ngClass]="{'en-div col-lg-3 col-xl-3 ':languageUsed!='fr',
      'offset-lg-6 offset-xl-6 col-lg-4 col-xl-4':languageUsed==='fr'}">
        <span class="back-To-Dashbaord" (click)='backToDashBoard()'>
          <img src='assets/img/chevron-left.svg' class="svg-Images" alt="left" >
          {{'selectVaccines.back_to_db'|cxTranslate}} </span>
          <span  class="dlete-Cart"  (click)="downloadExcel()">
            <img src='../../assets/icons/excel.png' class="excel-Img" alt="xls" >
            {{'selectVaccines.xls'|cxTranslate}}</span>
            <span  class="print" (click)="OnFluPrint()">
                <img src='assets/img/print.svg' class="svg-Images" alt="print">
                {{'selectVaccines.print'|cxTranslate}}</span>
      </div>
     
    </div>
  </div>
<app-flu-header [selectedHeader]="header" ></app-flu-header>
<app-checkout [cartDetails]="cartDetails" [stdCartType]="stdCart"
 [activeSeasonId] = "activeSeasonId"></app-checkout>

