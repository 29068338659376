import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserAccountFacade } from '@spartacus/user/account/root';
import * as _ from 'underscore';
import { CustomProfileAndPreferencesService } from '../custom-profile-and-preferences.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit, OnDestroy {

  getuserData: any;
  usergetDataObj: any;
  userAccountgetData: any;
  closeResult = '';
  selectedData: any;
  selectedDirectoryData: any;
  selectedRegulatoryDirectoryData: any;
  selectedPrimaryContactData: any;
  selectedCfoData: any;
  listSelectedData: any;
  submitted: boolean;
  searchText: string;
  searchedClientList: any;
  flag = true;
  changeLocation  = true;
  userAccountDataSubscriber: any;
  userccountDetailsSubscriber: any;
  constructor(
    private readonly modalService: NgbModal,
    private readonly pfService: CustomProfileAndPreferencesService,
    private readonly user: UserAccountFacade,
    private readonly ref: ChangeDetectorRef,

  ) { }
  ngOnInit(): void {
    this.user.get().subscribe((res) => {
      if (res) {
        this.userAccountgetData = res;
        this.getUserAccountData(this.userAccountgetData?.userName);
      }
    });

  }

  ngOnDestroy(){
    this.userAccountDataSubscriber?.unsubscribe();
    this.userccountDetailsSubscriber?.unsubscribe();
  }

  open(content): void {
    this.searchedClientList = this.getuserData;
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).
      result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  save(): void {
  }

  getUserAccountData(userId): void {

    this.pfService.getUserAccountDataFromDb(userId);
    this.userAccountDataSubscriber = this.pfService.userAccountData.subscribe((userData) => {
      if (userData.res != null && userData.res !== undefined && userData.res?.length !== 0) {
        this.usergetDataObj = userData.res.defaultB2BUnit;
        const accountId = this.usergetDataObj.uid;
        this.pfService.getUserAccountDetailsDataFromDb(userId, accountId);
        this.userccountDetailsSubscriber = this.pfService.userAccountDetails.subscribe((userDataget) => {
          if (userDataget && userDataget !== undefined && userDataget !== null) {
            this.getuserData = userDataget.res?.shippingAddresses;
            this.searchedClientList = userDataget.res?.shippingAddresses;
            this.selectedDirectoryData = userDataget.res?.director;
            this.selectedRegulatoryDirectoryData = userDataget.res?.regulatoryDirector;
            this.selectedCfoData = userDataget.res?.cfo;
            this.selectedPrimaryContactData = userDataget.res?.primaryContact;
          }
          if (this.getuserData?.length <= 1) {

            this.changeLocation = false;
          }
          if (this.getuserData !== undefined && this.getuserData !== null) {
            this.selectedData = _.first(this.getuserData.filter((item) => item.unitID === this.usergetDataObj?.uid));
            this.ref.detectChanges();
          }

          this.userccountDetailsSubscriber?.unsubscribe();
        });

      }
      this.userAccountDataSubscriber?.unsubscribe();
    });
  }

  onOptionsSelected(data) {
    this.listSelectedData = data;
  }

  onSubmit(): void {
    if (this.listSelectedData) {
      this.selectedData = this.listSelectedData;
    } else {
      this.searchedClientList = this.getuserData;
    }
  }
  SearchClientName(data: any) {
    if (data.length > 0) {
      this.searchlocation(data);
    } else {
      this.searchedClientList = this.getuserData;

    }
  }
  searchlocation(data) {
    this.searchedClientList = this.getuserData?.filter(x =>
      (x?.companyName?.toLowerCase().indexOf(data.toLowerCase()) >= 0)
      || (x?.unitID?.toLowerCase().indexOf(data.toLowerCase()) >= 0)
      || (x?.line1?.toLowerCase().indexOf(data.toLowerCase()) >= 0)
      || (x?.line2?.toLowerCase().indexOf(data.toLowerCase()) >= 0)
      || (x?.town?.toLowerCase().indexOf(data.toLowerCase()) >= 0)
      || (x?.postalCode?.toLowerCase().indexOf(data.toLowerCase()) >= 0)
      || (x?.region?.isocodeShort?.toLowerCase().indexOf(data.toLowerCase()) >= 0)
    );
  }
  onCancel() {
    this.searchedClientList = this.getuserData;
  }
}
