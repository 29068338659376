import { HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  GlobalMessageService,
  GlobalMessageType, HttpErrorHandler, Priority,
  RoutingService,
  TranslationService
} from '@spartacus/core';

@Injectable({
  providedIn: 'root',
})
export class UnauthorizedHandler extends HttpErrorHandler {
  getPriority?(): Priority;
  responseStatus = 401;

  constructor(
    private globalMessage: GlobalMessageService,
    private readonly router: RoutingService,
    private translation: TranslationService
  ) { super(globalMessage); }


  handleError(request: HttpRequest<any>, errorResponse: HttpErrorResponse) {
    const href = window.location.href;
    if (request.url.indexOf("/products/") > 0 || request.url.indexOf("/Open-Catalogue/") > 0) {
      this.translation.translate('errors.AccessDenied').subscribe((res) => {
        this.globalMessage.add(res,
          GlobalMessageType.MSG_TYPE_ERROR
        );
      });
      this.router.goByUrl('/');
    } else if (href.indexOf("/cart/import") > 0) {
      this.router.goByUrl('/');
    } else if (request.url.indexOf("/place-multiple-orders") > 0) {
      this.translation.translate('errors.agentPlaceOrder').subscribe((res) => {
        this.globalMessage.add(res,
          GlobalMessageType.MSG_TYPE_ERROR
        );
      });
      this.router.goByUrl('/');
    }
  }
}
