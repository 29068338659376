
  <section class="col-md-8 col-sm-8 pay-section float-left account-reg-section">
    <app-user-header
      [selectedHeader]="userData?.header"
      [tableCategory]="creditInvoice"
    ></app-user-header>

    <div
      class="
        col-xs-12 col-sm-12 col-md-12
        Registration-split
        confirmation-split
      "
    >
      <h3 class="pdp-title">
        {{ "userRegistration.userConfirmation.title" | cxTranslate }}
        {{ message?.title }}
      </h3>
      <span class="confirmation-txt" *ngIf="message?.wholesalerFlag else directMessage">
        {{"userRegistration.userConfirmation.indirectContent" | cxTranslate}}
      </span>

      <ng-template #directMessage>
        <span class="confirmation-txt">
        {{"userRegistration.userConfirmation.content" | cxTranslate}}
        </span>
        <br/>
        <div class="invoice-btngrps">
          <button
            type="button"
            class="btn btn-default oi-blue-button reviewvalidbtn"
            data-di-id="di-id-41a05d18-6b04a424"
            [routerLink]="['/Open-Catalogue/c/1']">
            {{ "userRegistration.userConfirmation.catalog_button" | cxTranslate }}
          </button>
        </div>
      </ng-template>
    </div>
  </section>

