<app-faq-title></app-faq-title>
<div class="faq-container">
  <div class="child-container-one">
    <ngb-accordion class="parent">
      <ngb-panel *ngFor="let f of faq">
        <ng-template ngbPanelHeader let-opened="opened">
          <div
            class="
              d-flex
              align-items-center
              justify-content-between
              faq-panel-header
            "
          >
            <h3 class="m-0">{{ f.item }}</h3>
            <button ngbPanelToggle class="btn btn-link p-0">
              <span *ngIf="!opened" class="plus-icon blue"></span
              ><span *ngIf="opened" class="minus-icon blue"></span>
            </button>
          </div>
        </ng-template>
        <ng-template ngbPanelContent>
          <div class="expand-all-sec all">
            <span class="expand-toggle" (click)="toggleAll()">All</span>
          </div>
          <ngb-accordion #a="ngbAccordion" class="child">
            <ngb-panel *ngFor="let q of f.questions">
              <ng-template ngbPanelHeader let-opened="opened">
                <div
                  class="
                    d-flex
                    align-items-center
                    justify-content-between
                    sub-faq-panel-header
                    panel-title
                  "
                >
                  <h5 class="m-0 expand-details">{{ q.question }}</h5>
                  <button ngbPanelToggle class="btn btn-link p-0 sub-icon">
                    <span *ngIf="!opened" class="plus-icon blue"></span
                    ><span *ngIf="opened" class="minus-icon blue"></span>
                  </button>
                </div>
              </ng-template>
              <ng-template ngbPanelContent class="pl-5 pt-2 pb-2">
                <app-accordian-answers
                  [answers]="q.answer"
                ></app-accordian-answers>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
  </div>
</div>
