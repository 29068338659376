
  <section
    class="
      col-xs-12 col-sm-8 col-md-8
      left-section
      Registration-nav-section
      section-height
    "
  >
    <form [formGroup]="passwordAnswerForm">
      <div
        class="col-xs-12 col-sm-12 col-md-12 Registration-split recovery-split"
      >
        <h3 class="login-help-title">
          {{ "forgotUserPassword.confirmIdentity" | cxTranslate }}
        </h3>
        <div class="col-xs-12 col-sm-8 col-md-8 p0">
          <div class="col-xs-12 col-sm-12 col-md-12 p0 Fields-container">
            <label class="Fields-label">{{passQuestion}}</label>
            <input
              type="text"
              class="Fields-input"
              name="answer"
              id="answer"
              formControlName="answer"
              (ngModelChange)="validateAnswer()"
            />
            <span
              class="error-message"
              role="alert"
              aria-live="assertive"
              *ngIf="
                (passwordAnswerForm.get('answer').hasError('required') &&
                  passwordAnswerForm.get('answer').touched) ||
                answerRequired
              "
              >{{ "forgotUserPassword.requiredField" | cxTranslate }}
            </span>
          </div>
          <div class="Fields-btn-group">
            <button
              type="submit"
              class="btn btn-default blue-button hintanswerRecovery"
              (click)="onSubmit()"
            >
              {{ "forgotUserPassword.submit" | cxTranslate }}
            </button>
          </div>
        </div>
      </div>
    </form>
  </section>
