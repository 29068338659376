
<p *ngIf="!selectedFile" class="file-upload-notice"> {{ "uploadFile.notice" | cxTranslate }}</p>
<form [formGroup]="myForm" >
  <input
    type="file"
    name="file"
    id="file"
    (change)="onFileChanged($event)"
    formControlName="file"
    accept=".pdf,.eml,.doc,.docx">
    <label for="file"> {{ "uploadFile.buttonText" | cxTranslate }}</label>
    <p class="file-name"*ngIf="selectedFile">{{selectedFile.name}}</p>

</form>
