    <div
      class="col-xs-12 col-sm-12 col-md-12 Registration-split recovery-split"
    >
      <h3 class="login-help-title">
        {{ "forgotUserPassword.recoverUsername" | cxTranslate }}
      </h3>
      <form [formGroup]="recoverPasswordForm">
        <div class="col-xs-12 col-sm-8 col-md-8 p0">
          <div
            class="
              col-xs-12 col-sm-12 col-md-12
              p0
              Fields-container Fields-container-error
            "
          >
            <label for="emailAddress" class="Fields-label">{{
              "forgotUserPassword.emailAddress" | cxTranslate
            }}</label>
            <input
              type="text"
              class="Fields-input"
              id="email"
              name="email"
              formControlName="email"
              (ngModelChange)="validateRecoverPassword()"
            />
            <span class="error-message" role="alert" aria-live="assertive"
              ><b
                *ngIf="
                  (recoverPasswordForm.get('email').hasError('required') &&
                    recoverPasswordForm.get('email').touched) ||
                  (recoverPasswordrequired &&
                    !recoverPasswordForm.get('email').hasError('pattern'))
                "
                >{{ "forgotUserPassword.EmailRequired" | cxTranslate }}</b
              >
              <b *ngIf="recoverPasswordForm.get('email').hasError('pattern')">{{
                "forgotUserPassword.invalidEmail" | cxTranslate
              }}</b></span
            >
          </div>
          <div class="Fields-btn-group">
            <button
              type="submit"
              class="btn btn-default blue-button"
              (click)="submitrecoverPasswordForm()"
            >
              {{ "forgotUserPassword.submit" | cxTranslate }}
            </button>
          </div>
        </div>
      </form>
      <hr class="Fields-container-line" />
      <form [formGroup]="passwordResetForm">
        <h3 class="login-help-title margin-top0">
          {{ "forgotUserPassword.resetPassword" | cxTranslate }}
        </h3>
        <div class="col-xs-12 col-sm-8 col-md-8 p0">
          <div
            class="
              col-xs-12 col-sm-12 col-md-12
              p0
              Fields-container Fields-container-error
            "
          >
            <label id="address2" class="Fields-label">{{
              "forgotUserPassword.emailAddress" | cxTranslate
            }}</label>
            <input
              type="text"
              class="Fields-input"
              formControlName="email"
              id="email"
              name="email"
              (ngModelChange)="validatepasswordReset()"
            />
            <span class="error-message" role="alert" aria-live="assertive">
              <b
                *ngIf="
                  (passwordResetForm.get('email').hasError('required') &&
                    passwordResetForm.get('email').touched) ||
                  (passwordResetRequired &&
                    !passwordResetForm.get('email').hasError('pattern'))
                "
                >{{ "forgotUserPassword.EmailRequired" | cxTranslate }}</b
              >
              <b *ngIf="passwordResetForm.get('email').hasError('pattern')">{{
                "forgotUserPassword.invalidEmail" | cxTranslate
              }}</b>
            </span>
          </div>
          <div class="Fields-btn-group">
            <button
              type="submit"
              class="btn btn-default blue-button"
              (click)="submitpasswordResetForm()"
            >
              {{ "forgotUserPassword.continue" | cxTranslate }}
            </button>
          </div>
        </div>
      </form>
      <br />
    </div>