import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { translationChunksConfig, translations } from '@spartacus/assets';
import { OccConfig, provideConfig } from '@spartacus/core';
import {
  defaultCmsContentProviders,
  layoutConfig,
  mediaConfig
} from '@spartacus/storefront';
import { environment } from 'src/environments/environment';

const occConfig: OccConfig = { backend: { occ: {} } };
// only provide the `occ.baseUrl` key if it is explicitly configured, otherwise the value of
// <meta name="occ-backend-base-url" > is ignored.
// This in turn breaks the deployment in CCv2.
// https://github.com/SAP/spartacus/issues/5886
occConfig.backend.occ.prefix = '/occ/v2/';
if (environment.occBaseUrl) {
  occConfig.backend.occ.baseUrl = environment.occBaseUrl;
}

const PRODUCT_TYPE = 'DEFAULT';

@NgModule({
  declarations: [],
  imports: [BrowserModule],
  providers: [
    provideConfig(layoutConfig),
    provideConfig(mediaConfig),
    ...defaultCmsContentProviders,
    provideConfig({
      backend: occConfig.backend,
      smartEdit: {
        storefrontPreviewRoute: 'cx-preview',
        // TODO: adjust origins as necessary
        allowOrigin: 'localhost:9002, *.*.model-t.cc.commerce.ondemand.com:443, *.pharma.aventis.com:443',
      },
      context: {
        urlParameters: ['baseSite', 'language', 'currency'],
        baseSite: ['dz'],
        currency: ['DZD'],
        language: ['fr_DZ','en'],
      },
      i18n: {
        resources: translations,
        chunks: translationChunksConfig,
        fallbackLang: 'fr',
      },
      features: {
        level: '6.0',
      },
    }),
    provideConfig({
      backend: {
        occ: {
          endpoints: {
            asmCustomerSearch: '/occ/v2/dz/assisted-service/customers/search',
            // product: {
            //   default:
            //     'products/${productCode}?fields=DEFAULT,averageRating,images(FULL),classifications,manufacturer,numberOfReviews,categories(DEFAULT),baseOptions,baseProduct,variantOptions,variantType',
            //   details:
            //     'products/${productCode}?fields=averageRating,stock(DEFAULT),description,availableForPickup,code,url,price(DEFAULT),numberOfReviews,manufacturer,categories(BASIC,name,url),priceRange,multidimensional,tags,images(FULL)',
            // },
            // productSearch: `users/test01/products/search?fields=products(${PRODUCT_TYPE},images(DEFAULT)),facets,breadcrumbs,pagination(DEFAULT),sorts(DEFAULT),freeTextSearch,currentQuery`,
            // product:'products/${productCode}?fields=code,name,summary,price(formattedValue,DEFAULT),images(galleryIndex,FULL),baseProduct,averageRating,stock(DEFAULT),description,availableForPickup,url,numberOfReviews,manufacturer,categories(BASIC),priceRange,multidimensional,tags,DEFAULT,classifications,baseOptions,variantOptions,variantType',
            // product: {
            //   default:
            //     'products/${productCode}?fields=DEFAULT,averageRating,images(FULL),classifications,manufacturer,numberOfReviews,categories(DEFAULT),baseOptions,baseProduct,variantOptions,variantType',
            //   details:
            //     'products/${productCode}?fields=averageRating,stock(DEFAULT),description,availableForPickup,code,url,price(DEFAULT),numberOfReviews,manufacturer,categories(BASIC),priceRange,multidimensional,tags,images(FULL)',
            // },
          },
        },
      },
    } as OccConfig),
  ],
})
/*
 *  https://api.czz3177o4e-sanofiave3-d1-public.model-t.cc.commerce.ondemand.com/occ/v2/vsca
 * /products/search?fields=products(DEFAULT)%2C
 * facets%2Cbreadcrumbs%2C
 * pagination(DEFAULT)%2Csorts(DEFAULT)%2C
 * freeTextSearch%2C
 * currentQuery&query=%3Arelevance%3AallCategories%3A1&pageSize=10&lang=en&curr=CAD
 * */
export class SpartacusConfigurationModule {}
