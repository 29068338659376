import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalMessageService, GlobalMessageType, LanguageService, OccEndpointsService, RoutingService } from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { enGbLocale } from 'ngx-bootstrap/locale';
import { CustomAddToCartService } from 'src/app/custom-add-to-cart/custom-add-to-cart.service';
import { CustomPdpService } from 'src/app/custom-pdp/custom-pdp.service';
import { CustomProfileAndPreferencesService } from 'src/app/custom-profile-and-preferences/custom-profile-and-preferences.service';
import { CustomRegistrationService } from 'src/app/custom-user-registration/custom-registration.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { ProductUtils } from 'src/app/shared/utils/productUtils';
import { CustomInfluenzaService } from '../custom-influenza.service';
import { ProductDetailsInfluenzaComponent } from '../product-details-influenza/product-details-influenza.component';
import * as _ from 'underscore';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-flu-cart',
  templateUrl: './flu-cart.component.html',
  styleUrls: ['./flu-cart.component.scss'],
})
export class FluCartComponent implements OnInit, OnDestroy {
  header = 'ViewCart';
  disableButton = false;
  showDangerAlert = true;
  currentTime = new Date().getHours();
  paymentsArray: any = [];
  duplicateCartProdArray: any = [];
  updatedProductsInCartArray: any = [];
  chosenPaymentMethod = 1;
  selectedHeader: any = 'Checkout';
  getuserData: any;
  usergetDataObj: any;
  userAccountgetData: any;
  selectedData: any;
  closeResult = '';
  flag = true;
  searchedClientList: any;
  userData: any = [];
  fluProductsList = [];
  influenzaChildCartId: any;
  btnText: any = 'Proceed to Checkout';
  baseUrl: any;
  showDatePicker = false;
  minDate: Date;
  deliveryDate: Date;
  deliveryDateFormat = '';
  deliveryCrossIcon = false;
  dateIsRequired = false;
  showEmptyCart = false;
  entriesForPopup: any = [];
  fallbackImage = '../../assets/i18n-assets/sanofi_missing_product.jpg';
  customerId: any;
  shipToUnitId: any;
  estimatedSubtotal: any;
  activeCartId: any;
  modalPopupRef: any;
  changeLocation = true;
  listSelectedData: any;
  url: any;
  urlPath: any;
  compName = 'SanofiFluDashboardBannerComponent';
  deleteSuccess: string;
  userDetails: any;
  minDeliveryDate = new Date();
  maxDeliveryDate = new Date();
  stopApiCounter: any = 0;
  cartFluProductsOfSubscribe: any;
  activeSeasonId: any = this.sharedService.getSessionStorage('activeSeasons');
  cartIdSubscriber: any;
  removedFluCartSubscriber: any;
  changeLocSubscriber: any;
  itemDeleteSubscriber: any;
  cartDleteSubscriber: any;
  updateFluSubscriber: any;
  createCartSubscriber: any;
  userAccountSubscriber: any;
  userAccountData: any;
  checkoutSubscriber: any;
  checkoutInfluenzaSubscriber: any;
  dateFromCheckout: any = '';
  prodCodes = [];
  activeSeasonSubscriber: any;
  dateSelectedFromPicker: any = new Date();
  volumePrices = [];
  volumePriceFlag: boolean;
  volumePriceSubscriber: Subscription;
  blockCheckout = false;
  languageUsed: any = '';
  constructor(
    private readonly influenzaService: CustomInfluenzaService,
    private readonly pfService: CustomProfileAndPreferencesService,
    private readonly ref: ChangeDetectorRef,
    private readonly modalService: NgbModal,
    private readonly accountRegService: CustomRegistrationService,
    private readonly router: RoutingService,
    private readonly user: UserAccountFacade,
    private readonly sharedService: SharedService,
    private readonly localeService: BsLocaleService,
    public readonly datepipe: DatePipe,
    private readonly occEndPoint: OccEndpointsService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly addToCart: CustomAddToCartService,
    private readonly pdpService: CustomPdpService,
    private readonly globalMessage: GlobalMessageService,
    private readonly languageService: LanguageService
  ) {

    enGbLocale.weekdaysShort = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
    enGbLocale.week.dow = 0;
    defineLocale('en-gb', enGbLocale);
    this.localeService.use('en-gb');
    this.baseUrl = this.occEndPoint.getBaseUrl({
      baseUrl: true,
      prefix: false,
      baseSite: false,
    });
    this.minDeliveryDate.setDate(this.minDeliveryDate.getDate());
  }

  ngOnInit() {
    this.languageService.getActive().subscribe((langRes) => {
      this.languageUsed = langRes;
      this.ref.detectChanges();
    });
    this.url = this.activatedRoute.url;
    this.urlPath = this.url?._value[this.url?._value.length - 1].path;
    this.user.get().subscribe((data) => {
      if (data) {
        this.userAccountgetData = data;
        this.getUserAccountData(this.userAccountgetData.userName);
        this.influenzaService.getCartIdofUser(this.userAccountgetData.userName);
        this.cartIdSubscriber = this.influenzaService.cartIdActiveOfUser.subscribe((cartIdData) => {
          if (cartIdData) {
            if (cartIdData?.carts?.length > 0) {
              const cartIdResponse = cartIdData?.carts;
              let activeCartIdArray=[]
              activeCartIdArray= cartIdResponse?.filter((item)=>item?.saveTime===undefined);
             this.activeCartId = activeCartIdArray[activeCartIdArray?.length - 1]?.code;
              this.cartIdSubscriber?.unsubscribe();
              this.getCartItemsFromDb();
            } else {
              this.showEmptyCart=true;
              this.ref.detectChanges();
              // this.influenzaService.CreateCartIdofUser(this.userAccountgetData.userName);
              // this.cartIdSubscriber?.unsubscribe();
              // this.createCartForUser();
            }
          }
        });
        this.influenzaService.getActiveSeasonsFromDb(this.userAccountgetData.userName);
        this.activeSeasonSubscriber = this.influenzaService.activeSeaons.subscribe((res) => {
          if (res) {

            this.activeSeasonId = res?.categoryData?.[0]?.code;
            this.activeSeasonSubscriber?.unsubscribe();
          }
        });
      }

    });



    this.ref.detectChanges();
    this.paymentsArray = [
      { name: 'Bill Me Later', code: 1 },
      { name: 'Pay by Card', code: 2 },
      { name: 'Manage Payment Methods', code: 3 },
    ];
    this.chosenPaymentMethod = 1;
    this.proceedToShipping();
  }
  ngOnDestroy() {
    this.volumePriceSubscriber?.unsubscribe();
    this.createCartSubscriber?.unsubscribe();
    this.cartFluProductsOfSubscribe?.unsubscribe();
    this.removedFluCartSubscriber?.unsubscribe();
    this.changeLocSubscriber?.unsubscribe();
    this.itemDeleteSubscriber?.unsubscribe();
    this.cartDleteSubscriber?.unsubscribe();
    this.updateFluSubscriber?.unsubscribe();
    this.checkoutSubscriber?.unsubscribe();
    this.userAccountSubscriber?.unsubscribe();
    this.userAccountData?.unsubscribe();
    this.cartIdSubscriber?.unsubscribe();
    this.activeSeasonSubscriber?.unsubscribe();

  }
  checkVolumePrice() {
    const params = {
      'productCodes': this.prodCodes
    };
    this.influenzaService.getVolumePrice(params);
    this.volumePriceSubscriber = this.influenzaService.getVolumePrices.subscribe(res => {
      this.volumePrices = res?.productPrices;
      this.ref.detectChanges();
      this.volumePriceSubscriber?.unsubscribe();
    });
  }

  // createCartForUser() {
  //   this.createCartSubscriber = this.influenzaService.createCartOfUser.subscribe((responseCartId) => {
  //     if (responseCartId) {
  //       this.activeCartId = responseCartId?.code;
  //       this.createCartSubscriber?.unsubscribe();
  //       this.getCartItemsFromDb();

  //     }
  //   });
  // }

  navigateToProducts() {
    this.router.goByUrl('/Open-Catalogue/c/1');
  }

  toggle(popover) {
    if (popover.isOpen()) {
      popover.close();
    } else {
      popover.open();
    }
  }
  productDetails(productDetails) {
    this.pdpService.getPDPCategory(productDetails.code);
    this.pdpService.productCode$.subscribe((res) => {
      if (res && res?.code === productDetails.code) {
        this.modalService.dismissAll();
        const modalRef = this.modalService.open(ProductDetailsInfluenzaComponent,
          { windowClass: 'product-details-inf-modal' });
        modalRef.componentInstance.productDetails = res;
        modalRef.componentInstance.standardCartCode = res.code;
      }
    });
  }
  onDeliveryDateChange(value: Date): void {
    if (value) {
      this.deliveryCrossIcon = true;
      this.deliveryDateFormat = this.datepipe?.transform(value, 'yyyy-MM-dd');
      this.dateIsRequired = false;
      this.dateSelectedFromPicker=value;
    } else {
      this.dateIsRequired = true;
      this.deliveryDateFormat = '';
    }
  }
  clearDate() {
    this.deliveryDate = null;
    this.deliveryCrossIcon = false;


  }
  closeDangerAlert() {
    this.showDangerAlert = false;
  }
  getCartItemsFromDb() {

    this.showDatePicker = false;
    this.fluProductsList = [];
    this.removedItemsFromCart();
  }
  assignProductsFromCart() {
    this.influenzaService.getFluProductsFromCart(
      this.userAccountgetData.userName,
      this.activeCartId, 'cart'
    );
    this.cartFluProductsOfSubscribe = this.influenzaService.fluProductsFromCart.subscribe((FluProducts) => {
      if (FluProducts) {
        let array = [];
        let entries = [];
        const cartProducts = [];
        array = FluProducts?.childCarts;

        if (array) {
          array.forEach((element) => {
            if (element.cartType === 'INFLUENZA') {
              this.influenzaChildCartId = element.code;
              this.sharedService.setSessionStorage('influenzaChildCartId', this.influenzaChildCartId);
              entries = element.entries;

              if (element?.shipToParty) {
                this.selectedData = element?.shipToParty?.shippingAddresses.filter((item) => item.unitID === element.shipToParty.uid);
                this.selectedData = _.first(this.selectedData);
              } else {

                if (this.getuserData && this.getuserData?.length > 0) {
                  this.selectedData = _.first(this.getuserData.filter((item) => item.unitID === this.usergetDataObj?.uid));
                }
              }
              this.entriesForPopup = entries;
              this.estimatedSubtotal = element.subTotal.value;
            }
          });
        }
        entries?.forEach((ele, index) => {

          if (ele?.product?.available && !ele?.product?.discontinued) {
            const product = ele.product;
            const totalQty = ele.quantity;
            const entNo = ele.entryNumber;
            const dosPeruni = product.quantityPerUnit;
            let thumbImg = '';
            if (product?.images) {
              thumbImg = product?.images[2]?.url;
            }
            const productFluName = product.name;
            const prodCode = product.code;
            const onsnBoolean = product.onsn;
            const descriptionFluProduct = product.description;
            const priceOfProduct = ele.basePrice?.value;
            const totalDosesOfFluProduct = parseInt(totalQty) * parseInt(dosPeruni);
            const obj = {
              entryNum: entNo,
              totalQuantity: totalQty,
              dosesPerUnit: dosPeruni,
              thumbNailImg: thumbImg,
              code: prodCode,
              onsn: onsnBoolean,
              desc: descriptionFluProduct,
              prodName: productFluName,
              price: priceOfProduct,
              totalDoses: totalDosesOfFluProduct,
              availableFlag: product.available,
              discontinuedFlag: product.discontinued,
            };

            if (obj.onsn === 'ON') {
              this.showDatePicker = true;
            }
            if (!this.prodCodes?.includes(obj?.code)) {
              this.prodCodes.push(obj?.code);
            }
            cartProducts.push(obj);
          }
          if (index + 1 === entries?.length) {
            if (!this.showDatePicker) {
              this.deliveryDateFormat = '';
            } else {
              this.setDeliveryDate();
            }
          }

        });
        this.fluProductsList = cartProducts;
        this.checkVolumePrice();
        this.addToCart.getAllCarts(this.userAccountgetData?.userName);
        this.setEmptyCart();
        this.ref.detectChanges();

        this.cartFluProductsOfSubscribe?.unsubscribe();
      }

    });
  }
  removedItemsFromCart() {
    this.influenzaService.removedFluCartEntries(this.activeCartId, this.userAccountgetData?.userName);
    this.removedFluCartSubscriber = this.influenzaService.removedFluCartItems.subscribe((responseFluRemoval: any) => {
      if (responseFluRemoval) {
        const removedData = responseFluRemoval?.removedEntries?.cartModifications;
        if (removedData && removedData?.length > 0) {
          for (const data of removedData) {
            this.globalMessage.add(data.statusMessage,
              GlobalMessageType.MSG_TYPE_ERROR
            );
          }

        }
        this.assignProductsFromCart();
        this.removedFluCartSubscriber?.unsubscribe();
      }

    });
  }

  setEmptyCart() {
    if (this.fluProductsList.length > 0) {
      this.showEmptyCart = false;
    } else {
      this.showEmptyCart = true;
    }
  }

  setDeliveryDate() {
    this.minDeliveryDate?.setDate(this.minDeliveryDate?.getDate());
    if ((this.minDeliveryDate?.getDay() === 0) || (this.minDeliveryDate?.getDay() === 6)) {
      for (let i = 1; i <= 3; i++) {
        this.minDeliveryDate?.setDate(this.minDeliveryDate?.getDate() + 1);
        if ((this.minDeliveryDate?.getDay() !== 0) && (this.minDeliveryDate?.getDay() !== 6)) {
          break;
        }
      }
    }
    this.maxDeliveryDate?.setDate(this.minDeliveryDate?.getDate() + (6));
    this.deliveryDate = this.minDeliveryDate;
    this.deliveryDateFormat = this.datepipe?.transform(this.minDeliveryDate, 'yyyy-MM-dd');
  }

  OnFluPrint() {
    window.print();
  }
  proceedToShipping() {
    const selectvaccine = { header: 'selectvaccine' };
    this.accountRegService.sectionChange.next(selectvaccine);
  }
  toggleWithGreeting(popover, greeting: string, language: string) {
    if (popover.isOpen()) {
      popover.close();
    } else {
      popover.open({ greeting, language });
    }
  }

  onOptionsSelected(data) {
    this.listSelectedData = data;
  }

  onSubmit() {
    if (this.listSelectedData) {
      this.selectedData = this.listSelectedData;
      this.shipToUnitId = this.listSelectedData?.unitID;
      this.updateShipToIdInDb();
    } else {
      this.searchedClientList = this.getuserData;
    }

  }
  updateShipToIdInDb() {
    this.influenzaService.updateShipToId(this.userAccountgetData.userName,
      this.activeCartId, this.shipToUnitId, false);
    this.changeLocSubscriber = this.influenzaService.changedLocation.subscribe((boolean) => {
      if (boolean) {
        this.changeLocSubscriber?.unsubscribe();
        this.getCartItemsFromDb();
      }
    });
  }
  performQuantityComputation(user, operator, idData, quantityOfUnits): void {
    this.btnText = 'Update Order';
    ProductUtils.alterQuantityforInfluenza(operator, idData, quantityOfUnits, 'cart', '');
    const quantity = document.getElementById(
      `prodQuantity${idData}`
    ) as HTMLInputElement;
    const obj = {
      entryNumber: user.entryNum,
      quantity: quantity.value,
    };
    if (this.duplicateCartProdArray.indexOf(user.code) === -1) {
      this.duplicateCartProdArray.push(user.code);
      this.updatedProductsInCartArray.push(obj);
    } else {
      let indexOfProdCode;
      this.updatedProductsInCartArray.filter((data, index) => {
        if (data.entryNumber === user.entryNum) {
          indexOfProdCode = index;
        }
      });
      if (parseInt(quantity.value) === 0) {
        this.updatedProductsInCartArray.splice(indexOfProdCode, 1);
        this.duplicateCartProdArray.splice(
          this.duplicateCartProdArray.indexOf(user.entryNum),
          1
        );
      } else {
        this.updatedProductsInCartArray[indexOfProdCode].quantity =
          quantity.value;
      }
    }
    this.ref.detectChanges();
  }
  deleteIndividualCartItem(user, popover) {
    this.influenzaService.deleteCartItems(
      this.userAccountgetData.userName,
      this.activeCartId,
      user.entryNum,
      this.influenzaChildCartId
    );
    this.itemDeleteSubscriber = this.influenzaService.itemDlete.subscribe((bool) => {
      if (bool) {
        this.itemDeleteSubscriber?.unsubscribe();
        this.getCartItemsFromDb();
        this.deleteSuccess = user.prodName;
        this.addToCart.getAllCarts(this.userAccountgetData?.userName);
      }
    });
    if (popover.isOpen()) {
      popover.close();
    } else {
      popover.open();
    }
  }

  deleteEntireCart(popover) {
    if (popover.isOpen()) {
      popover.close();
    } else {
      popover.open();
    }
    this.cartDleteSubscriber = this.influenzaService.cartDlete.subscribe((res) => {
      if (res) {
        this.addToCart.getAllCarts(this.userAccountgetData?.userName);
        this.getCartItemsFromDb();
        this.cartDleteSubscriber?.unsubscribe();
      }
    });
    this.influenzaService.deleteEntireCart(
      this.userAccountgetData.userName,
      this.influenzaChildCartId
    );

  }
  navigateToCheckout() {
    this.disableButton = true;
    if (this.btnText === 'Update Order') {
      this.influenzaService.updateFluProductsToCart(
        this.userAccountgetData.userName,
        this.activeCartId,
        this.influenzaChildCartId,
        this.updatedProductsInCartArray
      );
      this.updateFluSubscriber = this.influenzaService.updateFluCart.subscribe((resposne) => {
        if (resposne) {
          this.addToCart.getAllCarts(this.userAccountgetData?.userName);
          this.btnText = 'Proceed to Checkout';
          this.updateFluSubscriber?.unsubscribe();
          this.disableButton = false;
          this.getCartItemsFromDb();
        }
      });
    } else {
      if (this.showDatePicker) {
        if (!this.deliveryDateFormat) {
          this.dateIsRequired = true;
        } else {
          this.proceedForCheckout();
        }
      } else {
        this.proceedForCheckout();
      }

    }
  }
  proceedForCheckout() {

    const params =

    {
      'customerId': this.customerId,
      'date': this.deliveryDateFormat,
      'deliveryMode': 'standard',
      'pickupTime': '',
      'shipToUnitId': this.shipToUnitId,
      'standard': false
    };
    this.influenzaService.checkoutItems(
      this.userAccountgetData.userName,
      this.activeCartId,
      params
    );

    this.checkoutSubscriber = this.influenzaService.checkoutInfluenza$.subscribe((rsponeDta) => {
      if (rsponeDta) {
        this.influenzaService.cartType = 'I';
        this.checkoutSubscriber?.unsubscribe();
        this.disableButton = false;
        this.router.goByUrl('/influenza/checkout');
      }
    });


  }
  backToCategory() {

    this.influenzaService.contiueShopping = true;


    this.router.goByUrl('/influenza/category');
  }
  backToDashBoard() {
    this.router.goByUrl('/influenza/dashboard');
  }
  // ============================Loc Strip Methods=======================
  onCancel() {
    this.searchedClientList = this.getuserData;
  }
  getUserAccountData(userId) {
    this.pfService.getUserAccountDataFromDb(userId);
    this.userAccountData = this.pfService.userAccountData.subscribe((userData) => {
      if (userData.res != null && userData.res !== undefined && userData.res?.length !== 0) {
        const shippingAddressesArray = userData?.res?.defaultB2BUnit?.shippingAddresses;
        if (shippingAddressesArray) {
          this.shipToUnitId = shippingAddressesArray[0]?.unitID;

        }
        this.usergetDataObj = userData?.res?.defaultB2BUnit;
        this.customerId = this.usergetDataObj?.uid;
        const accountId = this.usergetDataObj?.uid;
        this.pfService.getUserAccountDetailsDataFromDb(userId, accountId);
        this.userAccountSubscriber = this.pfService.userAccountDetails.subscribe((userDataget) => {
          if (userDataget) {
            this.getuserData = userDataget.res?.shippingAddresses;
            this.searchedClientList = userDataget?.res?.shippingAddresses;
            this.blockCheckout = userDataget?.res?.orderBlockFlag ? true : false;
          }
          if (this.getuserData?.length <= 1) {

            this.changeLocation = false;
          }
          this.userAccountSubscriber?.unsubscribe();
        });

      }
      this.userAccountData?.unsubscribe();
    });
  }
  SearchClientName(data: any) {
    if (data.length > 0) {
      this.searchedClientList = this.getuserData.filter(x =>
        (x.companyName?.toLowerCase().indexOf(data.toLowerCase()) >= 0)
        || (x.unitID?.toLowerCase().indexOf(data.toLowerCase()) >= 0)
        || (x.line1?.toLowerCase().indexOf(data.toLowerCase()) >= 0)
        || (x.line2?.toLowerCase().indexOf(data.toLowerCase()) >= 0)
        || (x.town && x.town.toLowerCase().indexOf(data.toLowerCase()) >= 0)
        || (x.postalCode && x.postalCode.toLowerCase().indexOf(data.toLowerCase()) >= 0)
        || (x.region.isocodeShort && x.region.isocodeShort.toLowerCase().indexOf(data.toLowerCase()) >= 0));
    } else {
      this.searchedClientList = this.getuserData;

    }
  }

  open(content): void {
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  closeAlert() {
    this.deleteSuccess = '';
  }
  volumePricing(user) {
    this.pdpService.getPDPCategory(user.code);
    this.pdpService.productCode$.subscribe((res) => {
      this.modalService.dismissAll();
      if (res && res?.code === user.code) {
        this.userDetails = res;
      }
    });
  }
}
