<section class="container">
  <section class="row">
    <section class="col-12 col-sm-12 col-md-12 col-lg-12  "
     [ngClass]="{'col-xl-6':urlPath==='dashboard', 'col-xl-12':urlPath!='dashboard'}">
      <section class="container">
        <div  
        [ngClass]="{'chartMargin-Width':urlPath==='dashboard'}">
          <canvas baseChart [data]="doughnutChartData"
          
          [colors]="chartColors" [labels]="doughnutChartLabels"
       [chartType]="doughnutChartType" [options]="doughnutChartOptions">
          </canvas>


        </div>
         <section  *ngIf="doughnutChartData.length!==0"
         class="row"
        [ngClass]="{'dose-total-Section':urlPath==='dashboard' ,'dose-total-Section-reservation-details':urlPath!='dashboard'}">

        <section class="col-xl-12"
        [ngClass]="{'padding-left':urlPath==='dashboard'}">
          <img src="/assets/icons/chartImage.png"
           alt="chart-image" [ngClass]="{'chart-image-css':urlPath==='dashboard', 'chart-image-reservation-details-css':urlPath!='dashboard'}">
        </section>
        
          <section  class="col-xl-12"
          [ngClass]="{'doseShip-row':urlPath==='dashboard', 'doseShip-row-reservation-details':urlPath!='dashboard'}">
           <span class="doseNum text-center" 
            
           >{{dosesInChartDisplay}} of {{totalDoses}}</span>
         </section>
         <section class="col-xl-12"
         [ngClass]="{'doeses-Shipped':urlPath==='dashboard', 'doeses-Shipped-reservation-details':urlPath!='dashboard'}"
         >
         <span class="text-center"> Doses {{statusInChrtsDisplay}}</span>  
         </section>
        </section> 
     
      </section>


    </section>
    <section class="col-12 col-sm-12 col-md-12 col-lg-12  tableDiv" [ngClass]="{'col-xl-6':urlPath==='dashboard','col-xl-12':urlPath!='dashboard'}">
      <table aria-describedby="cardDetailsTable" class="table">
        <thead>
          <tr class="header-row">
            <th scope="col" class="header">{{'orderConfirmation.shipmentStaus'|cxTranslate}}</th>
            <th scope="col" class="header">{{'orderConfirmation.shipDate'|cxTranslate}}</th>
            <th scope="col" class="header">{{'orderConfirmation.unitsDoses'|cxTranslate}}</th>
            <th scope="col" class="header">{{'orderConfirmation.tracking'|cxTranslate}}</th>
          </tr>
        </thead>

        <tbody>
          <tr class="content-row" *ngFor="let i of chartTableArray;let j=index" [ngClass]="{'alternate-Row':j%2===0}">
            <td class="statusTd col-Divider">
              {{i?.status}}
            </td>
            <td class="col-Divider">
              -
            </td>
            <td class="col-Divider">
              {{i.totalQuantity}}/{{i.doses}}
            </td>
            <td class="col-Divider">

              -
            </td>

          </tr>
        </tbody>
      </table>
    </section>
  </section>
</section>