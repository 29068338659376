import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BaseService {
  public baseUrl = this.occEndPoint.getBaseUrl();
  jsonContentType = 'application/json';
  status: string;
  constructor(
    private readonly http: HttpClient,
    private readonly occEndPoint: OccEndpointsService
  ) {}

  // GET Service
  public get(relativeurl: string, queryParams?): Observable<any> {
    const url = this.occEndPoint.buildUrl(relativeurl);
    let params = new HttpParams();
    if (queryParams) {
      for (const param in queryParams) {
        if (param) {
          params = params.set(param, queryParams[param]);
        }
      }
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': this.jsonContentType,
      }),
      params,
    };
    return this.http
      .get(url, httpOptions)
      .pipe(tap(), catchError(this.handleError('get', [])));
  }
  // DELETE Service
  // public delete(relativeurl: string, queryParams?): Observable<any> {
  //   const url = this.occEndPoint.buildUrl(relativeurl);
  //   let params = new HttpParams();
  //   if (queryParams) {
  //     for (const param in queryParams) {
  //       if (param) {
  //         params = params.set(param, queryParams[param]);
  //       }
  //     }
  //   }
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': this.jsonContentType,
  //     }),
  //     params,
  //   };
  //   return this.http
  //     .delete(url, httpOptions)

  public delete(relativeurl: string, queryParams?): Observable<any> {
    const url = this.occEndPoint.buildUrl(relativeurl);
    let params = new HttpParams();
    if (queryParams) {
      for (const param in queryParams) {
        if (param) {
          params = params.set(param, queryParams[param]);
        }
      }
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': this.jsonContentType,
      }),
      params,
    };
    return this.http
      .delete(url, httpOptions)
      .pipe(tap(), catchError(this.handleError('delete', [])));
  }

  // public getPdf(relativeurl: string, queryParams?): Observable<any> {
  //   const url = this.occEndPoint.buildUrl(relativeurl);
  //   let params = new HttpParams();
  //   if (queryParams) {
  //     for (const param in queryParams) {
  //       if (param) {
  //         params = params.set(param, queryParams[param]);
  //       }
  //     }
  //   }
  //   const httpOptions: {
  //     headers: HttpHeaders;
  //     observe: 'response';
  //     params: HttpParams;
  //     reportProgress?: boolean;
  //     responseType: 'blob';
  //     withCredentials?: boolean;
  //   } = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/pdf',
  //     }),
  //     observe: 'response',
  //     params,
  //     responseType: 'blob',
  //   };

  //   return this.http
  //     .get(url, httpOptions)
  //     .pipe(tap(), catchError(this.handleError('get', [])));
  // }
  public getPdf(relativeurl: string, queryParams?): Observable<any> {
    const url = this.occEndPoint.buildUrl(relativeurl);
    let params = new HttpParams();
    if (queryParams) {
      for (const param in queryParams) {
        if (param) {
          params = params.set(param, queryParams[param]);
        }
      }
    }
    //responseType: "blob";
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/pdf',
        Accept: 'application/pdf',
      }),
      params,
      responseType: 'blob' as 'json',
    };
    return this.http.get(url, httpOptions);
  }

  // POST service
  public post(relativeUrl: string, queryParams): Observable<any> {
    const url = this.occEndPoint.buildUrl(relativeUrl);
    let params: any = queryParams;
    if (typeof (params) !== "string") {
      params = JSON.stringify(params);
    }
    const headers = new HttpHeaders({
      'Content-Type': this.jsonContentType,
    });
    const options = { headers };
    return this.http.post(url, params, options).pipe(
      tap(),
      catchError((err) => {
        //Handle the error here
        return throwError(err); //Rethrow it back to component
      })
    );
  }

  // PUT Service
  public put(relativeUrl: string, queryParams): Observable<any> {
    const url = this.occEndPoint.buildUrl(relativeUrl);

    const params = JSON.stringify(queryParams);
    const headers = new HttpHeaders({
      'Content-Type': this.jsonContentType,
    });
    const options = { headers };

    return this.http
      .put(url, params, options)
      .pipe(tap(), catchError(this.handleError('put', [])));
  }

  // PaTch Service
  public patch(relativeUrl: string, queryParams): Observable<any> {
    const url = this.occEndPoint.buildUrl(relativeUrl);

    const params = JSON.stringify(queryParams);
    const headers = new HttpHeaders({
      'Content-Type': this.jsonContentType,
    });
    const options = { headers };
    return this.http.patch(url, params, options).pipe(
      tap(),
      catchError((err) => {
        //Handle the error here
        return throwError(err); //Rethrow it back to component
      })
    );
  }

  // download file
  public downloadFile(relativeUrl: string, queryParams?): Observable<any> {
    const url = this.baseUrl;
    let params = new HttpParams();

    if (queryParams) {
      for (const param in queryParams) {
        if (param) {
          params = params.set(param, queryParams[param]);
        }
      }
    }
    const httpOptions: {
      headers: HttpHeaders;
      observe: 'response';
      params: HttpParams;
      reportProgress?: boolean;
      responseType: 'blob';
      withCredentials?: boolean;
    } = {
      headers: new HttpHeaders({
        'Content-Type': this.jsonContentType,
        Accept: 'text/csv',
        // 'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      }),
      observe: 'response',
      params,
      responseType: 'blob',
    };
    return this.http.get(url + relativeUrl, httpOptions).pipe(
      tap((res) => {
        return res;
      }),
      catchError(this.handleError('get', []))
    );
  }

  // To upload file
  public uploadFile(relativeUrl: string, file: File): Observable<any> {
    const url = this.baseUrl;
    const formData = new FormData();
    formData.append('data-file', file, file.name);
    const headers = new HttpHeaders({
      'Content-Type':
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      Accept: this.jsonContentType,
    });
    const options = { headers };
    return this.http
      .post(url + relativeUrl, formData, options)
      .pipe(tap(), catchError(this.handleError('post', [])));
  }

  public uploadCSVFile(relativeUrl: string, file: File): Observable<any> {
    const url = this.baseUrl;
    const formData = new FormData();
    formData.append('csvFile', file);
    return this.http
      .post(url + relativeUrl, formData, {observe: 'response'}).pipe(
        tap(),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  /* Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T): any {
    return (error: HttpErrorResponse): Observable<T> => {
      const errFromDb = error;
      errFromDb.error.errors[0].error_description = 'Something Went Wrong';
      return of(result as T);
    };
  }
}
