<section class="col-12 col-xs-12 col-sm-7 col-md-12 left-section import_section" *ngIf="cmsContentavailable">
  <div *ngIf="showSuccessMessage" class="global-alerts">
    <div class="alert alert-info alert-dismissable getAccAlert">
      <button (click)="closeInfoAlert()" class="close closeAccAlert" aria-hidden="true" data-dismiss="alert"
              type="button" data-di-id="di-id-4f6d7b93-70563360">×
      </button>
      <span class="alert-text">{{"importCart.successMessage" | cxTranslate}}</span>
    </div>
  </div>
  <div class="col-md-6 import_title">
    <h3 class="import_title"> {{ "importCart.title" | cxTranslate }} </h3>
  </div>
  <div class="account_overview_import" id="leftCont-rightNav">
    <div [innerHtml]="cmsContent | safeHtml"></div>
            <b *ngIf="numberOfColumnsError" class="import_error_1">{{'importCart.numberOfColumnsError'|cxTranslate}}</b>
            <b *ngIf="incorrectColumnsError" class="import_error_2">{{'importCart.incorrectColumnsError'|cxTranslate}}</b>
            <b *ngIf="noFileSelectedError" class="import_error_3">{{'importCart.noFileSelectedError'|cxTranslate}}</b>
            <b *ngIf="incorrectFileFormatError" class="import_error_4">{{'importCart.incorrectFileFormatError'|cxTranslate}}</b>
  </div>
</section>