import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { translationChunksConfig } from '@spartacus/assets';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { StatementsPipeModule } from '../shared/pipes/statementspipe.module';
import { OpenStatementsComponent } from './open-statements/open-statements.component';
import { FormatNegativeValuePipe } from './statement-display/format-negative-value.pipe';
import { StatementDisplayComponent } from './statement-display/statement-display.component';
@NgModule({
  declarations: [
    OpenStatementsComponent,
    StatementDisplayComponent,
    FormatNegativeValuePipe,
  ],
  imports: [
    CommonModule,
    RouterModule,
    I18nModule,
    NgbModule,
    BsDatepickerModule.forRoot(),
    StatementsPipeModule,
    FormsModule,
    ConfigModule.withConfig({
      cmsComponents: {
        StatementTopBodyComponent: {
          component: OpenStatementsComponent,
        },
      },
      i18n: {
        backend: { loadPath: 'assets/i18n-assets/{{lng}}/{{ns}}.json' },
      },
      chunks: translationChunksConfig,
    } as CmsConfig),
  ],
  providers: [NgbActiveModal],
  exports: [OpenStatementsComponent, StatementDisplayComponent],
})
export class StatementsModule {}
