<div class="col-xs-12 col-sm-12 col-md-12 Registration-split">
  <h3 class="pdp-title">
    {{ "userRegistration.accountInformation.header" | cxTranslate }}
  </h3>
  <div class="p0 col-xs-7 col-sm-7 col-md-7" id="accountTypeCheckBoxes">

      <form [formGroup]="accountInfoForm">
        <section class="custom-control custom-radio radio_grp1">
            <input type="radio" id="sanofiCustomer" [value]="false" style="position: fixed" class="custom-control-input" formControlName="wholesalerFlag">
            <label
              class="custom-control-label secloct"
              for="sanofiCustomer"
              style="font-size: 12px"
            >{{
              "userRegistration.accountInformation.radioButton" | cxTranslate
              }}
            </label>
        </section>
        <section class="custom-control custom-radio radio_grp1">
          <input type="radio" id="wholeSaler" [value]="true" style="position: fixed" class="custom-control-input" formControlName="wholesalerFlag">
          <label
            class="custom-control-label secloct"
            for="wholeSaler"
            style="font-size: 12px"
          >{{
            "userRegistration.accountInformation.wholesaler.radioButton" | cxTranslate
            }}
          </label>
        </section>
      </form>
  </div>
  <span class="title-sub-notify">{{
    "userRegistration.accountInformation.title" | cxTranslate
  }}</span>
  <span class="title-sub-heading">{{
    "userRegistration.accountInformation.formTitle" | cxTranslate
  }}</span>
  <div
    class="accountNotExist alert alert-danger alert-dismissable getAccAlert"
    [ngClass]="{ 'acc-invalid': billToShipError === true }"
    *ngIf="errorMsg"
  >
    <span class="dismissible-error-message" *ngIf="!billToShipError && !isEmailExists && !accountInfoForm.get('registeredAkamaiUser').value && !accountTypeError">
      {{
        "userRegistration.accountInformation.formValidationMsg" | cxTranslate
      }}</span
    >
    <span class="dismissible-error-message" *ngIf="!billToShipError && !isEmailExists && accountInfoForm.get('registeredAkamaiUser').value && !accountTypeError && !akamaiPasswordValidationError">
      {{
      "userRegistration.accountInformation.formValidationMsg" | cxTranslate
      }}</span
    >
    <span class="dismissible-error-message" *ngIf="billToShipError && !isEmailExists && !accountInfoForm.get('registeredAkamaiUser').value">
      {{
        "userRegistration.accountInformation.billToShipToValidationMsg"
          | cxTranslate
      }}</span
    >
    <span class="dismissible-error-message" *ngIf="isEmailExists">
      {{
        "userRegistration.accountInformation.emailExistMsg"
          | cxTranslate
      }}</span
    >
    <span class="dismissible-error-message" *ngIf="akamaiPasswordValidationError">
      {{
        "userRegistration.accountInformation.akamaiPasswordValidationMsg"
          | cxTranslate
      }}</span
    >
  </div>
  <div class="accountNotExist alert alert-danger alert-dismissable getAccAlert"
       [ngClass]="{ 'acc-invalid': billToShipError === true }"
       *ngIf="isPasswordEmptyInAkamai">
    <span class="dismissible-error-message">
      {{
      "userRegistration.accountInformation.passwordIsEmptyInAkamai" | cxTranslate
      }}
    </span>
  </div>
  <div class="accountExistOnAkamai alert alert-info acc-exist"
       *ngIf="infoMsg && !resetPasswordEmailSent">
    <span class="dismissible-info-message" *ngIf="infoMsg">
      {{
      "userRegistration.accountInformation.emailExistOnAkamaiMsg"
        | cxTranslate
      }}
      <a (click)="sendResetPasswordEmail()" style="color: #337ab7;">{{
      "userRegistration.accountInformation.emailExistOnAkamaiMsgLink"
        | cxTranslate
        }}</a></span
    >
  </div>
  <div class="accountExistOnAkamai alert alert-info acc-exist"
       *ngIf="infoMsg && resetPasswordEmailSent">
    <span class="dismissible-info-message">
      {{
      "userRegistration.accountInformation.resetPasswordEmailSentMsg"
        | cxTranslate
      }}
    </span
    >
  </div>
  <form
    [formGroup]="accountInfoForm"
    id="accountInfoForm"
    (ngSubmit)="validateAccountInfo()"
  >
    <ng-container *ngIf="!accountInfoForm.get('wholesalerFlag').value else wholeSaler">
    <div class="col-xs-12 col-sm-8 col-md-8 p0">
      <div id="registerBilling">
        <div
          class="
            col-xs-12 col-sm-12 col-md-12
            p0
            Fields-container
            doubleLineContainer
            zip-container
          "
        >
          <ng-template #accountNumberPopContent>{{
            "userRegistration.accountInformation.accountNumber.popoverMsg"
              | cxTranslate
          }}</ng-template>
          <label for="accountNumber" class="Fields-label icon-label"
            >{{
              "userRegistration.accountInformation.accountNumber.label"
                | cxTranslate
            }}
          </label>
          <a placement="top" [ngbPopover]="accountNumberPopContent"
            ><img
              src="../../assets/icons/faqs_reg.png"
              alt="faq_reg"
              class="faq"
          /></a>
          <input
            id="accountNumber"
            name="accountNumber"
            data-valid="valid_facility"
            aria-labelledby="accountNumber tooltipFacilityName"
            type="text"
            placeholder="XXXXXXXXX"
            class="Fields-input"
            autocomplete="off"
            formControlName="accountNumber"
            data-di-id="#accountNumber"
          />

          <span
            *ngIf="
              !accountInfoForm.get('accountNumber').errors?.required &&
              !accountInfoForm.get('accountNumber').valid &&
              accountInfoForm.get('accountNumber').touched
            "
            class="errorMessage"
            >{{
              "userRegistration.accountInformation.accountNumber.validationMessage.acceptsNumberValidation"
                | cxTranslate
            }}</span
          >
          <span
            *ngIf="
              accountInfoForm.get('accountNumber').errors?.required &&
              !accountInfoForm.get('accountNumber').valid &&
              accountInfoForm.get('accountNumber').touched
            "
            class="defaultMessage"
            role="alert"
            aria-live="assertive"
            >{{
              "userRegistration.accountInformation.requiredMsg" | cxTranslate
            }}
          </span>
        </div>
        <div
          class="
            col-xs-12 col-sm-12 col-md-12
            p0
            Fields-container
            zip-container
          "
        >
          <ng-template #postalCodePopContent>{{
            "userRegistration.accountInformation.postalCode.popoverMsg"
              | cxTranslate
            }}</ng-template>
          <label for="postalCode" class="Fields-label icon-label">{{
            "userRegistration.accountInformation.postalCode.label" | cxTranslate
          }}</label>
          <a placement="top" [ngbPopover]="postalCodePopContent"
          ><img
            src="../../assets/icons/faqs_reg.png"
            alt="faq_reg"
            class="faq"
          /></a>
          <input
            id="postalCode"
            name="postalCode"
            data-valid="valid_zip_canada"
            type="text"
            maxlength="5"
            class="Fields-input"
            autocomplete="off"
            formControlName="postalCode"
            data-di-id="#postalCode"
          />

          <span
            *ngIf="
              accountInfoForm.get('postalCode').errors?.pattern &&
              !accountInfoForm.get('postalCode').valid &&
              accountInfoForm.get('postalCode').touched
            "
            class="errorMessage"
            role="alert"
            aria-live="assertive"
            >{{
              "userRegistration.accountInformation.postalCode.invalidFormatMsg"
                | cxTranslate
            }}</span
          >
          <span
            *ngIf="
              accountInfoForm.get('postalCode').errors?.required &&
              !accountInfoForm.get('postalCode').valid &&
              accountInfoForm.get('postalCode').touched
            "
            class="defaultMessage"
            role="alert"
            aria-live="assertive"
            >{{
              "userRegistration.accountInformation.requiredMsg" | cxTranslate
            }}
          </span>
        </div>
      </div>
    </div>
    </ng-container>
    <ng-template #wholeSaler>
      <div class="col-xs-12 col-sm-8 col-md-8 p0">
        <div id="registerBilling">
          <div
            class="
            col-xs-12 col-sm-12 col-md-12
            p0
            Fields-container
            doubleLineContainer
            zip-container
          "
          >
            <ng-template #accountNumberPopContent>{{
              "userRegistration.accountInformation.accountNumber.popoverMsg"
                | cxTranslate
              }}</ng-template>
            <label for="accountNumber" class="Fields-label icon-label"
            >{{
              "userRegistration.accountInformation.wholesaler.accountNumber.label"
                | cxTranslate
              }}
            </label>
            <a placement="top" [ngbPopover]="accountNumberPopContent"
            ><img
              src="../../assets/icons/faqs_reg.png"
              alt="faq_reg"
              class="faq"
            /></a>
            <input
              id="accountNumber"
              name="accountNumber"
              data-valid="valid_facility"
              aria-labelledby="accountNumber tooltipFacilityName"
              type="text"
              placeholder="XXXXXXXXX"
              class="Fields-input"
              autocomplete="off"
              formControlName="accountNumber"
              data-di-id="#accountNumber"
              [attr.readonly]="isIndirectB2BUnitValidated ? '' : null"
            />

            <span
              *ngIf="
              !accountInfoForm.get('accountNumber').errors?.required &&
              !accountInfoForm.get('accountNumber').valid &&
              accountInfoForm.get('accountNumber').touched
            "
              class="errorMessage"
            >{{
              "userRegistration.accountInformation.accountNumber.validationMessage.acceptsNumberValidationWHS"
                | cxTranslate
              }}</span
            >
            <span
              *ngIf="
              accountInfoForm.get('accountNumber').errors?.required &&
              !accountInfoForm.get('accountNumber').valid &&
              accountInfoForm.get('accountNumber').touched
            "
              class="defaultMessage"
              role="alert"
              aria-live="assertive"
            >{{
              "userRegistration.accountInformation.requiredMsg" | cxTranslate
              }}
          </span>
          </div>
          <div
            class="
            col-xs-12 col-sm-12 col-md-12
            p0
            Fields-container
            zip-container
          "
          >
            <ng-template #postalCodePopContent>{{
              "userRegistration.accountInformation.postalCode.popoverMsg"
                | cxTranslate
              }}</ng-template>
            <label for="postalCode" class="Fields-label icon-label">{{
              "userRegistration.accountInformation.postalCode.label" | cxTranslate
              }}</label>
            <a placement="top" [ngbPopover]="postalCodePopContent"
            ><img
              src="../../assets/icons/faqs_reg.png"
              alt="faq_reg"
              class="faq"
            /></a>
            <input
              id="postalCode"
              name="postalCode"
              data-valid="valid_zip_canada"
              type="text"
              maxlength="5"
              class="Fields-input"
              autocomplete="off"
              formControlName="postalCode"
              data-di-id="#postalCode"
              [attr.readonly]="isIndirectB2BUnitValidated ? '' : null"
            />

            <span
              *ngIf="
              accountInfoForm.get('postalCode').errors?.pattern &&
              !accountInfoForm.get('postalCode').valid &&
              accountInfoForm.get('postalCode').touched
            "
              class="errorMessage"
              role="alert"
              aria-live="assertive"
            >{{
              "userRegistration.accountInformation.postalCode.invalidFormatMsg"
                | cxTranslate
              }}</span
            >
            <span
              *ngIf="
              accountInfoForm.get('postalCode').errors?.required &&
              !accountInfoForm.get('postalCode').valid &&
              accountInfoForm.get('postalCode').touched
            "
              class="defaultMessage"
              role="alert"
              aria-live="assertive"
            >{{
              "userRegistration.accountInformation.requiredMsg" | cxTranslate
              }}
          </span>
          </div>
        </div>
      </div>
      <div formGroupName="emailInfo">
        <div class="col-xs-6 col-sm-6 col-md-6 p0 Fields-container mt-2">
          <label for="userEmail" class="Fields-label"
          >{{
            "userRegistration.userInformation.email.label" | cxTranslate
            }}
          </label>
          <input
            id="userEmail"
            name="email"
            data-valid="valid_mail"
            type="text"
            class="Fields-input"
            data-view="newemail"
            formControlName="email"
            data-di-id="#userEmail"
            [attr.readonly]="isIndirectB2BUnitValidated || skipAkamaiValidation ? '' : null"
          />

          <span
            *ngIf="
                isEmailExists && !accountInfoForm.get('emailInfo.email').touched
              "
            class="errorMessage"
          >
              {{
            "userRegistration.userInformation.email.existingEmail"
              | cxTranslate
            }}</span
          >

          <span
            *ngIf="
                !!accountInfoForm.get('emailInfo.email').value &&
                accountInfoForm.get('emailInfo.email').invalid &&
                accountInfoForm.get('emailInfo.email').touched
              "
            class="defaultMessage"
            role="alert"
            aria-live="assertive"
          >{{
            "userRegistration.userInformation.email.invalidEmail"
              | cxTranslate
            }}</span
          >
          <span
            *ngIf="
                accountInfoForm.get('emailInfo.email').errors?.required &&
                !accountInfoForm.get('emailInfo.email').valid &&
                accountInfoForm.get('emailInfo.email').touched
              "
            class="defaultMessage"
            role="alert"
            aria-live="assertive"
          >{{
            "userRegistration.userInformation.requiredMsg" | cxTranslate
            }}
            </span>
        </div>
        <div *ngIf="isIndirectB2BUnitValidated && !accountInfoForm.get('registeredAkamaiUser').value" class="col-xs-6 col-sm-6 p0 Fields-container mt-2">
            <label for="userConfirmEmail" class="Fields-label"
            >{{
              "userRegistration.userInformation.confirmEmail.label"
                | cxTranslate
              }}
            </label>
            <input
              id="userConfirmEmail"
              name="confirmEmail"
              data-valid="valid_mail"
              type="text"
              class="Fields-input"
              data-view="confirmemail"
              formControlName="confirmEmail"
              data-di-id="#userConfirmEmail"
            />

            <span
              *ngIf="
                !!accountInfoForm.get('emailInfo.confirmEmail').value &&
                accountInfoForm.get('emailInfo.confirmEmail').invalid &&
                accountInfoForm.get('emailInfo.confirmEmail').touched
              "
              class="defaultMessage"
              role="alert"
              aria-live="assertive"
            >{{
              "userRegistration.userInformation.email.invalidEmail"
                | cxTranslate
              }}</span
            >

            <span
              *ngIf="
                !!accountInfoForm.get('emailInfo.confirmEmail').value &&
                !accountInfoForm.get('emailInfo.confirmEmail').invalid &&
                accountInfoForm.get('emailInfo').errors?.emailNotMatch
              "
              class="errorMessage"
            >{{
              "userRegistration.userInformation.confirmEmail.emailNotMatch"
                | cxTranslate
              }}</span
            >
            <span
              *ngIf="
                accountInfoForm.get('emailInfo.confirmEmail').errors?.required &&
                !accountInfoForm.get('emailInfo.confirmEmail').valid &&
                accountInfoForm.get('emailInfo.confirmEmail').touched
              "
              class="defaultMessage"
              role="alert"
              aria-live="assertive"
            >{{
              "userRegistration.userInformation.requiredMsg" | cxTranslate
              }}
            </span>
          </div>
      </div>


      <div *ngIf="isIndirectB2BUnitValidated || userInfoDetailsGroup.get('password').value"
        class="
              col-xs-12 col-sm-12 col-md-12
              p0
              Fields-container
              zip-container
            "
        formGroupName="userInfoDetails"
      >
        <label for="userPassword" class="Fields-label"
        >{{
          "userRegistration.userInformation.password.label" | cxTranslate
          }}
        </label>
        <input
          id="userPassword"
          name="password"
          data-valid="valid_facility"
          aria-labelledby="password tooltipFacilityName"
          type="password"
          class="Fields-input"
          autocomplete="off"
          formControlName="password"
          data-di-id="#userPassword"
          [attr.readonly]="this.userAuthDetails?.password ? '' : null"
        />


        <span
          *ngIf="
                !accountInfoForm.get('userInfoDetails.password').valid &&
                !accountInfoForm.get('userInfoDetails.password').errors
                  ?.required  &&
                accountInfoForm.get('userInfoDetails.password').touched
              "
          class="errorMessage"
        >{{
          "userRegistration.userInformation.password.inValidPassword"
            | cxTranslate
          }}</span
        >
        <span
          *ngIf="
                accountInfoForm.get('userInfoDetails.password').errors?.required &&
                !accountInfoForm.get('userInfoDetails.password').valid &&
                accountInfoForm.get('userInfoDetails.password').touched
              "
          class="defaultMessage"
          role="alert"
          aria-live="assertive"
        >{{
          "userRegistration.userInformation.requiredMsg" | cxTranslate
          }}
            </span>
      </div>

      <div *ngIf="isIndirectB2BUnitValidated && !accountInfoForm.get('registeredAkamaiUser').value"
        class="
              col-xs-12 col-sm-12 col-md-12
              p0
              Fields-container
              zip-container
            "
           formGroupName="userInfoDetails"
      >
        <label for="userConfirmPassword" class="Fields-label">{{
          "userRegistration.userInformation.confirmPassword.label"
            | cxTranslate
          }}</label>
        <input
          id="userConfirmPassword"
          name="confirmPassword"
          data-valid="valid_zip_canada"
          type="password"
          class="Fields-input"
          autocomplete="off"
          formControlName="confirmPassword"
          data-di-id="#userConfirmPassword"
        />

        <span
          *ngIf="
                !accountInfoForm.get('userInfoDetails.confirmPassword').valid &&
                !accountInfoForm.get('userInfoDetails.confirmPassword').errors
                  ?.required &&
                accountInfoForm.get('userInfoDetails.confirmPassword').errors
                  ?.isPasswordInvalid &&
                accountInfoForm.get('userInfoDetails.confirmPassword').touched
              "
          class="errorMessage"
        >{{
          "userRegistration.userInformation.password.inValidPassword"
            | cxTranslate
          }}</span
        >

        <span
          *ngIf="
                !!accountInfoForm.get('userInfoDetails.confirmPassword').value &&
                accountInfoForm.get('userInfoDetails.confirmPassword').touched &&
                !accountInfoForm.get('userInfoDetails.confirmPassword').errors
                  ?.isPasswordInvalid &&
                accountInfoForm.get('userInfoDetails').errors?.mismatch
              "
          class="errorMessage"
        >{{
          "userRegistration.userInformation.notMatchMsg" | cxTranslate
          }}</span
        >
        <span
          *ngIf="
                accountInfoForm.get('userInfoDetails.confirmPassword').errors
                  ?.required &&
                !accountInfoForm.get('userInfoDetails.confirmPassword').valid &&
                accountInfoForm.get('userInfoDetails.confirmPassword').touched
              "
          class="defaultMessage"
          role="alert"
          aria-live="assertive"
        >{{
          "userRegistration.userInformation.requiredMsg" | cxTranslate
          }}
            </span>
      </div>
    </ng-template>
    <hr class="Fields-container-line billingPage" />
    <div class="col-xs-12 col-sm-12 col-md-12 captcha-x">
      <re-captcha
        formControlName="recaptchaReactive"
        (resolved)="resolved($event)"
      ></re-captcha >
      <span
        *ngIf="
          accountInfoForm.get('recaptchaReactive').errors?.required &&
          !accountInfoForm.get('recaptchaReactive').valid &&
          accountInfoForm.get('recaptchaReactive').touched
        "
        class="defaultMessage"
        role="alert"
        aria-live="assertive"
        >{{ "userRegistration.userInformation.requiredMsg" | cxTranslate }}
      </span>
    </div>
    <div class="invoice-btngrps">
      <p style="font-size: 12px; color: red" *ngIf="accountTypeError && !accountInfoForm.get('wholesalerFlag').value">
        {{"userRegistration.accountInformation.accountTypeErrorMsg" | cxTranslate}}
      </p>
      <button
        type="submit"
        class="btn btn-default oi-blue-button reviewvalidbtn"
        data-di-id="di-id-7a6cb2cd-cb37948a"
      >
        {{ "userRegistration.accountInformation.buttonLabel" | cxTranslate }}
      </button>
    </div>
  </form>
</div>
