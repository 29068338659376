import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as config from '../shared/config/app.config';
import { BaseService } from '../shared/services/base.service';

@Injectable({
  providedIn: 'root'
})
export class ResourcesService {

  public catalogs = new BehaviorSubject<any>(null);
  catalogs$ = this.catalogs.asObservable();
  public catalogTypes = new BehaviorSubject<any>(null);
  catalogTypes$ = this.catalogTypes.asObservable();

  constructor(public baseService: BaseService) { }

  public getCatalogs() {
    const filter = {
      fields: 'DEFAULT',
    };
    const query = 'dzProductCatalog/Online/categories/m1';
    this.baseService
      .get(config.CATALOGS + query , filter)
      .subscribe((res) => {   
        this.catalogTypes.next(res);
      });
  }

  public getCatalogTypes(type?){
    const filter = {
      fields: 'DEFAULT',
    };
    this.baseService
    .get(`${config.CATALOGSTYPE}${type}`,filter)
    .subscribe((res) => {      
      this.catalogs.next(res);
    });
  }

  public getFAQ(){
    this.baseService.get(config.FAQ).subscribe((res) => {      
    });
  }
}
