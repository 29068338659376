import { Injectable } from '@angular/core';
import { RoutingService } from '@spartacus/core';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { BaseService } from '../shared/services/base.service';
import { SharedService } from '../shared/services/shared.service';
import { ExcelDownloadService } from '../shared/services/excel-download.service';
@Injectable({
  providedIn: 'root',
})
export class CustomInfluenzaService {
  activeSeaons: any;
  activetab: any;
  fluProducts: any;
  influenzaContact: any;
  addedProductsToCart: any;
  userIdOfLoggedInUser: any;
  activeCartId: any;
  fluProductsFromCart: any;
  cartDlete: any;
  itemDlete: any;
  contiueShopping = false;
  updateFluCart: any;
  ordersHistory: any;
  cartType: any;
  dataForExcel:any;
  updatedInfContact: any;
  detailedReservation: any;
  cartIdActiveOfUser:any;
  createCartOfUser:any;
  changedLocation:any;
  removedFluCartItems:any;
  public checkoutInfluenza = new BehaviorSubject<any>(false);
  checkoutInfluenza$ = this.checkoutInfluenza.asObservable();
  defaultFields = '?fields=DEFAULT';
  getVolumePrices: any;
 
  constructor(private readonly baseService: BaseService,
    private readonly router: RoutingService,
    private readonly sharedService: SharedService,
    public excelService: ExcelDownloadService) {
    this.activeSeaons = new Subject();
    this.activetab = new Subject();
    this.fluProducts = new Subject();
    this.influenzaContact = new Subject();
    this.addedProductsToCart = new Subject();
    this.userIdOfLoggedInUser = new Subject();
    this.activeCartId = new Subject();
    this.fluProductsFromCart = new Subject();
    this.cartDlete = new Subject();
    this.itemDlete = new Subject();
    this.updateFluCart = new Subject();
    this.ordersHistory = new Subject();
  
    this.updatedInfContact = new Subject();
    this.detailedReservation = new ReplaySubject();
    this.cartIdActiveOfUser=new Subject();
    this.createCartOfUser=new Subject();
    this.changedLocation=new Subject();
    this.removedFluCartItems=new Subject();
    this.getVolumePrices = new Subject();


  }

 
  public removedFluCartEntries(cartId,userId){
    this.baseService.get('users/'+userId+'/carts/'+cartId+
    '?cartPage=true&fields=DEFAULT&standardCart=false').subscribe((res) => {
      if (res) {
        this.removedFluCartItems.next(res);
      }
    });
  }
  public updateShipToId(userId,cartId,shipToUnitId,standardCart){
    this.baseService.post('/users/'+userId+'/carts/'+cartId+'/addShipToUnit/'+shipToUnitId+'/'+standardCart+this.defaultFields ,{}).subscribe((res) => {
      if (res) {
        this.changedLocation.next(true);
      }
    });
   
  }
  
  public getCartIdofUser(userId) {
    this.baseService.get('/users/'+userId+'/carts?currentPage=0&fields=DEFAULT&pageSize=20&savedCartsOnly=false').subscribe((res) => {
      if (res) {
        this.cartIdActiveOfUser.next(res);
      }
    });
  }
  public CreateCartIdofUser(userId) {
    this.baseService.post('users/'+userId+'/carts?fields=DEFAULT',{}).subscribe((res) => {
      if (res) {
        this.createCartOfUser.next(res);
      }
    });
  }
  public getVolumePrice( products){
    this.baseService.post('/products/getPrice?fields=DEFAULT',products).subscribe((res) => {
      if (res) {
        this.getVolumePrices.next(res);
      }
    });
  }


  public getActiveSeasonsFromDb(userId) {
    this.baseService.get('users/' + userId + '/influenza/get-active-seasons').subscribe((res) => {
      if (res) {
        this.activeSeaons.next(res);
      }
    });
  }
  public getUserOrderHistory(userId,seasonId) {
    this.baseService.get('users/' + userId + '/orders?currentPage=0&fields=DEFAULT&orderType=ZPRE&seasonId='+seasonId).subscribe((res) => {
      if (res) {
        this.ordersHistory.next(res);
      }
    });
  }
  public getFluProducts(userId, seasonId) {
    this.fluProducts.next(false);
    this.baseService.get('users/' + userId + '/influenza/get-fluproducts-by-seasonId/' + seasonId).subscribe((res) => {
      if (res) {
        this.fluProducts.next(res);
      }
    });
  }
  public getFluOrderContact(userId) {
    this.baseService.get('users/' + userId + '/b2bUnits').subscribe((res) => {
      if (res) {
        this.influenzaContact.next(res);
      }
    });
  }
  public getDetailedReservation(userId, orderId,boolean) {
    this.baseService.get('users/' + userId + '/orders/' + orderId + this.defaultFields).subscribe((res) => {
      if (res) {
        sessionStorage.removeItem('fluOverviewData');
        this.sharedService.setSessionStorage('fluOverviewData', res);

        this.detailedReservation.next(res);
        if(boolean){
          this.router.goByUrl('/influenza/reservation-overview');

        }

      }
    });
  }
  public getFluProductsFromCart(userId, cartId,page) {

    if(cartId) {
    this.baseService.get('users/' + userId + '/carts/' + 
    cartId +this.defaultFields).subscribe((res) => {
      if (res) {
        this.fluProductsFromCart.next(res);
      }
    });
  }
  }

  public addFluProductsToCart(userId, cartId, params) {
    this.baseService.post('users/' + userId + '/carts/' + cartId + '/bulk-add-to-cart', params)
    .subscribe((res) => {
      if (res) {
        this.addedProductsToCart.next(res);
      }
    },
    (err)=>{
      this.addedProductsToCart.next(err);
    }
    );
  }
  public updateFluProductsToCart(userId, parentCartId, childCartId, params) {
    this.baseService.patch('users/' + userId + '/carts/' + parentCartId + '/update/' + childCartId + this.defaultFields, params)
    .subscribe((res) => {
      if (res) {
        this.updateFluCart.next(res);
      }
    },
    (err)=>{
      this.updateFluCart.next(err);
    }
    );
  }
  public deleteEntireCart(userId, childCartId) {
    this.baseService.delete('users/' + userId + '/carts/' + childCartId).subscribe((res) => {
      this.cartDlete.next(true);
    });

  }
  public deleteCartItems(userId, parentCartId, entryNumber, childCartId) {
    this.baseService.delete('users/' + userId + '/carts/' +
      parentCartId + '/entries/' + entryNumber + '/' + childCartId).subscribe((res) => {
        
        this.itemDlete.next(true);
      });
     

  }
  public downloadXls(data, name) {
    this.excelService.exportAsExcelFile(data, name,true);
  }

  public checkoutItems(userId, cartId, params) {
    this.checkoutInfluenza.next(false);
    this.baseService.post('users/' + userId + '/carts/' + cartId + '/orderSimulate?fields=DEFAULT', params)
      .subscribe((res) => {
        if (res) {
          this.checkoutInfluenza.next(res);
        }
      },
      (err)=>{
        this.checkoutInfluenza.next(err);
      }
      );
  }
  updateContact(params, userId): void {
    this.baseService.put('users/' + userId + '/influenza/updateContactInfo', params).subscribe((res) => {
      if (res) {
        this.updatedInfContact.next(res);
      }
    });
  }
 


}
