<div *ngIf="showSuccessMessage" class="global-alerts">
  <div class="alert alert-info alert-dismissable getAccAlert">
    <button (click)="closeInfoAlert()" class="close closeAccAlert" aria-hidden="true" data-dismiss="alert"
            type="button" data-di-id="di-id-4f6d7b93-70563360">×
    </button>
    <span class="alert-text">{{"importCart.successMessage" | cxTranslate}}</span>
  </div>
</div><!--
<div class="account-section-content import-csv__content">
  <form:form modelAttribute="importCSVSavedCartForm" enctype="multipart/form-data" method="post" action="${importCSVSavedCartLink}" class="import-csv__form">-->
<!--  </form:form>-->
  <!--<div class="well well-quaternary well-md import-csv__well">
    <div class="row">
      <div class="col-xs-12 col-sm-6 import-csv__file-spec">
        <ul>
          <b>{{'importCart.instructionHead'|cxTranslate}}</b>
          <p>
            {{'importCart.steps'|cxTranslate}}<br/>
            {{'importCart.step1'|cxTranslate}}<br/>
            {{'importCart.step2'|cxTranslate}}<br/>
          </p>
          <button class="btn btn-primary btn-block template-button" (click)="exportXls()" id="exportButton">
            {{'importCart.downloadTemplate'|cxTranslate}}
          </button>
        </ul>
      </div>
      <div class="col-xs-12 col-sm-6 import-csv__file-upload">
        <b>{{'importCart.selectFile'|cxTranslate}}</b>
        <div class="form-group file-upload js-file-upload">
          <div class="file-upload__wrapper btn btn-default btn-small" id="chooseFileButton">
            <span>{{'importCart.chooseFile'|cxTranslate}}</span>
            <input type="file" id="csvFile" name="csvFile" class="file-upload__input js-file-upload__input"
                   accept="text/csv" (change)="onFileSelected($event)" #fileUpload/>
          </div>
          {{fileName}}
        </div>
      </div>
    </div>
  </div>

  <div class="import-csv__actions js-import-csv">
      <div class="col-xs-12 col-sm-6 col-md-3 pull-right">
        <div class="sumbit-button">
          <button class="btn btn-primary btn-block" (click)="sendFile()" id="importButton">
            {{'importCart.import'|cxTranslate}}
          </button>
          <b *ngIf="numberOfColumnsError">{{'importCart.numberOfColumnsError'|cxTranslate}}</b>
          <b *ngIf="incorrectColumnsError">{{'importCart.incorrectColumnsError'|cxTranslate}}</b>
          <b *ngIf="noFileSelectedError">{{'importCart.noFileSelectedError'|cxTranslate}}</b>
          <b *ngIf="incorrectFileFormatError">{{'importCart.incorrectFileFormatError'|cxTranslate}}</b>
        </div>
      </div>
  </div>
</div>-->

<div class="" id="leftCont-rightNav">
  <div [innerHtml]="cmsContent | safeHtml"></div>
          <b *ngIf="numberOfColumnsError" class="import_error_1">{{'importCart.numberOfColumnsError'|cxTranslate}}</b>
          <b *ngIf="incorrectColumnsError" class="import_error_2">{{'importCart.incorrectColumnsError'|cxTranslate}}</b>
          <b *ngIf="noFileSelectedError" class="import_error_3">{{'importCart.noFileSelectedError'|cxTranslate}}</b>
          <b *ngIf="incorrectFileFormatError" class="import_error_4">{{'importCart.incorrectFileFormatError'|cxTranslate}}</b>
</div>
