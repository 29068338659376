<div *ngIf="showDisclaimer" class="global-alerts">
  <div class="alert alert-info alert-dismissable getAccAlert">
    <button (click)="closeDisclaimerAlert()" class="close closeAccAlert" aria-hidden="true" data-dismiss="alert"
            type="button" data-di-id="di-id-4f6d7b93-70563360">×
    </button>
    {{"addToCartItems.priceAndExpirationDateDisclaimerMsg" | cxTranslate}}
  </div>
</div>

<section class="row col-lg-12 col-md-12 col-sm-12 col-xs-12 p0 details-header">
  <section class="col-lg-5 col-md-5 col-sm-0 col-xs-12 p0"></section>
  <section class="col-lg-3 col-md-3 col-sm-6 col-xs-12 middle-row p0">
    <img src="../../../assets/icons/arrow-left.svg" class="chevron-left" alt="left" />
    <a [routerLink]="['/my-account/orders']" href="javascript: void(0);">{{
      "orderDetail.backToOrder" | cxTranslate
      }}</a>
  </section>
  <section class="col-lg-4 col-md-4 col-sm-6 col-xs-12 p0">
    <section class="save-document mb1 mt0">
      <ng-template #popSaveTitle>{{
        "orderDetail.orderName" | cxTranslate
        }}</ng-template>
      <ng-template #popSaveContent>
        <input type="text" class="save-order" [(ngModel)]="saveCartName" />
        <span class="common-error required">{{ errorMsg }}</span>
        <button type="reset" class="popover-close btn white-button popover-cancel-button" (click)="toggle(p1)">
          {{ "orderDetail.cancel" | cxTranslate }}
        </button>
        <button class="btn blue-button popover-cancel-button" type="submit" data-di-id="di-id-3064f17d-e97cc628"
          (click)="saveOrder()">
          {{ "orderDetail.save" | cxTranslate }}
        </button>
      </ng-template>
      <a class="save-cart" [ngbPopover]="popSaveContent" [popoverTitle]="popSaveTitle" triggers="manual"
        #p1="ngbPopover" placement="top" (click)="toggle(p1)" container="body"
        popoverClass="popover-links order-detail-save">
        <img src="../../../assets/icons/saved-carts.svg" alt="Save order" /><span>{{ "orderDetail.saveOrder" |
          cxTranslate }}</span>
      </a>
      <a (click)="excelDownload()"><img src="../../../assets/icons/excel.png" alt="XLS" />
        <span>{{ "orderDetail.xls" | cxTranslate }}</span></a>
      <a (click)="print()"><img src="../../../assets/icons/print.svg" alt="Print" />
        <span>{{ "orderDetail.print" | cxTranslate }}</span></a>
    </section>
  </section>
</section>
<section class="row row-20 leftCont-rightNav display-block">
  <aside class="col-sm-4 col-md-4 right-navigation float-right">
    <section class="savings-total">
      <h3>
        {{ "orderDetail.orderTotal" | cxTranslate }}
        {{ orderDetailsData?.totalPriceWithTax?.value | localCurrency }}
      </h3>
      <button type="button" class="btn btn-default blue-button" *ngIf="orderDetailsData?.cartType !== 'INFLUENZA'"
        (click)="openModal(content)">
        {{ "orderDetail.reorderCheckout" | cxTranslate }}
      </button>
      <button type="submit" *ngIf="orderDetailsData?.cartType !== 'INFLUENZA'" class="btn btn-default white-button"
        (click)="getCartId('addCart')">
        {{ "orderDetail.addToCart" | cxTranslate }}
      </button>
    </section>
    <section class="savings-total row hidden-sm" *ngIf="orderDetailsData?.authorizeDocument">
      <div class="col-md-12 cols-xs-12 download-doc-copy">
        <p>{{ "orderDetail.downloadCopy" | cxTranslate }}</p>
      </div>
      <div class="col-md-6 col-xs-6 text-left file-name">
        <p>{{orderDetailsData?.authorizeDocument.name}}</p>
      </div>
      <div class="col-md-6 col-xs-6 text-right download-link">
        <a (click)="downloadFile(
          {
            code:orderDetailsData?.authorizeDocument.code,
            url:orderDetailsData?.authorizeDocument.url,
            name:orderDetailsData?.authorizeDocument.name},orderDetailsData?.authorizeDocument.name)">
          <img _ngcontent-serverapp-c423="" src="../../../assets/icons/download-icon.svg" alt="download">{{ "orderDetail.download" | cxTranslate }}
        </a>
      </div>
    </section>
    <!-- Modal Start -->
    <ng-template #content let-modal>
      <section class="modal-header">
        <h4 class="modal-title-top20">
          {{ "orderDetail.reorder" | cxTranslate }}
        </h4>
      </section>
      <section class="modal-body reorder">
        <section class="text-center">
          <p>{{ "orderDetail.clearCartDescription" | cxTranslate }}</p>
        </section>
      </section>

      <section class="modal-footer">
        <section class="miniCart-foot">
          <button type="submit" class="btn btn-default blue-button" (click)="clearChildCart()">
            {{ "orderDetail.clearReorder" | cxTranslate }}
          </button>
          <button type="button" class="btn btn-default white-button" (click)="existingCart()">
            {{ "orderDetail.existingCart" | cxTranslate }}
          </button>
        </section>
      </section>
    </ng-template>
    <!-- Modal End -->
  </aside>
  <section class="col-sm-8 col-md-8 left-section float-left">
    <section class="print-preview">
      <section class="firstLevel-acc od-line-align">
        <section class="od-lable-align">
          <label class="fs-14 light-color">
            <img src="../../../assets/icons/location.svg" alt="loc" />
            {{ selectedData?.companyName }} ({{ selectedData?.unitID }})
          </label>
        </section>
        <section class="order-preview">
          <p *ngIf="!orderContainsWholesalerProducts">
            {{ "orderDetail.order" | cxTranslate
            }}<span>{{ orderDetailsData?.guid }}</span>
          </p>
          <p>
            {{ "orderDetail.orderDate" | cxTranslate
            }}<span>{{ orderDetailsData?.created | date: "dd/MM/yyyy" }}</span>
          </p>
        </section>
      </section>
      <section class="row ship-orderdata">
        <section class="col-sm-12 col-md-12 col-lg-12 p0 ship-status">
          <ul>
            <li [ngStyle]="{'margin-bottom': orderContainsWholesalerProducts ? '5%' : ''}">
              <h5 class="fs-12 dark-color ff-bold mt0">
                {{ "orderDetail.orderStatus" | cxTranslate }}
              </h5>
              <p *ngIf="
                  orderDetailsData?.statusDisplay !== undefined &&
                  orderDetailsData?.statusDisplay !== ''
                ">
                {{ orderDetailsData?.statusDisplay }}
              </p>
              <p *ngIf="
                  orderDetailsData?.statusDisplay === undefined ||
                  orderDetailsData?.statusDisplay === ''
                ">
                -
              </p>
            </li>
            <li>
              <h5 class="fs-12 dark-color ff-bold mt0">
                {{ "orderDetail.placedBy" | cxTranslate }}
              </h5>
              <p *ngIf="
                  orderDetailsData?.user?.name !== undefined &&
                  orderDetailsData?.user?.name !== ''
                ">
                {{ orderDetailsData?.user?.name }}
              </p>
              <p *ngIf="
                  orderDetailsData?.user?.name === undefined ||
                  orderDetailsData?.user?.name === ''
                ">
                -
              </p>
            </li>
            <li style="margin-right: 5%;">
              <h5 class="fs-12 dark-color ff-bold mt0">
                {{ "orderDetail.po" | cxTranslate }}
              </h5>
              <p *ngIf="
                  orderDetailsData?.purchaseOrderNumber !== undefined &&
                  orderDetailsData?.purchaseOrderNumber !== ''
                ">
                {{ orderDetailsData?.purchaseOrderNumber }}
              </p>
              <p *ngIf="
                  orderDetailsData?.purchaseOrderNumber === undefined ||
                  orderDetailsData?.purchaseOrderNumber === ''
                ">
                -
              </p>
            </li>
            <li *ngIf="orderContainsWholesalerProducts">
              <h5 class="fs-12 dark-color ff-bold mt0">
                {{ "orderDetail.sapCcOrderNumber" | cxTranslate }}
              </h5>
              <p *ngIf="
                  orderDetailsData?.code !== undefined &&
                  orderDetailsData?.code !== ''
                ">
                {{ orderDetailsData?.code }}
              </p>
              <p *ngIf="
                  orderDetailsData?.code === undefined ||
                  orderDetailsData?.code === ''
                ">
                -
              </p>
            </li>
            <li *ngIf="orderContainsWholesalerProducts">
              <h5 class="fs-12 dark-color ff-bold mt0">
                {{ "orderDetail.whsOrderNumber" | cxTranslate }}
              </h5>
              <p *ngIf="
                  orderDetailsData?.wholesalerOrderReference !== undefined &&
                  orderDetailsData?.wholesalerOrderReference !== ''
                ">
                {{ orderDetailsData?.wholesalerOrderReference }}
              </p>
              <p *ngIf="
                  orderDetailsData?.wholesalerOrderReference === undefined ||
                  orderDetailsData?.wholesalerOrderReference === ''
                ">
                -
              </p>
            </li>
          </ul>
        </section>
      </section>
      <section class="light-grey-line"></section>
      <h4 class="headerTextDetailsPage">
        {{ "orderDetail.billing" | cxTranslate }}
      </h4>
      <section class="row row-20 ship-data">
        <section class="col-sm-12 col-md-5 col-lg-4">
          <ul>
            <li class="billingTo">
              <h5 class="fs-12 dark-color ff-bold mt0">
                {{ "orderDetail.billTo" | cxTranslate }}
              </h5>
              <p>
                {{ "orderDetail.accountNumber" | cxTranslate
                }}{{ orderDetailsData?.billingAddress?.unitID }}
              </p>
              <p>{{ orderDetailsData?.billingAddress?.companyName }}</p>
              <p>
                {{ orderDetailsData?.billingAddress?.line1 }},
                {{ orderDetailsData?.billingAddress?.line2 }}
              </p>
              <p>
                {{ orderDetailsData?.billingAddress?.town }},
                {{ orderDetailsData?.billingAddress?.region?.isocodeShort }},
                {{ orderDetailsData?.billingAddress?.postalCode }}
              </p>
            </li>
            <!-- <li>
              <h5 class="fs-12 dark-color ff-bold mt0">
                {{ "orderDetail.paymentType" | cxTranslate }}
              </h5>
              <p>{{ orderDetailsData?.paymentType?.displayName }}</p>
            </li> -->
          </ul>
        </section>
        <section class="col-sm-12 col-md-5 col-lg-5 order-Subtotal">
          <section class="table-responsive">
            <table class="table" aria-describedby="orderDataTable">
              <thead>
                <tr>
                  <th scope="col" class="ff-bold">
                    {{ "orderDetail.orderSubtotal" | cxTranslate }}
                  </th>
                  <th scope="col" class="fw-normal order-price">
                    {{ orderDetailsData?.subTotal?.value | localCurrency }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngIf="orderDetailsData?.totalTax?.value !== 0 && orderDetailsData?.totalTax?.value !== undefined">
                  <td class="ff-bold pt1">
                    {{ "orderDetail.salesTax" | cxTranslate }}
                  </td>
                  <td class="ff-bold pt1 order-price">
                    {{ orderDetailsData?.totalTax?.value | localCurrency }}
                  </td>
                </tr>
                <tr>
                  <td class="ff-bold pt1">
                    {{ "orderDetail.orderTotals" | cxTranslate }}
                  </td>
                  <td class="ff-bold pt1 order-price">
                    {{ orderDetailsData?.totalPrice?.value | localCurrency }}
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
        </section>
      </section>
      <section class="light-grey-line"></section>
      <h4 class="headerTextDetailsPage">
        {{ "orderDetail.shipping" | cxTranslate }}
      </h4>

      <section class="row row-20 ship-data">
        <section class="col-sm-12 col-md-5 col-lg-4">
          <ul>
            <li>
              <h5 class="fs-12 dark-color ff-bold mt0">
                {{ "orderDetail.shipTo" | cxTranslate }}
              </h5>
              <p>
                {{ "orderDetail.accountNumber" | cxTranslate
                }}{{ orderDetailsData?.deliveryAddress?.unitID }}
              </p>
              <p>{{ orderDetailsData?.deliveryAddress?.companyName }}</p>
              <p>
                {{ orderDetailsData?.deliveryAddress?.line1 }},
                {{ orderDetailsData?.deliveryAddress?.line2 }}
              </p>
              <p>
                {{ orderDetailsData?.deliveryAddress?.town }},
                {{ orderDetailsData?.deliveryAddress?.region?.isocodeShort }},
                {{ orderDetailsData?.deliveryAddress?.postalCode }}
              </p>
            </li>
          </ul>
        </section>
        <section class="col-sm-12 col-md-7 col-lg-7" *ngIf="!showDisclaimer">
          <ul>
            <li>
              <h5 class="fs-12 dark-color ff-bold mt0">
                {{ "orderDetail.shippingMethod" | cxTranslate }}
              </h5>
              <p *ngIf="orderDetailsData?.deliveryMode">{{ orderDetailsData?.deliveryMode.name }}</p>
            </li>
          </ul>
        </section>
        <section class="col-sm-12">
          <ul>
            <li>
              <h5 class="fs-12 dark-color ff-bold mt0">
                {{ "orderDetail.inco" | cxTranslate }}
              </h5>
              <p>{{ orderDetailsData?.incoterm }}</p>
            </li>
          </ul>
        </section>
      </section>

      <section class="light-grey-line hidden-md" *ngIf="orderDetailsData?.authorizeDocument"></section>
      <section class="row hidden-md" *ngIf="orderDetailsData?.authorizeDocument">
        <div class="col-md-12 download-doc-copy">
          <p>{{ "orderDetail.downloadCopy" | cxTranslate }}</p>
        </div>
        <div class="col-xs-6 col-md-6 text-left file-name">
          <p>{{orderDetailsData?.authorizeDocument.name}}</p>
        </div>
        <div class="col-xs-6 col-md-6 text-right download-link">
          <a (click)="downloadFile(
            {
              code:orderDetailsData?.authorizeDocument.code,
              url:orderDetailsData?.authorizeDocument.url,
              name:orderDetailsData?.authorizeDocument.name},orderDetailsData?.authorizeDocument.name)">
            <img _ngcontent-serverapp-c423="" src="../../../assets/icons/download-icon.svg" alt="download">{{ "orderDetail.download" | cxTranslate }}
          </a>
        </div>
      </section>

      <ul class="checkout-details checkout-details-view" *ngFor="let item of orderDetailsData?.entries; let i = index">
        <li>
          <section class="row row-20">
            <section class="col-sm-12 col-md-12 col-lg-12 display-table pd_img pr15">
              <a class="in-active" title="{{ item?.product?.name }}" (click)="openProductDetails(item)">
                <img class="flu-poster" *ngIf="item?.product.images !== undefined && item?.PRIMARY !== undefined" src="{{item?.PRIMARY.url }}" alt="{{ item?.product?.name }}" />
                <img class="flu-poster" *ngIf="item?.product.images === undefined && item?.PRIMARY === undefined" [src]="fallbackImage" alt="{{ item?.product?.name }}" />
                <h2 [innerHTML]="getProductName(item?.product?.name)"></h2>
              </a>
            </section>
          </section>
          <section class="row row-20 pr15">
            <section class="col-sm-12 col-md-1 col-lg-1 device-hide"></section>
            <section class="col-sm-12 col-md-11 col-lg-8 prod-number">
              <section class="checkout-data" [innerHtml]="getDescription(item?.product?.description)"></section>
              <p class="pd_num">
                {{ "orderDetail.productNumber" | cxTranslate }}
                <span class="num-bold productCode">{{
                  item?.product?.code
                  }}</span>
              </p>
            </section>
          </section>
          <!-- Desktop Table -->
          <section class="row row-20 details-Table">
            <section class="col-md-12 orderDetailTable" *ngIf="accountType !== 'INDIRECT'">
              <section class="dataTables-wrapper" *ngIf="item?.prodConsignments?.length > 0">
                <table class="mt-20" aria-describedby="orderTable" *ngFor="let data of item?.prodConsignments;">
                  <thead>
                    <tr class="heading">
                      <th scope="col">
                        {{ "orderDetail.status" | cxTranslate }}
                      </th>
                      <th scope="col">
                        {{ "orderDetail.totalUnits" | cxTranslate }}
                      </th>
                      <ng-container *ngIf="data?.statusCode.toLowerCase() !== 'delivery_completed'; else deliveredQuantity">
                        <th scope="col">
                          {{ "orderDetail.shippedUnits" | cxTranslate }}
                        </th>
                        <th scope="col">
                          {{ "orderDetail.shipDate" | cxTranslate }}
                        </th>
                      </ng-container>
                      <ng-template #deliveredQuantity>
                        <th scope="col">
                          {{ "orderDetail.deliveredUnits" | cxTranslate }}
                        </th>
                        <th scope="col">
                          {{ "orderDetail.deliveryDate" | cxTranslate }}
                        </th>
                      </ng-template>
                      <!-- <th scope="col">
                        {{ "orderDetail.tracking" | cxTranslate }}
                      </th> -->
                      <th scope="col" *ngIf="accountType !== 'INDIRECT'">{{ "orderDetail.lot" | cxTranslate }}</th>
                      <th scope="col">{{ "orderDetail.expireDate" | cxTranslate }}</th>
                      <th scope="col" class="text-center">
                        {{ "orderDetail.yourPrice" | cxTranslate }}
                      </th>
                      <th scope="col" class="text-center">
                        {{ "orderDetail.subtotal" | cxTranslate }}
                      </th>
                    </tr>
                  </thead>
                  <tbody class="datatable-row desktop-table-consignments">
                        <tr class="details-col" *ngIf="data.quantityDeclined == null || data.quantityDeclined == 0">
                          <td class="text-center">{{ data?.status }}</td>
                          <td class="text-center"><span class="td-hide">{{ item?.quantity }}</span></td>
                          <td class="text-center">
                            {{ data?.shippedQuantity }}
                          </td>
                          <td class="text-center">{{ data?.statusDate | slice:0:10 | date:'dd/MM/yyyy' }} <span *ngIf="prodEntries?.statusDate !== ''"></span></td>
                          <!-- <td class="text-center">{{ data?.entries[0]?.orderEntry?.trackingId }}</td> -->
                          <td class="text-center" *ngIf="accountType !== 'INDIRECT'">{{ data?.batchInfo?.batchNumber }}</td>
                          <td class="text-center">
                            <span *ngIf="accountType !== 'INDIRECT'">{{ data?.batchInfo?.expiryDate  }}</span>
                            <span *ngIf="accountType === 'INDIRECT'">{{ item?.productExpirationDate  | slice:0:10 | date:'dd/MM/yyyy'}}</span>
                          </td>
                          <td class="text-right extra-width">
                            <span class="td-hide">{{ item?.basePrice?.value | localCurrency }}</span>
                          </td>
                          <td class="text-right extra-width">
                            <span class="td-hide">{{ item?.totalPrice?.value | localCurrency }}</span>
                          </td>
                        </tr>
                  </tbody>
                </table>
              </section>
              <section class="dataTables-wrapper" *ngIf="
                      item?.prodConsignments === undefined ||
                      item?.prodConsignments?.length === 0
                    ">
                <table class="mt-20" aria-describedby="orderTable">
                  <thead>
                    <tr class="heading">
                      <th scope="col">
                        {{ "orderDetail.status" | cxTranslate }}
                      </th>
                      <th scope="col">
                        {{ "orderDetail.totalUnits" | cxTranslate }}
                      </th>
                      <th scope="col">
                       {{ "orderDetail.shippedUnits" | cxTranslate }}
                      </th>
                      <th scope="col">
                        {{ "orderDetail.shipDate" | cxTranslate }}
                      </th>
                      <!-- <th scope="col">
                        {{ "orderDetail.tracking" | cxTranslate }}
                      </th> -->
                      <th scope="col" *ngIf="accountType !== 'INDIRECT'">{{ "orderDetail.lot" | cxTranslate }}</th>
                      <th scope="col">{{ "orderDetail.expireDate" | cxTranslate }}</th>
                      <th scope="col" class="text-center">
                        {{ "orderDetail.yourPrice" | cxTranslate }}
                      </th>
                      <th scope="col" class="text-center">
                        {{ "orderDetail.subtotal" | cxTranslate }}
                      </th>
                    </tr>
                  </thead>
                  <tbody class="datatable-row">
                    <tr class="details-col">
                      <td class="text-center">{{ item?.quantityStatus }}</td>
                      <td class="text-center">{{ item?.quantity }}</td>
                      <td class="text-center"></td>
                      <td class="text-center">{{ item?.confirmedDate }} <span *ngIf="item?.confirmedDate !== ''">({{
                          "orderDetail.listConfirmed" | cxTranslate}})</span></td>
                      <!-- <td class="text-center">-</td> -->
                      <td *ngIf="accountType !== 'INDIRECT'">{{ item?.lotNumber }}</td>
                      <td>
                        <span *ngIf="accountType === 'INDIRECT'">{{ item?.productExpirationDate  | slice:0:10 | date:'dd/MM/yyyy'}}</span>
                      </td>
                      <td class="text-right extra-width">
                        {{ item?.basePrice?.value | localCurrency }}
                      </td>
                      <td class="text-right extra-width">
                        {{ item?.totalPrice?.value | localCurrency }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </section>
            </section>
            <section class="col-md-12 orderDetailTable" *ngIf="accountType === 'INDIRECT'">
              <section class="dataTables-wrapper">
                <table class="mt-20" aria-describedby="orderTable">
                  <thead>
                    <tr class="heading">
                      <th scope="col">
                        {{ "orderDetail.totalUnits" | cxTranslate }}
                      </th>
                      <th scope="col">
                        {{ "orderDetail.shippedUnits" | cxTranslate }}
                      </th>
                      <th scope="col">{{ "orderDetail.expireDate" | cxTranslate }}</th>
                      <th scope="col" class="text-center">
                        {{ "orderDetail.yourPrice" | cxTranslate }}
                      </th>
                      <th scope="col" class="text-center">
                        {{ "orderDetail.subtotal" | cxTranslate }}
                      </th>
                    </tr>
                  </thead>
                  <tbody class="datatable-row">
                    <tr class="details-col">
                      <td class="text-center">{{ item?.quantity }}</td>
                      <td class="text-center">{{ item?.dzConfirmedQuantity }}</td>
                      <ng-container *ngIf="orderDetailsData?.status !== 'CANCELLED' && orderDetailsData?.status !== 'REJECTED'; else orderCancelledTemplate">
                        <td>
                          <span *ngIf="item?.dzConfirmedQuantity > 0">{{ item?.productExpirationDate  | slice:0:10 | date:'dd/MM/yyyy'}}</span>
                        </td>
                        <td class="text-right extra-width">
                          {{ item?.basePrice?.value | localCurrency }}
                        </td>
                        <td class="text-right extra-width">
                          {{ item?.totalPrice?.value | localCurrency }}
                        </td>
                      </ng-container>
                      <ng-template #orderCancelledTemplate>
                        <td>
                          <span></span>
                        </td>
                        <td class="text-right extra-width">
                          {{ 0 | localCurrency }}
                        </td>
                        <td class="text-right extra-width">
                          {{ 0 | localCurrency }}
                        </td>
                      </ng-template>
                    </tr>
                  </tbody>
                </table>
                <table class="mt-20" aria-describedby="orderTable" *ngFor="let data of item?.prodConsignments;">
                  <thead>
                    <tr class="heading">
                      <th scope="col">
                        {{ "orderDetail.status" | cxTranslate }}
                      </th>
                      <th scope="col">
                        {{ "orderDetail.shippedQuantity" | cxTranslate }}
                      </th>
                      <th scope="col">
                        {{ "orderDetail.shipDate" | cxTranslate }}
                      </th>
                    </tr>
                  </thead>
                  <tbody class="datatable-row desktop-table-consignments">
                    <tr class="details-col">
                      <td class="text-center">{{ data?.status }}</td>
                      <td class="text-center">
                        {{ data?.shippedQuantity }}
                      </td>
                      <td class="text-center" *ngIf="data?.statusCode !== 'CANCELLED'">{{ data?.statusDate | slice:0:10 | date:'dd/MM/yyyy' }} <span *ngIf="prodEntries?.statusDate !== ''"></span></td>
                    </tr>
                  </tbody>
                </table>
              </section>
            </section>
          </section>
          <!-- Mobile table -->
          <ng-container *ngIf="accountType !== 'INDIRECT'">
          <section class="mobile-table hidden-md-block" *ngIf="item?.prodConsignments?.length > 0">
            <ng-container *ngFor="let data of item?.prodConsignments;">
                <section class="mobile-table-body" *ngIf="item?.product?.code == data.orderEntry.product.code && (data.quantityDeclined == null || data.quantityDeclined == 0)">
                    <p>
                      <label>{{ "orderDetail.status" | cxTranslate }}</label>
                      <span>{{ data?.status }}</span>
                    </p>
                    <p class="sec-hide">
                      <label>{{ "orderDetail.totalUnits" | cxTranslate }}</label>
                      <span>{{ item?.quantity }}</span>
                    </p>
                    <p>
                      <label> {{ "orderDetail.shippedUnits" | cxTranslate }}</label>
                      <span>{{ data?.shippedQuantity }}</span>
                    </p>
                    <p>
                      <label>{{ "orderDetail.shipDate" | cxTranslate }}</label>
                      <span>{{ data?.statusDate | slice:0:10 | date:'dd/MM/yyyy'}} <span *ngIf="data?.statusDate !== ''"></span></span>
                    </p>
                    <!-- <p>
                      <label>{{ "orderDetail.tracking" | cxTranslate }}</label>
                      <span>{{ data?.entries[0]?.orderEntry?.trackingId }}</span>
                    </p> -->
                    <p>
                      <label>{{ "orderDetail.lot" | cxTranslate }}</label>
                      <span>{{ data?.batchInfo?.batchNumber }}</span>
                    </p>
                  <p>
                    <label>{{ "orderDetail.expireDate" | cxTranslate }}</label>
                    <span *ngIf="accountType !== 'INDIRECT'">{{ data?.batchInfo?.expiryDate }}</span>
                    <span *ngIf="accountType === 'INDIRECT'">{{ item?.productExpirationDate  | slice:0:10 | date:'dd/MM/yyyy'}}</span>
                  </p>
                    <p class="sec-hide">
                      <label>{{ "orderDetail.yourPrice" | cxTranslate }}</label>
                      <span>{{ item?.basePrice?.value | localCurrency }}</span>
                    </p>
                    <p class="sec-hide">
                      <label>{{ "orderDetail.subtotal" | cxTranslate }}</label>
                      <span>{{ item?.totalPrice?.value | localCurrency }}</span>
                    </p>
                </section>
            </ng-container>
          </section>
          <section *ngIf="
              item?.prodConsignments === undefined ||
              item?.prodConsignments?.length === 0
            ">
            <section class="commonViewTable">
              <section class="childViewTable">
                <p>
                  <label>{{ "orderDetail.status" | cxTranslate }}</label>
                  <span class="text-center">{{ item?.quantityStatus }}</span>
                </p>
                <p>
                  <label>{{ "orderDetail.totalUnits" | cxTranslate }}</label>
                  <span class="text-center">{{ item?.quantity }}</span>
                </p>
                <p>
                  <label>{{ "orderDetail.shippedUnits" | cxTranslate }}</label>
                  <span class="text-center"></span>
                </p>
                <p>
                  <label>{{ "orderDetail.shipDate" | cxTranslate }}</label>
                  <span class="text-center">{{ item?.confirmedDate }} <span *ngIf="item?.confirmedDate !== ''">({{
                      "orderDetail.listConfirmed" | cxTranslate}})</span></span>
                </p>
                <!-- <p>
                  <label>{{ "orderDetail.tracking" | cxTranslate }}</label>
                  <span class="text-center">-</span>
                </p> -->
                <p *ngIf="accountType !== 'INDIRECT'">
                  <label>{{ "orderDetail.lot" | cxTranslate }}</label>
                  <span class="text-center">{{ item?.lotNumber }}</span>
                </p>
                <p>
                  <label>{{ "orderDetail.expireDate" | cxTranslate }}</label>
                  <span *ngIf="accountType === 'INDIRECT'">{{ item?.productExpirationDate  | slice:0:10 | date:'dd/MM/yyyy'}}</span>
                </p>
                <p>
                  <label>{{ "orderDetail.yourPrice" | cxTranslate }}</label>
                  <span>{{ item?.basePrice?.value | localCurrency }}</span>
                </p>
                <p>
                  <label>{{ "orderDetail.subtotal" | cxTranslate }}</label>
                  <span>{{ item?.totalPrice?.value | localCurrency }}</span>
                </p>
              </section>
            </section>
          </section>
          </ng-container>
          <ng-container *ngIf="accountType === 'INDIRECT'">
            <section class="commonViewTable">
              <section class="childViewTable">
                  <p>
                    <label>{{ "orderDetail.totalUnits" | cxTranslate }}</label>
                    <span class="text-center">{{ item?.quantity }}</span>
                  </p>
                  <p>
                    <label>{{ "orderDetail.shippedUnits" | cxTranslate }}</label>
                    <span class="text-center">{{ item?.dzConfirmedQuantity }}</span>
                  </p>
                  <ng-container *ngIf="orderDetailsData?.status !== 'CANCELLED' && orderDetailsData?.status !== 'REJECTED'; else orderCancelledTemplate">
                    <p>
                      <label>{{ "orderDetail.expireDate" | cxTranslate }}</label>
                      <span *ngIf="item?.dzConfirmedQuantity > 0">{{ item?.productExpirationDate  | slice:0:10 | date:'dd/MM/yyyy'}}</span>
                    </p>
                    <p>
                      <label>{{ "orderDetail.yourPrice" | cxTranslate }}</label>
                      <span>{{ item?.basePrice?.value | localCurrency }}</span>
                    </p>
                    <p>
                      <label>{{ "orderDetail.subtotal" | cxTranslate }}</label>
                      <span>{{ item?.totalPrice?.value | localCurrency }}</span>
                    </p>
                  </ng-container>
                  <ng-template #orderCancelledTemplate>
                    <p>
                      <label>{{ "orderDetail.expireDate" | cxTranslate }}</label>
                      <span></span>
                    </p>
                    <p>
                      <label>{{ "orderDetail.yourPrice" | cxTranslate }}</label>
                      <span>{{ 0 | localCurrency }}</span>
                    </p>
                    <p>
                      <label>{{ "orderDetail.subtotal" | cxTranslate }}</label>
                      <span>{{ 0 | localCurrency }}</span>
                    </p>
                  </ng-template>
                </section>
              </section>
              <section class="mobile-table hidden-md-block" *ngIf="item?.prodConsignments?.length > 0">
                <ng-container *ngFor="let data of item?.prodConsignments;">
                  <section class="mobile-table-body" *ngIf="item?.product?.code == data.orderEntry.product.code && (data.quantityDeclined == null || data.quantityDeclined == 0)">
                    <p>
                      <label>{{ "orderDetail.status" | cxTranslate }}</label>
                      <span>{{ data?.status }}</span>
                    </p>
                    <p>
                      <label> {{ "orderDetail.shippedQuantity" | cxTranslate }}</label>
                      <span>{{ data?.shippedQuantity }}</span>
                    </p>
                    <p>
                      <label>{{ "orderDetail.shipDate" | cxTranslate }}</label>
                      <span *ngIf="data?.statusCode !== 'CANCELLED'">{{ data?.statusDate | slice:0:10 | date:'dd/MM/yyyy'}} <span *ngIf="data?.statusDate !== ''"></span></span>
                    </p>
                  </section>
                </ng-container>
              </section>
            </ng-container>
        </li>
      </ul>

      <!-- Cancelled Order Product Desktop-->
      <ul class="checkout-details checkout-details-view" *ngIf="hasOrderCancelled">
        <li>
          <section class="row row-20 details-Table">
            <section class="col-md-12 orderDetailTable">
              <section class="dataTables-wrapper">
                <h4 class="headerTextDetailsPage">{{"orderDetail.cancelledProducts" | cxTranslate}}</h4>
                <table>
                  <thead>
                    <tr class="heading">
                      <th>{{ "orderDetail.status" | cxTranslate }}</th>
                      <th>{{ "orderDetail.productName" | cxTranslate }}</th>
                      <th>{{ "orderDetail.productNumber" | cxTranslate }}</th>
                      <th>{{ "orderDetail.unitCancelled" | cxTranslate }}</th>
                      <th>{{ "orderDetail.reason" | cxTranslate }}</th>
                    </tr>
                  </thead>
                <ng-container *ngFor="let item of orderDetailsData?.entries; let i = index">
                    <ng-container *ngIf="item?.prodConsignments?.length > 0 && hasOrderCancelled">
                    <ng-container *ngFor="let data of item?.prodConsignments;">
                      <tbody class="datatable-row desktop-table-consignments" >
                        <tr class="details-col" *ngIf="(data?.quantityDeclined != null && data?.quantityDeclined > 0)">
                          <td class="text-center">{{ data?.status }}</td>
                          <td class="extra-width-20">{{ data?.orderEntry?.product?.name }}</td>
                          <td class="text-center">{{ data?.orderEntry?.product?.code }}</td>
                          <td class="text-center">{{data?.quantityDeclined}}</td>
                          <td>{{data.rejectionReason}}</td>
                        </tr>
                      </tbody>
                    </ng-container>
                    </ng-container>
                  </ng-container>
                </table>
              </section>
            </section>
          </section>
        </li>
      </ul>
      <!-- Cancelled Order Product Mobile-->
      <ul class="hidden-md-block" *ngIf="hasOrderCancelled">
        <li>
          <section class="mobile-table">
            <h4 class="headerTextDetailsPage">{{"orderDetail.cancelledProducts" | cxTranslate}}</h4>
            <ng-container *ngFor="let item of orderDetailsData?.entries; let i = index">
                <ng-container *ngIf="item?.prodConsignments?.length > 0 && hasOrderCancelled">
                <ng-container *ngFor="let data of item?.prodConsignments;">
                  <section class="mobile-table-body" *ngIf="item?.product?.code == data?.orderEntry?.product?.code && (data?.quantityDeclined != null && data?.quantityDeclined > 0)">
                      <p><label>{{ "orderDetail.status" | cxTranslate }}</label><span>{{ data?.status }}</span></p>
                      <p><label>{{ "orderDetail.productName" | cxTranslate }}</label><span>{{ data?.orderEntry?.product?.name }}</span></p>
                      <p><label>{{ "orderDetail.productNumber" | cxTranslate }}</label><span>{{ data?.orderEntry?.product?.code }}</span></p>
                      <p><label>{{ "orderDetail.unitCancelled" | cxTranslate }}</label><span>{{data?.quantityDeclined}}</span></p>
                      <p><label>{{ "orderDetail.reason" | cxTranslate }}</label><span>{{data.rejectionReason}}</span></p>
                  </section>
                </ng-container>
                </ng-container>
              </ng-container>
          </section>
        </li>
      </ul>
    </section>
    <div *ngIf="orderDetailsData?.invoices?.length > 0">
      <section class="light-grey-line"></section>
      <h4 class="headerTextDetailsPage">
        {{ "orderDetail.invoices" | cxTranslate }}
      </h4>

      <ul class="checkout-details-view">
        <li>
          <!-- Desktop Table -->
          <section class="row row-20 details-Table">
            <section class="col-md-12 orderDetailTable">
              <section class="dataTables-wrapper">
                <table class="mt-20" aria-describedby="orderTable">
                  <thead>
                  <tr class="heading">
                    <th scope="col">
                      {{ "orderDetail.invoiceDate" | cxTranslate }}
                    </th>
                    <th scope="col">
                      {{ "orderDetail.invoiceRefNo" | cxTranslate }}
                    </th>
                    <th scope="col">
                      {{ "orderDetail.invoiceAmount" | cxTranslate }}
                    </th>
                    <th scope="col">
                      {{ "orderDetail.invoiceCurrency" | cxTranslate }}
                    </th>
                  </tr>
                  </thead>
                  <tbody class="datatable-row desktop-table-consignments">
                  <ng-container *ngFor="let invoice of orderDetailsData?.invoices;">
                    <tr class="details-col">
                      <td class="text-center">{{ invoice?.invoiceDate | slice:0:10 | date:'dd-MMM-y' }}</td>
                      <td class="text-center">{{ invoice?.invoiceNumber }}</td>
                      <td class="text-center">
                        {{ invoice?.invoiceAmount }}
                      </td>
                      <td class="text-center">{{ invoice?.invoiceCurrency }}</td>
                    </tr>
                  </ng-container>
                  </tbody>
                </table>
              </section>
            </section>
          </section>
        </li>
      </ul>
    </div>
  </section>
</section>
