import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { OutletPosition, provideOutlet } from '@spartacus/storefront';
import { FaqTitleModule } from '../shared/modules/faq-title/faq-title.module';
import { AccordianAnswersComponent } from './accordian-answers/accordian-answers.component';
import { FaqComponent } from './faq/faq.component';


@NgModule({
  declarations: [FaqComponent, AccordianAnswersComponent],
  imports: [CommonModule,NgbModule,FaqTitleModule],
  providers: [
    provideOutlet({
      id: 'FAQ-left',
      position: OutletPosition.REPLACE,
      component: FaqComponent,
    }),
  ],
})
export class FaqModule {}
