import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, OnInit } from '@angular/core';
import { CmsService } from '@spartacus/core';
import * as XLSX from 'xlsx';
import { CustomAddToCartService } from '../custom-add-to-cart.service';
@Component({
  selector: 'app-import-cart',
  templateUrl: './import-cart.component.html',
  styleUrls: ['./import-cart.component.scss']
})
export class ImportCartComponent implements OnInit {
  numberOfColumnsError = false;
  showSuccessMessage = false;
  incorrectColumnsError = false;
  noFileSelectedError = false;
  incorrectFileFormatError = false;
  successMessageTimer: any;
  fileCorrect = false;
  fileName = '';
  file: File;
  cmsContent: any;
  constructor(private readonly ref: ChangeDetectorRef, private readonly addToCart: CustomAddToCartService, private readonly http: HttpClient,
    private readonly cmsData: CmsService, private elementRef: ElementRef) {
  }

  ngOnInit(): void {
    this.cmsData
      .getComponentData('ImportCartDzComponent')
      .subscribe((res) => {
        const needAssistanceContent: any = JSON.parse(JSON.stringify(res));
        this.cmsContent = needAssistanceContent.content;
      });
  }

  ngAfterViewInit() {
    this.elementRef.nativeElement.querySelector('#exportButton')
      .addEventListener('click', this.exportCSV.bind(this));
    this.elementRef.nativeElement.querySelector('#exportButtonXLS')
      .addEventListener('click', this.exportXLSX.bind(this));
    this.elementRef.nativeElement.querySelector('#csvFile')
      .addEventListener('change', this.choosefile.bind(this));
    this.elementRef.nativeElement.querySelector('#importButton')
      .addEventListener('click', this.importcsvxls.bind(this));
  }

  exportCSV(event) {
    this.addToCart.downloadImportCartTemplate();
  }

  exportXLSX(event) {
    this.addToCart.downloadImportCartTemplatexls();
  }

  choosefile(event) {
    console.log(event)
    const file: File = event.target.files[0];

    if (file) {
      this.fileName = file.name;
      this.file = file;
      this.elementRef.nativeElement.querySelector('#chosenfile').innerHTML = this.fileName;
    }
  }

  importcsvxls() {
    const btn = this.elementRef.nativeElement.querySelector('#importButton');
    btn.disabled = true;
    this.sendFile();
  }


  /*onFileSelected(event) {
    console.log(event)
    const file: File = event.target.files[0];

    if (file) {
      this.fileName = file.name;
      this.file = file;
    }
  }*/

  async sendFile() {
    if (this.file) {
      this.showSuccessMessage = false;
      await this.validateFile(this.file);
      if (this.fileCorrect) {
        this.addToCart.importCart(this.file);
        this.addToCart.importCartResult$.subscribe((response) => {
          if (201 === response.status) {
            this.fileName = null;
            this.file = null;
            this.showSuccessMessage = true;
            this.ref.detectChanges();
            this.elementRef.nativeElement.querySelector('#chosenfile').innerHTML = '';
            clearTimeout(this.successMessageTimer);
            this.successMessageTimer = setTimeout(() => this.closeInfoAlert(), 2500);
            const btn = this.elementRef.nativeElement.querySelector('#importButton');
            btn.disabled = false;
          }
            const btn = this.elementRef.nativeElement.querySelector('#importButton');
            btn.disabled = false;
        }
         ,(error: HttpErrorResponse) => {
              const btn = this.elementRef.nativeElement.querySelector('#importButton');
              btn.disabled = false;
            }
       );
      }
    }
    else {
      this.noFileSelectedError = true;
      this.ref.detectChanges();
      const btn = this.elementRef.nativeElement.querySelector('#importButton');
      btn.disabled = false;
    }
  }


  get_header_row(sheet) {
    var headers = [];
    var range = XLSX.utils.decode_range(sheet['!ref']);
    var C, R = range.s.r; /* start in the first row */
    /* walk every column in the range */
    for (C = range.s.c; C <= range.e.c; ++C) {
      var cell = sheet[XLSX.utils.encode_cell({ c: C, r: R })] /* find the cell in the first row */
      // console.log("cell",cell)
      var hdr = "UNKNOWN " + C; // <-- replace with your desired default 
      if (cell && cell.t) {
        hdr = XLSX.utils.format_cell(cell);
        headers.push(hdr);
      }
    }
    return headers;
  }
 

  async validateFile(file: File) {
    this.fileCorrect = true;
    this.noFileSelectedError = false;
    this.numberOfColumnsError = false;
    this.incorrectColumnsError = false;
    this.incorrectFileFormatError = false;
    const fileReader = new FileReader();
    const exceltoJson = {};
    let headerJson = {};
    await new Promise((resolve, reject) => {
      fileReader.onload = () => {
        if (this.fileName.endsWith('.xlsx')){
          fileReader.readAsBinaryString(file);
          exceltoJson['filename'] = file;
          fileReader.onload = (e: any) => {
            /* create workbook */
            const binarystr: string = e.target.result;
            const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });
            for (var i = 0; i < wb.SheetNames.length; ++i) {
              const wsname: string = wb.SheetNames[i];
              const ws: XLSX.WorkSheet = wb.Sheets[wsname];
              const data = XLSX.utils.sheet_to_json(ws); // to get 2d array pass 2nd parameter as object {header: 1}
              exceltoJson[`sheet${i + 1}`] = data;
              const headers = this.get_header_row(ws);
              headerJson[`header${i + 1}`] = headers;
            }
            exceltoJson['headers'] = headerJson;
            console.log(exceltoJson['headers']['header1']);
            const headers = exceltoJson['headers']['header1'];
            const requiredColumns = ['GMID', 'Product name', 'Quantity'];
            const missingColumns = requiredColumns.filter(column => {
              return !headers.find(header => header.toLowerCase().startsWith(column.toLowerCase()));
            });
            
            if (!this.fileName.endsWith('.csv') && !this.fileName.endsWith('.xlsx'))
            {
              this.incorrectFileFormatError = true;
              this.fileCorrect = false;
              const btn = this.elementRef.nativeElement.querySelector('#importButton');
              btn.disabled = false;
            }
            else{
              if (headers.length < 3) {
                this.numberOfColumnsError = true;
                this.fileCorrect = false;
                const btn = this.elementRef.nativeElement.querySelector('#importButton');
                btn.disabled = false;
              }
              else if (missingColumns.length > 0) {
                this.incorrectColumnsError = true;
                this.fileCorrect = false;
                const btn = this.elementRef.nativeElement.querySelector('#importButton');
                btn.disabled = false;
              }
            }
            this.ref.detectChanges();
            resolve(file);
        };
        }else{
          const csvString = fileReader.result as string;
          console.log(csvString)
          const rows = csvString.split(/[\r\n]/g);
          const headers = rows[0].split(/[;|,]/g);
          const requiredColumns = ['GMID', 'Product name', 'Quantity'];
          const missingColumns = requiredColumns.filter(column => {
            return !headers.find(header => header.toLowerCase().startsWith(column.toLowerCase()));
          });
          
          if (!this.fileName.endsWith('.csv') && !this.fileName.endsWith('.xlsx'))
          {
            this.incorrectFileFormatError = true;
            this.fileCorrect = false;
            const btn = this.elementRef.nativeElement.querySelector('#importButton');
            btn.disabled = false;
          }
          else{
            if (headers.length < 3) {
              this.numberOfColumnsError = true;
              this.fileCorrect = false;
              const btn = this.elementRef.nativeElement.querySelector('#importButton');
              btn.disabled = false;
            }
            else if (missingColumns.length > 0) {
              this.incorrectColumnsError = true;
              this.fileCorrect = false;
              const btn = this.elementRef.nativeElement.querySelector('#importButton');
              btn.disabled = false;
            }
          }
          this.ref.detectChanges();
          resolve(file);
        }
      };
      fileReader.readAsText(file);
    });

  }

  /*exportXls() {
    this.addToCart.downloadImportCartTemplate();
  }

  exportXlsnew() {
    this.addToCart.downloadImportCartTemplatexls();
  }*/

  closeInfoAlert() {
    this.showSuccessMessage = false;
    this.ref.detectChanges();
  }
}
