import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {SelectWhsPopupComponent} from './select-whs-popup.component';
import {AuthGuard, CmsConfig, ConfigModule, I18nModule, provideConfig} from '@spartacus/core';
import {FormsModule} from '@angular/forms';
import {SelectWhsPopupGuard} from './services/guards/select-whs-popup.guard';
import {translationChunksConfig} from '@spartacus/assets';


@NgModule({
  declarations: [SelectWhsPopupComponent],
  imports: [
    CommonModule,
    ConfigModule.withConfig({
      cmsComponents: {
        SwitchWhsPopupComponent: {
          component: SelectWhsPopupComponent,
          guards: [AuthGuard, SelectWhsPopupGuard],
        },
      },
    } as CmsConfig),
    FormsModule,
    I18nModule,
  ],
  exports: [SelectWhsPopupComponent],
  providers: [
    provideConfig({
      i18n: {
        backend: {
          loadPath: 'assets/i18n-assets/{{lng}}/{{ns}}.json',
        },
        chunks: translationChunksConfig,
      },
    }),
  ],
})
export class SelectWhsPopupModule {
}
