<section class="productList">
    <section class="product-page" *ngIf="(productList$ | async) as productList">
        <section *ngIf="productList?.products?.length>0 || productList?.freeTextSearch?.length==0">
        <section class="header">

            <section class="content content-right">
                <section class="top" id="pagination">
                    <section class="left">

                        <span> <span>{{'plp.showing'|cxTranslate}}</span> {{pageRange}} {{'plp.of'|cxTranslate}}
                            {{productList.pagination?.totalResults}} {{'plp.products'|cxTranslate}} 
                            <span *ngIf="productList?.freeTextSearch?.length>0"> {{'plp.for'|cxTranslate}} 
                                <span class="search-text">{{productList?.freeTextSearch}}</span></span>
                        </span>
                    </section>
                    <span class="page-info">{{'plp.page'|cxTranslate}} {{currentPage}} {{'plp.of'|cxTranslate}}
                        {{productList.pagination?.totalPages}} 
                    </span>
                    <cx-pagination (viewPageEvent)="performPagination($event)" queryParam="currentPage"
                        [pagination]="this.pagination">
                    </cx-pagination>
                    

                </section>
                <section class="bottom">
                    <section class="left">
                        <section *ngIf="facets$?.length>0;else filterNone" class="facets-filter">
                            <section>
                                <cx-active-facets #facetRef [facet]="facet$"></cx-active-facets>
                            </section>
                            <section id="clearAll-filters">
                                <a href="/dz/fr_DZ/DZD/Open-Catalogue/c/1" data-di-id="di-id-37774359-a6b0cba1">
                                    {{'plp.clearFliter'|cxTranslate}}</a>
                            </section> 
                        </section>

                        <ng-template #filterNone>
                            <section class="col-md-8 col-sm-7 hidden-xs js-facet-values filter-facet-none">
                                {{'plp.filtersApplied'|cxTranslate}} :
                                <span>{{'plp.none'|cxTranslate}}</span>
                            </section>
                        </ng-template>
                    </section>
                    <section class="right">

                        <cx-sorting placeHolder="Select Sort" selectedOption="{{selectedData}}"
                            [sortOptions]=" productList.sorts " (sortListEvent)="sortList($event)">
                        </cx-sorting>
                    </section>
                </section>

            </section>
        </section>
        <section class=" main ">
            <section class=" content content-right ">

                <app-custom-product-card *ngFor=" let product of productList.products " [product]=" product " [iconDisplay]=true>

                </app-custom-product-card>

            </section>
        </section>
        <section class="bottom-pagination">
        <section class="text-center showing-text">
            <span>{{'plp.showing'|cxTranslate}} {{pageRange}} {{'plp.of'|cxTranslate}}
                {{productList.pagination?.totalResults}}
                {{'plp.products'|cxTranslate}}</span>
        </section>
        <section class="bottom-pagination-center">
        <section class="mx-auto">
            <cx-pagination (viewPageEvent)="performPagination($event)" queryParam="currentPage"
                [pagination]="this.pagination">
            </cx-pagination>
        </section>
        </section>
        <section class="text-center">
            <span class="page-info">{{'plp.page'|cxTranslate}} {{currentPage}} {{'plp.of'|cxTranslate}}
                {{productList.pagination?.totalPages}} </span>
        </section>
        </section>
    </section>
    <section class="featureProdTxt" *ngIf="productList?.freeTextSearch?.length>0 && productList?.products?.length==0">
        <p style="display: block" id="noReuslt-found" class="bold-12" role="alert" aria-live="assertive">
                {{'plp.sorryNoProducts'|cxTranslate}} "{{productList?.freeTextSearch}}" {{'plp.noProducts'|cxTranslate}}</p>
        </section>
    </section>
</section>
