<section class="row makePaymentView" id="productList-area">
  <aside class="col-md-2 col-sm-3 float-left" *ngIf="!showSection">
    <section class="vertical-banner">
	<div class="filterByGroup">
        <div class="filterByTitle mb-2">
          <font style="vertical-align: inherit"
            ><font style="vertical-align: inherit">
              {{ "ordersList.dateRange" | cxTranslate }}</font
            ></font
          >
        </div>
        <div class="delivary-details">
          <div>
            <fieldset class="form-horizontal mb0">
              <legend class="control-group mb-2">
                <label for="fromDate" aria-labelledby="dataRange fromDate"
                  ><font style="vertical-align: inherit"
                    ><font style="vertical-align: inherit"
                      >{{ "ordersList.from" | cxTranslate }}
                    </font></font
                  ></label
                >
              </legend>
              <div class="headSearch-holder">
                <label class="searchbox orders-filter time-frame"
                  ><img src="../../../assets/icons/calendar.svg" alt="" /><input
                    type="text"
                    placeholder="{{ 'ordersList.dateFormat' | cxTranslate }}"
                    class="input-filter delivery-pickup-calendar"
                    (ngModelChange)="validateDate()"
                    bsDatepicker
                    [maxDate]="maxSelectionDate"
                    [daysDisabled]="[5, 6]"
                    [bsConfig]="{ containerClass: 'order-list-date' }"
                    [(ngModel)]="fromDate"
                /></label>
              </div>
            </fieldset>
          </div>
        </div>
        <div class="delivary-details">
          <div>
            <fieldset class="form-horizontal mb0">
              <legend class="control-group mb-2">
                <label for="toDate" aria-labelledby="dataRange toDate"
                  ><font style="vertical-align: inherit"
                    ><font style="vertical-align: inherit">{{
                      "ordersList.to" | cxTranslate
                    }}</font></font
                  ></label
                >
              </legend>
              <div class="headSearch-holder">
                <label class="searchbox orders-filter time-frame"
                  ><img src="../../../assets/icons/calendar.svg" alt="" />
                  <input
                    type="text"
                    placeholder="{{ 'ordersList.dateFormat' | cxTranslate }}"
                    class="input-filter"
                    (ngModelChange)="validateDate()"
                    bsDatepicker
                    [maxDate]="maxSelectionDate"
                    [daysDisabled]="[5, 6]"
                    [bsConfig]="{ containerClass: 'order-list-date' }"
                    [(ngModel)]="toDate"
                /></label>
              </div>
            </fieldset>
          </div>
        </div>
        <span class="dateError" *ngIf="dateError">{{
          "ordersList.invalidDateErrMsg" | cxTranslate
        }}</span>
        <span class="dateError" *ngIf="singleDateError">{{
          "ordersList.selectBothDate" | cxTranslate
        }}</span>
      </div>
      <!-- Filter and cancel button-->
      <div class="account-order-filter-btn">
        <button
          type="submit"
          data-di-id="di-id-362f2c77-5f23e741"
          class="btn btn-default blue-button"
          (click)="applyFilter()"
        >
          <font style="vertical-align: inherit"
            ><font style="vertical-align: inherit">{{
              "ordersList.applyFilters" | cxTranslate
            }}</font></font
          ></button
        ><button
          type="button"
          id="clearOrderFiltersBtn"
          data-di-id="#clearOrderFiltersBtn"
          class="btn btn-default white-button"
          (click)="clearFilters()"
        >
          <font style="vertical-align: inherit"
            ><font style="vertical-align: inherit">{{
              "ordersList.clearFilters" | cxTranslate
            }}</font></font
          >
        </button>
      </div>
	</section>
  </aside>
  <section class="{{ statementWidth }} col-xs-12 table-s-section float-right"
    [ngClass]="{ 'acc-overview-sp': showSection === true }">
    <section class="credits-section" [ngClass]="{ 'acc-overview-sp': showSection === true }">
      <section class="row">
        <section class="col-sm-6 col-md-6 col-xs-12 op_ovice" [ngClass]="{ 'acc-overview-sp': showSection === true }">
          <h3 class="oi-table-title" [ngClass]="{ 'acc-overview-sp': showSection === true }">
            {{ "statements.statementList.title" | cxTranslate }}
          </h3>
        </section>
        <section class="col-6 col-md-6 col-sm-6 col-xs-12 text-right openstatement-btn">
          <button type="button" (click)="viewCurrent()" class="btn btn-primary"
            [ngClass]="{ 'acc-overview-sp': showSection === true }">
            {{ "statements.statementList.view_text" | cxTranslate }}
          </button>
          <a title="XLS" id="invoiceXLS" aria-label="download account overview invoices as" data-di-id="#invoiceXLS">
            <img src="../../assets/icons/excel.png" alt="" class="ad-pdf" target="_blank" />
            <span (click)="downloadExcel()" class="acc-icon-text">{{
              "statements.statementList.excel_text" | cxTranslate
              }}</span></a>
          <a (click)="viewPDF()" class="pdf btnIphone"
            [ngClass]="{ 'acc-overview-sp': showSection === true }">
            <img src="../../assets/icons/pdf.png" alt="pdf" class="pdf" />
            {{ "statements.statementList.pdf_text" | cxTranslate }}
        </a>
        </section>
		 <hr _ngcontent-serverapp-c141="" class="hidden-xs" />
        <div *ngIf="!showSection"
          [ngClass]="
            facetsList.length > 2 ? 'page-dropdown-ipad' : 'page-dropdown'
          "
          class="row row-20 col-lg-12 page-dropdown"
          ng-reflect-ng-class="page-dropdown"
        >
          <div
            _ngcontent-serverapp-c141=""
            class="col-md-8 col-lg-12 col-sm-7 hidden-xs applied-filters"
          >
            {{ "ordersList.filtersApplied" | cxTranslate }}
            <span *ngIf="!facetsList.length">{{
              "ordersList.none" | cxTranslate
            }}</span>
            <span _ngcontent-serverapp-c141="" id="applied-filters" class="ml-2"
              ><span
                _ngcontent-serverapp-c141=""
                tabindex="0"
                *ngFor="let filter of facetsList"
                class="filterText focus mr-1 mb-2 ng-star-inserted"
                ><div _ngcontent-serverapp-c141="">
                  {{ filter.value
                  }}<img
                    _ngcontent-serverapp-c141=""
                    src="../../../assets/icons/white close.png"
                    alt=""
                    (click)="removeFacet(filter)"
                    class="remove-facet"
                  /><span
                    _ngcontent-serverapp-c141=""
                    class="filterRemove"
                  ></span></div></span
              ><!--bindings={
  "ng-reflect-ng-for-of": "[object Object]"
}--><a
                _ngcontent-serverapp-c141=""
                class="clear-filters ml-1 ng-star-inserted"
                (click)="clearFilters()"
                *ngIf="facetsList.length"
                >{{ "ordersList.clearAllFilters" | cxTranslate }}</a
              ><!--bindings={
  "ng-reflect-ng-if": "1"
}--></span
            >
          </div>
        </div>
      </section>
    </section>

    <section class="payment-section" [ngClass]="{ 'acc-overview-sp': showSection === true }">
      <table aria-describedby="creditsTable" id="applyCreditsTable" class="credits-table table table-striped">
        <thead>
          <tr>
            <th scope="col" class="statement" [ngClass]="{ 'acc-overview-sp': showSection === true }">
              {{
              "statements.statementList.statement_table_label" | cxTranslate
              }}&nbsp;
            </th>

            <th scope="col" class="text-right" [ngClass]="{ 'acc-overview-sp': showSection === true }"></th>
            <th scope="col" [ngClass]="{ 'acc-overview-sp': showSection === true }"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of statementsData?.dateMap; let i = index">
            <td>
              <span>{{ data?.value | date: "dd/MM/yyyy" }}</span>
            </td>
            <td>
              <a title="view statement link" (click)="openNewModalPopup(data.value, data.key)" class="view-link">{{
                "statements.statementList.view_text" | cxTranslate }}</a>
            </td>
            <td>
              <a title="download PDF link" (click)="generateSavePdf(data.value, data.key)"><img
                  src="../../assets/icons/pdf.png" alt="pdf" class="pdf-design" />{{ "statements.statementList.pdf_text"
                | cxTranslate }}</a>
            </td>
          </tr>
        </tbody>
      </table>
      <table aria-describedby="statementsTable" id="applyStatementsTable" class="credits-table table table-striped">
        <tr class="sample-header">
          <th scope="col"></th>
          <th scope="col"></th>
        </tr>
        <tr *ngFor="let data of statementsData?.dateMap; let i = index">
          <td class="two-column-lay header">
            {{ "statements.statementList.statement_table_label" | cxTranslate }}
          </td>
          <td class="two-column-lay">
            <span>{{ data?.value | date: "dd/MM/yyyy" }}</span>
          </td>
          <td class="two-column-lay header"></td>
          <td class="two-column-lay">
            <a title="view statement link" (click)="openNewModalPopup(data.value, data.key)" class="view-link">{{
              "statements.statementList.view_text" | cxTranslate }}</a>
          </td>
          <td class="two-column-lay header"></td>
          <td class="two-column-lay">
            <a title="download PDF link" (click)="generateSavePdf(data.value, data.key)"><img
                src="../../assets/icons/pdf.png" alt="pdf" class="pdf-design" />{{ "statements.statementList.pdf_text" |
              cxTranslate }}</a>
          </td>
        </tr>
      </table>
    </section>
  </section>
</section>
<section id="temp-pdf" *ngIf="showPortal">
  <app-statement-display [statement]="details" [toShow]="showModal"></app-statement-display>
</section>