<div id="location-select" [ngClass]="{'Not-Visibile':!showLocStrip}">
  <div class="locationIcon">
    <img src="../../../../assets/icons/location.svg" alt="">
  </div>
  <div class="locationDetails">

        <span class="selected-loc">
          <span
            class="locationNameShipping">{{usergetDataObj?.locName ? usergetDataObj?.locName : usergetDataObj?.name}}
          </span>
          {{"(" + usergetDataObj?.uid + ")"}}
        </span>
    <div *ngIf="sessionStorage.getItem('accountType') === 'INDIRECT'">
      <span>{{'wholesaler.selected'|cxTranslate}}: {{currentCustomer?.wholesalerDisplayName}}</span>
      <div class="switch-whs-popup">
        <a [routerLink]="['/select-whs']">{{'wholesaler.change'|cxTranslate}}</a>
      </div>
    </div>
  </div>
</div>
