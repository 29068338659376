<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 bg-color">
    <div class="col-lg-5 col-md-5 col-sm-0 col-xs-12 p0"></div>
    <div class="col-lg-8 col-md-8 col-sm-6 col-xs-12 middle-row p0 mt0 continue-button">
        <a href="/dz/fr_DZ/DZD/Open-Catalogue/c/1" target="" id="continueShoppingId" data-di-id="#continueShoppingId">
            <img src="../../assets/icons/chevron-left.svg" alt="" class="chevron-left continue-icon">
            {{'addToCartItems.continueShopping'|cxTranslate}}</a>
    </div>

</div>
<div *ngIf="renamedCart!==''" class="global-alerts">

    <div class="alert alert-info alert-dismissable getAccAlert">
        <button (click)="closeAlert()" class="close closeAccAlert" aria-hidden="true" data-dismiss="alert" type="button"
            data-di-id="di-id-4f6d7b93-70563360">×</button>
        {{'addToCartItems.order'|cxTranslate}} {{renamedCart}} {{'addToCartItems.successMsg'|cxTranslate}}.
    </div>
</div>
<div id="main-container" class="container saved-orders">

    <div class="row row-20" id="leftCont-rightNav">

        <section class="col-xs-12 col-sm-12 col-md-12 left-section testcart" style="min-height: 165px;">
            <ng-template #emptySavedCart>
                <section class="edit-saved-cart">
                    <div class="bold-12 empty-cart-content">
                        <h3 class="font-20">{{'addToCartItems.emptySaveOrder'|cxTranslate}}</h3>
                        <div class="darkblue font-14 empty-cart-options">
                            <div><a href="/dz/fr_DZ/DZD/Open-Catalogue/c/1" title="Order products" target=""
                                    data-di-id="di-id-66e71f9c-2a8224d">{{'addToCartItems.emptyOrderProduct'|cxTranslate}}</a>
                            </div>
                            <div><a href="/my-account/account-overview" title="My Account" target=""
                                    data-di-id="di-id-4bc01e1-da980c73">{{'addToCartItems.myAccount'|cxTranslate}}</a>
                            </div>
                        </div>
                    </div>
                </section>
            </ng-template>
            <section *ngIf="savedcarts && savedcarts?.length>0; else emptySavedCart">
                <section class="edit-saved-cart" *ngFor="let carts of savedcarts">
                    <div class="row add-to-cart">
                        <img class="save-cart-icon" src="../../assets/icons/cart.png" alt="">
                        <div class="col-sm-7 col-md-5 col-lg-5 saved-prod-header" id="cart-value1">
                            <span class="cartIconleft float-left"></span>
                            <h5 class="bold-12 light-color" id="cartHeading">{{carts?.name}}</h5>
                        </div>
                        <div class="col-sm-9 col-md-5 col-lg-3 hidden-xs p0 align-right-view">
                            <span class="actionOptions">
                                <a id="linkViewEdit" aria-labelledby="cartHeading" aria-describedby="linkViewEdit"
                                    target="" class="edit" data-di-id="#linkViewEdit"
                                    (click)="viewOrEditOrder(carts?.code)"><img class="edit-icon"
                                        src="../../assets/icons/editIcon.png"
                                        alt="">{{'addToCartItems.view/cart'|cxTranslate}}</a>
                                <a href="javascript:void(0)" class="trash save-cart" data-toggle="popover"
                                    data-placement="top" data-target="save-cart-0" data-original-title=""
                                    id="linkViewDelete" aria-labelledby="cartHeading" data-di-id="#linkViewDelete"
                                    [ngbPopover]="popDeltContent" [popoverTitle]="popDeltTitle" triggers="manual"
                                    #p3="ngbPopover" placement="top" (click)="toggle(p3)" container="body"
                                    popoverClass="popover-links"> <img class="delt-icon"
                                        src="../../assets/icons/trash.svg"
                                        alt="">{{'addToCartItems.delete'|cxTranslate}}</a>
                                <!--Popover content for Save cart start-->
                                <!--Popover content for Save cart end-->
                                <ng-template #popDeltTitle><span
                                        class="text-header">{{'addToCartItems.delete'|cxTranslate}} {{carts?.name}}
                                        {{'addToCartItems.deleteTitleSavedOrder'|cxTranslate}}</span></ng-template>
                                <ng-template #popDeltContent>
                                    <button type="reset"
                                        class="popover-close btn white-button popover-cancel-button savedelt-cancel"
                                        data-di-id="di-id-d01008be-fb6e135c" (click)="toggle(p3)">
                                        {{'addToCartItems.cancel'|cxTranslate}}</button>
                                    <button class="btn blue-button popover-cancel-button savedelt-delete" type="submit"
                                        data-di-id="di-id-3064f17d-e97cc628" (click)="deleteCart(carts?.code)">
                                        {{'addToCartItems.delete'|cxTranslate}}</button>
                                </ng-template>
                            </span>
                        </div>
                        <div class="btn-section">
                            <input type="button"
                                class="btn btn-default blue-button save-cart-btn saved-order-cart visible-xs btn-cart-mobileView"
                                value="{{'addToCartItems.restore'|cxTranslate}}" (click)="restoreClick(restorecart,carts?.code)">
                            <ng-template #restorecart class="restore-popover">
                                <div class="modal-content overlay restore-modal">
                                    <div class="modal-header overlay-heading">
                                        <h4 class="modal-title checkout-title restore-header">
                                            {{'addToCartItems.restoreheader'|cxTranslate}} </h4>
                                    </div>

                                    <div class="modal-body text-center confirm-order">
                                        <p> {{'addToCartItems.restoreContentBody'|cxTranslate}}
                                        </p>

                                        <button type="submit"
                                            class="btn btn-default blue-button blue_white order-confirm clear-restore-scart"
                                            data-toggle="modal" data-target="#loading-order" id="orderConfirmbtn"
                                            data-di-id="#orderConfirmbtn"
                                            (click)="clearSessionRestoreCart(carts?.code)">
                                            {{'addToCartItems.restoreClearCart'|cxTranslate}}
                                        </button>
                                        <button type="button"
                                            class="btn btn-default white-button checkout-edit keepexisting-restore-cart"
                                            data-toggle="modal" data-target="#overlay"
                                            data-di-id="di-id-35119eec-5a7dc742" (click)="closeModal()">
                                            {{'addToCartItems.restoreExistingBtn'|cxTranslate}}</button>
                                    </div>
                                </div>
                            </ng-template>
                        </div>
                        <span class="actionOptions-ipad">
                            <a id="linkViewEdit" aria-labelledby="cartHeading" aria-describedby="linkViewEdit" target=""
                                class="edit" data-di-id="#linkViewEdit" (click)="viewOrEditOrder(carts?.code)"><img
                                    class="edit-icon" src="../../assets/icons/editIcon.png"
                                    alt="">{{'addToCartItems.view/cart'|cxTranslate}}</a>
                            <a href="javascript:void(0)" class="trash save-cart" data-toggle="popover"
                                data-placement="top" data-target="save-cart-0" data-original-title=""
                                id="linkViewDelete" aria-labelledby="cartHeading" data-di-id="#linkViewDelete"
                                [ngbPopover]="popDeltContentipad" [popoverTitle]="popDeltTitleipad" triggers="manual"
                                #d2="ngbPopover" placement="top" (click)="toggle(d2)" container="body"
                                popoverClass="popover-links delete-rwd-ipad"> <img class="delt-icon" src="../../assets/icons/trash.svg"
                                    alt="">{{'addToCartItems.delete'|cxTranslate}}</a>
                                    <ng-template #popDeltTitleipad><span
                                        class="text-header">{{'addToCartItems.delete'|cxTranslate}} {{carts?.name}}
                                        {{'addToCartItems.deleteTitleSavedOrder'|cxTranslate}}</span></ng-template>
                                <ng-template #popDeltContentipad>
                                    <button type="reset"
                                        class="popover-close btn white-button popover-cancel-button savedelt-cancel"
                                        data-di-id="di-id-d01008be-fb6e135c" (click)="toggle(d2)">
                                        {{'addToCartItems.cancel'|cxTranslate}}</button>
                                    <button class="btn blue-button popover-cancel-button savedelt-delete" type="submit"
                                        data-di-id="di-id-3064f17d-e97cc628" (click)="deleteCart(carts?.code)">
                                        {{'addToCartItems.delete'|cxTranslate}}</button>
                                </ng-template>
                        </span>

                    </div>
                    <div class="saved-cart-comment" *ngIf="!!carts?.description.trim()">
                      {{'addToCartItems.comment'|cxTranslate}} {{carts?.description}} <br /> <a *ngIf="carts?.description.trim() != '-'" class='error_import_click' (click)="download_file_error(carts?.code)">{{'addToCartItems.productFile'|cxTranslate}}</a>
                    </div>

                    <div class="row cart-price-data">
                        <div class="col-md-12 col-sm-12">
                            <div class="table-responsive">
                                <table class="table table-saved-cart" aria-describedby="saveCart">
                                    <thead>
                                        <tr>
                                            <th id="restoreTableCol1">
                                                {{'addToCartItems.mySavedOrder_TotalUnits'|cxTranslate}}</th>
                                            <th id="restoreTableCol2">
                                                {{'addToCartItems.mySavedOrder_LastOrdered'|cxTranslate}}</th>
                                            <th id="restoreTableCol3">
                                                {{'addToCartItems.mySavedOrder_LastUpdated'|cxTranslate}}</th>
                                            <th id="restoreTableCol4">
                                                {{'addToCartItems.mySavedOrder_dateCreated'|cxTranslate}}</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td headers="restoreTableCol1">
                                                {{getQuantity(carts.childCarts)}}</td>
                                            <td headers="restoreTableCol2">
                                                {{getOrders(carts.childCarts)}}
                                                </td>
                                            <td headers="restoreTableCol3">{{carts?.modifiedTime | slice:0:10 | date:'dd/MM/yyyy'}}
                                            </td>
                                            <td headers="restoreTableCol4">{{carts?.saveTime | slice:0:10 | date:'dd/MM/yyyy'}}
                                            </td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <section class="table-rwd">
                                <div class="row">
                                    <div class="column">
                                        <p>{{'addToCartItems.mySavedOrder_TotalUnits'|cxTranslate}}</p>
                                        <p>{{'addToCartItems.mySavedOrder_LastOrdered'|cxTranslate}}</p>
                                        <p>{{'addToCartItems.mySavedOrder_LastUpdated'|cxTranslate}}</p>
                                        <p>{{'addToCartItems.mySavedOrder_dateCreated'|cxTranslate}}</p>
                                    </div>
                                    <div class="column">
                                        <p>{{getQuantity(carts.childCarts)}}</p>
                                        <p>{{getOrders(carts.childCarts)}}</p>
                                        <p>{{carts?.modifiedTime | slice:0:10 | date:'dd/MM/yyyy'}}</p>
                                        <p>{{carts?.saveTime | slice:0:10 | date:'dd/MM/yyyy'}}</p>
                                    </div>
                                </div>
                            </section>
                            <div class="col-xs-12 width-100">
                                <span class="actionOptions-small">
                                    <a id="linkViewEdit" aria-labelledby="cartHeading" aria-describedby="linkViewEdit"
                                        target="" class="edit" data-di-id="#linkViewEdit"
                                        (click)="viewOrEditOrder(carts?.code)"><img class="edit-icon"
                                            src="../../assets/icons/editIcon.png"
                                            alt="">{{'addToCartItems.view/cart'|cxTranslate}}</a>
                                    <a href="javascript:void(0)" class="trash save-cart" data-toggle="popover"
                                        data-placement="top" data-target="save-cart-0" data-original-title=""
                                        id="linkViewDelete" aria-labelledby="cartHeading" data-di-id="#linkViewDelete" popoverClass="popover-links deleterwd-small"
                                        [ngbPopover]="popDeltContentrwd" [popoverTitle]="popDeltTitlerwd" triggers="manual"
                                        #d1="ngbPopover" placement="top" (click)="toggle(d1)" container="body"
                                        > <img class="delt-icon"
                                            src="../../assets/icons/trash.svg"
                                            alt="">{{'addToCartItems.delete'|cxTranslate}}</a>
                                            <ng-template #popDeltTitlerwd><span
                                                class="text-header">{{'addToCartItems.delete'|cxTranslate}} {{carts?.name}}
                                                {{'addToCartItems.deleteTitleSavedOrder'|cxTranslate}}</span></ng-template>
                                        <ng-template #popDeltContentrwd>
                                            <button type="reset"
                                                class="popover-close btn white-button popover-cancel-button savedelt-cancel"
                                                data-di-id="di-id-d01008be-fb6e135c" (click)="toggle(d1)">
                                                {{'addToCartItems.cancel'|cxTranslate}}</button>
                                            <button class="btn blue-button popover-cancel-button savedelt-delete" type="submit"
                                                data-di-id="di-id-3064f17d-e97cc628" (click)="deleteCart(carts?.code)">
                                                {{'addToCartItems.delete'|cxTranslate}}</button>
                                        </ng-template>
                                </span>
                            </div>
                        </div>
                        <!-- edit/delete options for small screen -->

                    </div>

                </section>
            </section>
        </section>
        <aside class="col-xs-12 col-sm-12 col-md-12 col-lg-4 right-navigation savedcart-right">
            <div class="item-numb">
                <h3 *ngIf="cartItemsNo===0;else noOfCart">{{'addToCartItems.emptycart'|cxTranslate}}</h3>
                <ng-template #noOfCart>
                    <h3>{{'addToCartItems.currentCart'|cxTranslate}} {{cartItemsNo}}
                        {{'addToCartItems.itemsCart'|cxTranslate}}</h3>
                </ng-template>
                <button type="button" class="btn btn-default white-button cart-edit-rename"
                    [ngClass]="{'disabled':cartItemsNo===0}" [disabled]="cartItemsNo===0" [ngbPopover]=popSaveContent
                    [popoverTitle]=popSaveTitle triggers="manual" #p2="ngbPopover" placement="top" (click)="toggle(p2)"
                    container="body" popoverClass="popover-links savethisorder-popclass">{{'addToCartItems.saveOrderLabel'|cxTranslate}}</button>
                <section class="savecart-saveorder">
                    <ng-template #popSaveTitle>{{'addToCartItems.saveOrderTitle'|cxTranslate}}</ng-template>
                    <ng-template #popSaveContent>
                        <input type="text" class="save-order" required #name="ngModel" maxlength="30"
                            [(ngModel)]="saveCartName" (keypress)="omit_special_char($event)">
                        <span *ngIf="name.errors?.required" class="common-error required">{{errorMsg}}</span>
                        <span class="common-error required">{{errorMsg}}</span>
                        <button type="reset" class="popover-close btn savethis-white-button popover-cancel-button"
                            (click)="toggle(p2)">
                            {{'addToCartItems.cancel'|cxTranslate}}</button>
                        <button class="btn blue-button popover-cancel-button savethis-blue-button" type="submit"
                            data-di-id="di-id-3064f17d-e97cc628" (click)="saveOrder()">
                            {{'addToCartItems.save'|cxTranslate}}</button>
                    </ng-template>
                </section>
                <!--Popover content for Save cart start-->
                <div class="hide" id="save-cart-order-pop-up">
                    <form id="saveCartForm" class="edit-popover savedcartpop" action="/cart/saveOrder" method="post">
                        <div class="col-xs-12 col-sm-12 col-md-12 p0">
                        </div>


                        <div>
                            <input type="hidden" name="CSRFToken" value="955a299b-a8e5-4a08-936d-b86589e8bc4a"
                                data-di-id="di-id-31b63261-66d9a693">
                        </div>
                    </form>
                </div>
                <p class="savedorder">
                    {{'addToCartItems.saveThisOrder'|cxTranslate}}</p>
            </div>
            <section class="ad-product-details cartProductSaveDetails"
                     *ngIf="sessionStorage.getItem('accountType') === 'DIRECT'">
                <h3>{{'addToCartItems.addProducts'|cxTranslate}}</h3>
                <h6>{{'addToCartItems.quickOrder'|cxTranslate}}</h6>
                <p>{{'addToCartItems.searchItems'|cxTranslate}}</p>
                <app-quick-order></app-quick-order>
            </section>
        </aside>
    </div>
</div>
