<section class="row row-20">
    <section class="container-pdp" *ngIf="displaySection">
        <aside aria-label="Product Details" class="col-xs-12 col-sm-4 col-md-4 right-navigation float-right">
            <a *ngIf="productDetails?.images != undefined || productDetails?.images?.length > 0"
                (click)="openImage(content)">

                <cx-media class="cx-product-image" [container]="productDetails?.images?.PRIMARY" format="product"
                    [alt]="productDetails?.summary"></cx-media>

            </a>
            <a *ngIf="productDetails?.images === undefined || productDetails?.images?.length === 0">
                <img class="image-set-in-card product-img" [src]="fallbackImage" alt="">
            </a>
            <ng-template #content let-modal>
                <ngb-carousel *ngIf="productDetails?.images != undefined || productDetails?.images?.length > 0"
                    ngClass="productDetails?.images?.GALLERY.length > 0 ? 'carousel-nopagination' : 'carousel-pagination' ">
                    <ng-template ngbSlide>
                        <div class="picsum-img-wrapper">
                          <!-- Show zoom format if it exist in resp -->
                          <ng-container *ngIf="productDetails?.images?.GALLERY.zoom != null">
                            <cx-media class="cx-product-image carousel-pdp" [container]="productDetails?.images?.GALLERY?.zoom" format="zoom"
                                [alt]="img">
                            </cx-media>
                            <div class="xzoom-thumbs">
                                <a class="item">
                                    <cx-media class="cx-product-image carousel-zoom" [container]="productDetails?.images?.GALLERY?.zoom"
                                        format="zoom" [alt]="img">
                                    </cx-media>
                                </a>
                            </div>
                          </ng-container>
                          <!-- Show primary product if no zoom exist in resp -->
                          <ng-container *ngIf="productDetails?.images?.GALLERY.zoom == null">
                            <cx-media class="cx-product-image carousel-pdp" [container]="productDetails?.images?.PRIMARY?.product" format="product"
                                [alt]="img">
                            </cx-media>
                            <div class="xzoom-thumbs">
                                <a class="item">
                                    <cx-media class="cx-product-image carousel-zoom" [container]="productDetails?.images?.PRIMARY?.product"
                                        format="product" [alt]="img">
                                    </cx-media>
                                </a>
                            </div>
                          </ng-container>
                        </div>
                    </ng-template>
                </ngb-carousel>
                <!-- <ngb-carousel *ngIf="productDetails?.images != undefined || productDetails?.images?.length > 0"
                    ngClass="productDetails?.images?.GALLERY.length > 0 ? 'carousel-nopagination' : 'carousel-pagination' ">
                    <ng-template ngbSlide *ngFor="let carousel of productDetails?.images?.GALLERY">
                        <div class="picsum-img-wrapper">
                            <cx-media class="cx-product-image carousel-pdp" [container]="carousel?.zoom" format="zoom"
                                [alt]="img">
                            </cx-media>
                            <div class="xzoom-thumbs">
                                <a class="item">
                                    <cx-media class="cx-product-image carousel-zoom" [container]="carousel?.zoom"
                                        format="zoom" [alt]="img">
                                    </cx-media>
                                </a>
                            </div>
                        </div>
                    </ng-template>
                </ngb-carousel> -->
            </ng-template>
            <aside aria-label="Product Details Pdf " class="col-sm-3 right-navigation float-left resources-section "
                *ngIf="monographLink ">
                <section>
                    <section class=" prod-detail-pdfs ">
                        <p class=" pdfs-title ">
                            {{'plp.resources'|cxTranslate}}</p>
                        <p class=" pdfs-list ">

                            <img class=" pdf-icon " src=" ../../assets/icons/pdf.png " alt=" ">
                            <a alt=" " [href]="monographLink" target=" _blank "
                                class=" file-link blue ">{{'plp.monograph'|cxTranslate}}</a>
                        </p>
                    </section>
                </section>
            </aside>
        </aside>
        <section class="col-xs-12 col-sm-8 col-md-8 left-section pb-2 float-left">
            <!-- <h3 class="pdp-title">{{productDetails?.name}}</h3> -->
            <p class="desc-class" [innerHTML]="description"></p>
            <br>
            <section class="batch-info">
              <section *ngIf="sessionStorage.getItem('accountType') !== 'INDIRECT'">
                <p class="desc-title" *ngIf="productDetails?.batchInfo?.batchNumber">{{'plp.batchNumber'|cxTranslate}}
                  <span class="desc-details">{{productDetails?.batchInfo?.batchNumber}}</span>
                </p>
                <p class="desc-title" *ngIf="productDetails?.batchInfo?.batchNumber">{{'plp.expiryDate'|cxTranslate}} :
                  <span class="desc-details">{{productDetails?.batchInfo?.expiryDate}}</span>
                </p>
                <p *ngIf="productDetails?.batchInfo?.batchNumber">{{'plp.batchInfo'|cxTranslate}}</p>
              </section>
                <p class="desc-title">{{'plp.productNumber'|cxTranslate}} : <span
                        class="desc-details">{{productDetails?.code}}</span> </p>
                <hr class="line">
                <section *ngIf="userDetails !== undefined">
                    <section *ngIf="!productDetails?.flu">
                        <section class="third-col">
                            <section class="row unit-calc">
                                <section class="col-md-4 col-xs-6 bottom-spacing price-area">
                                    <b>{{'plp.yourPrice'|cxTranslate}}</b>
                                    <section class="number">
                                        <span
                                            *ngIf="productDetails?.price == undefined || productDetails?.price?.value === undefined">-</span>
                                        <span
                                            *ngIf="productDetails?.price && productDetails?.price?.value !== undefined">
                                            <span>{{productDetails?.price?.value | localCurrency}}</span>
                                            {{'plp.cad'|cxTranslate}}
                                        </span>
                                    </section>

                                    <section
                                        *ngIf="productDetails?.price && productDetails?.price?.value !== undefined">
                                        <span>
                                            {{'plp.priceDisclaimerInfo'|cxTranslate}}
                                        </span>
                                    </section>
                                    <section class="unitDisplayName">
                                        <span *ngIf="productDetails?.price && productDetails?.price?.b2BUnitDisplayName !== undefined">
                                            <b>{{'plp.soldBy'|cxTranslate}}&nbsp;</b>
                                            <span>{{productDetails?.price?.b2BUnitDisplayName}}</span>
                                        </span>
                                    </section>
                                </section>
                            </section>

                            <section class="col-md-4 col-xs-6 bottom-spacing units-area" *ngIf="(productDetails?.price != null && productDetails?.price != undefined && productDetails?.price?.value != 0.0) && !productDetails?.discontinued">
                                <section>
                                    <section *ngIf="productDetails?.available">
                                        <b class="prodUnits">{{'plp.units'|cxTranslate}}</b>
                                        <section class="unit-area">
                                            <span class="disabled " aria-hidden="true"
                                                id="{{'minusIcon'+productDetails?.code}}"
                                                (click)="performQuantityComputation(2,productDetails?.code,productDetails?.quantityPerUnit)">
                                                <img src="../../assets/icons/less.svg"
                                                    id="{{'unitMinus'+productDetails?.code}}" class="disabled-img"
                                                    alt="">
                                                <img src="../../assets/icons/less-disable.svg"
                                                    id="{{'unitMinusDisable'+productDetails?.code}}" alt="">
                                            </span>
                                            <input type="number" class="prod-quantity text-center" value='1'
                                                (keyup)="performQuantityComputation(null,productDetails?.code,productDetails?.quantityPerUnit)"
                                                id="{{'prodQuantity'+productDetails?.code}}" min="1" max="9999"
                                                onkeypress="return /[0-9a-zA-Z]/i.test(event.key)" />
                                            <span class="" aria-hidden="true" id="{{'plusIcon'+productDetails?.code}}"
                                                (click)="performQuantityComputation(1,productDetails?.code,productDetails?.quantityPerUnit)">
                                                <img src="../../assets/icons/more.svg"
                                                    id="{{'unitPlus'+productDetails?.code}}" alt="">
                                                <img src="../../assets/icons/more-disable.svg"
                                                    id="{{'unitPlusDisable'+productDetails?.code}}" class="disabled-img"
                                                    alt="">
                                            </span>
                                        </section>
                                        <section class="doses-section" id="{{'noOfDoses'+productDetails?.code}}">
                                            {{productDetails?.quantityPerUnit}} {{'plp.doses'|cxTranslate}}</section>
                                    </section>
                                </section>
                            </section>
                            <section class="col-md-6 col-xs-6 bottom-spacing units-area text-right"  *ngIf="(productDetails?.price == null || productDetails?.price == undefined || productDetails?.price?.value == undefined || productDetails?.price?.value == 0.0) && !productDetails?.discontinued">
                                <section class="no-price"><p>{{'plp.noPrice' | cxTranslate}}</p></section>
                            </section>
                            <section class="col-md-4 col-sm-4 col-xs-12 bottom-spacing button-area"
                                *ngIf="(productDetails?.price != null && productDetails?.price != undefined || productDetails?.price?.value != undefined && productDetails?.price?.value != 0.0) && (!productDetails?.discontinued && productDetails?.available && !productDetails?.flu && productDetails?.price?.value != 0.0)">
                                <button class="blue-button login-btn ml10 added-success"
                                    data-di-id="di-id-2fea5a72-b2e23002" [ngbPopover]=popTitleSuccess placement="bottom"
                                    triggers="manual" #p2="ngbPopover" placement="bottom"
                                    popoverClass="pdp-itemsuccess-msg" (click)="addToCart(productDetails?.code, p2)">
                                    <i *ngIf="buttonName === 'Reserve now'" class="fa fa-calendar"
                                        aria-hidden="true"></i>
                                    <span
                                        *ngIf="buttonName==='Add to cart'">{{'addToCartItems.addCart'|cxTranslate}}</span>
                                    <span
                                        *ngIf="buttonName==='Login to order'">{{'addToCartItems.loginToOrder'|cxTranslate}}</span>
                                    <span class="reserve-now-button"
                                        *ngIf="buttonName==='Reserve now'">{{'addToCartItems.reserveNow'|cxTranslate}}</span>
                                </button>
                            </section>
                        </section>

                        <section *ngIf="productDetails?.whsPrices != undefined && (sessionStorage.getItem('accountType') === 'INDIRECT' || sessionStorage.getItem('accountType') === 'MIXED')">
                            <p class="whs-price-title">{{'plp.whsPriceOption'|cxTranslate}}</p>
                        </section>

                        <section *ngFor="let whsprice of productDetails?.whsPrices let i=index">
                        <section class="third-col">
                            <section class="row unit-calc">
                                <section class="col-md-4 col-xs-6 bottom-spacing price-area">
                                    <b>{{'plp.yourPrice'|cxTranslate}}</b>
                                    <section class="number">
                                        <span
                                            *ngIf="whsprice?.value == undefined || whsprice?.value === undefined">-</span>
                                        <span
                                            *ngIf="whsprice?.value && whsprice?.value !== undefined">
                                            <span>{{whsprice?.value | localCurrency}}</span>
                                            {{'plp.cad'|cxTranslate}}
                                        </span>
                                    </section>

                                    <section
                                        *ngIf="whsprice?.value && whsprice?.value !== undefined">
                                        <span>
                                            {{'plp.priceDisclaimerInfo'|cxTranslate}}
                                        </span>
                                    </section>
                                    <section class="unitDisplayName">
                                        <span *ngIf="whsprice?.value && whsprice?.b2BUnitDisplayName !== undefined">
                                            <b>{{'plp.soldBy'|cxTranslate}}&nbsp;</b>
                                            <span>{{whsprice?.b2BUnitDisplayName}}</span>

                                        </span>
                                    </section>
                                </section>
                            </section>

                            <section class="col-md-4 col-xs-6 bottom-spacing units-area" *ngIf="(whsprice?.value != null && whsprice?.value != undefined && whsprice?.value != 0.0) && !productDetails?.discontinued">
                                <section>
                                    <section *ngIf="productDetails?.available">
                                        <b class="prodUnits">{{'plp.units'|cxTranslate}}</b>
                                        <section class="unit-area">
                                            <span class="disabled " aria-hidden="true"
                                                id="{{'minusIcon'+whsprice?.b2BUnitCode}}"
                                                (click)="performQuantityComputation(2, whsprice?.b2BUnitCode, productDetails?.quantityPerUnit)">
                                                <img src="../../assets/icons/less.svg"
                                                    id="{{'unitMinus'+whsprice?.b2BUnitCode}}" class="disabled-img"
                                                    alt="">
                                                <img src="../../assets/icons/less-disable.svg"
                                                    id="{{'unitMinusDisable'+whsprice?.b2BUnitCode}}" alt="">
                                            </span>
                                            <input type="number" class="prod-quantity text-center" value='1'
                                                (keyup)="performQuantityComputation(null, whsprice?.b2BUnitCode, whsprice?.quantityPerUnit)"
                                                id="{{'prodQuantity'+whsprice?.b2BUnitCode}}" min="1" max="9999"
                                                onkeypress="return /[0-9a-zA-Z]/i.test(event.key)" />
                                            <span class="" aria-hidden="true" id="{{'plusIcon'+whsprice?.b2BUnitCode}}"
                                                (click)="performQuantityComputation(1, whsprice?.b2BUnitCode, productDetails?.quantityPerUnit)">
                                                <img src="../../assets/icons/more.svg"
                                                    id="{{'unitPlus'+whsprice?.b2BUnitCode}}" alt="">
                                                <img src="../../assets/icons/more-disable.svg"
                                                    id="{{'unitPlusDisable'+whsprice?.b2BUnitCode}}" class="disabled-img"
                                                    alt="">
                                            </span>
                                        </section>
                                        <section class="doses-section" id="{{'noOfDoses'+whsprice?.b2BUnitCode}}">
                                          {{productDetails?.quantityPerUnit}} {{'plp.doses'|cxTranslate}}</section>
                                    </section>
                                </section>
                            </section>
                            <section class="col-md-6 col-xs-6 bottom-spacing units-area text-right"  *ngIf="(whsprice?.value == null || whsprice?.value == undefined || whsprice?.value == undefined || whsprice?.value == 0.0) && !productDetails?.discontinued">
                                <section class="no-price"><p>{{'plp.noPrice' | cxTranslate}}</p></section>
                            </section>

                            <section class="col-md-4 col-sm-4 col-xs-12 bottom-spacing button-area"
                                *ngIf="(whsprice?.value != null && whsprice?.value != undefined || whsprice?.value != undefined && whsprice?.value != 0.0) && (!productDetails?.discontinued && productDetails?.available && !productDetails?.flu)">
                                <ng-template #popTitleSuccess>
                                  <div class="cart-value-added">
                                    <img src="../../../assets/icons/confirmation-positive-green.png"
                                         class="positive-green-icon" alt="success">
                                    <span class="confirmation-itemsadded">{{itemAddedQuantity}}
                                      {{'addToCartItems.addEntrySuccess'|cxTranslate}}</span>
                                  </div>
                                </ng-template>
                                <button class="blue-button login-btn ml10 added-success"
                                    data-di-id="di-id-2fea5a72-b2e23002" [ngbPopover]=popTitleSuccess placement="bottom"
                                    triggers="manual" #p2="ngbPopover" placement="bottom"
                                    popoverClass="pdp-itemsuccess-msg" (click)="addToCartWHSProduct(productDetails?.code, whsprice?.b2BUnitCode, p2)">
                                    <i *ngIf="buttonName === 'Reserve now'" class="fa fa-calendar"
                                        aria-hidden="true"></i>
                                    <span
                                        *ngIf="buttonName==='Add to cart'">{{'addToCartItems.addCart'|cxTranslate}}</span>
                                    <span
                                        *ngIf="buttonName==='Login to order'">{{'addToCartItems.loginToOrder'|cxTranslate}}</span>
                                    <span class="reserve-now-button"
                                        *ngIf="buttonName==='Reserve now'">{{'addToCartItems.reserveNow'|cxTranslate}}</span>
                                </button>
                            </section>
                        </section>
                    </section>

                        <hr class="line">
                        <!-- Packing List Section -->
                        <section>
                            <h3 class="cx-section-title">{{'plp.packingListTitle'|cxTranslate}}</h3>
                            <p class="desc-title">
                                {{'plp.productNoOfProdPerBox'|cxTranslate}}:
                                <span class="desc-details">{{productDetails?.numberOfProductsPerBox}}</span>
                            </p>

                            <p class="desc-title">
                                {{'plp.productNoOfProdPerPallet'|cxTranslate}}:
                                <span class="desc-details">{{productDetails?.numberOfProductsPerPallet}}</span>
                            </p>
                        </section>
                        <section class="sap-allocation-area"
                            *ngIf="!productDetails?.discontinued && productDetails?.available">
                            <section *ngIf="productDetails?.sapAllocation === true">
                                <b class="prod-allocation">{{'plp.productAllocation'|cxTranslate}}</b>
                                <p class="allocation-p">{{'plp.allocationDetails' | cxTranslate}}
                            </section>
                        </section>
                    </section>

                    <section *ngIf="productDetails?.discontinued || !productDetails?.available">
                        <section *ngIf="productDetails?.discontinued && productDetails?.available">
                            <p class="text-center product-discontinued-txt1">
                                {{'plp.discontinued'|cxTranslate}}</p>
                        </section>
                        <section *ngIf="productDetails?.discontinued && !productDetails?.available">
                            <p class="text-center product-discontinued-txt1">
                                {{'plp.discontinued'|cxTranslate}}</p>
                        </section>
                        <section *ngIf=" !productDetails?.discontinued && !productDetails?.available">
                            <section class="text-center1">
                                <b class="text-center product-discontinued-txt1"
                                    *ngIf="productDetails?.availabilityText">{{productDetails?.availabilityText}}</b>
                                <b class="text-center product-discontinued-txt1"
                                    *ngIf="!productDetails?.availabilityText">{{'plp.productUnavailable'|cxTranslate}}</b>
                            </section>
                        </section>
                    </section>

                    <section class="added-success-container">
                        <ng-template #popTitleSuccess>
                            <div class="cart-value-added">
                                <img src="../../../assets/icons/confirmation-positive-green.png"
                                    class="positive-green-icon" alt="success">
                                <span class="confirmation-itemsadded">{{itemAddedQuantity}}
                                    {{'addToCartItems.addEntrySuccess'|cxTranslate}}</span>
                            </div>
                        </ng-template>
                    </section>

                    <section class="flu-class"
                        *ngIf="productDetails.flu && productDetails?.available && !productDetails?.discontinued">
                        <section>
                            <p class="contact-info">
                                {{'plp.productAllocationInfo1'|cxTranslate}}<b>
                                    {{'plp.emergencycontact'|cxTranslate}}</b>{{'plp.productAllocationInfo2'|cxTranslate}}
                            </p>
                        </section>
                        <section class="col-md-4 col-sm-4 col-xs-12 button-area flu-button-area"
                            *ngIf="productDetails.flu && productDetails?.available && !productDetails?.discontinued">
                            <button class="blue-button login-btn ml10 flu-button" data-di-id="di-id-2fea5a72-b2e23002 "
                                [ngbPopover]=popTitleSuccess placement="bottom" triggers="manual" #p2="ngbPopover"
                                placement="bottom" (click)="addToCart(productDetails?.code, p2)">
                                <i *ngIf="buttonName === 'Reserve now'" class="fa fa-calendar" aria-hidden="true"></i>
                                <span *ngIf="buttonName==='Add to cart'">{{'addToCartItems.addCart'|cxTranslate}}</span>
                                <span
                                    *ngIf="buttonName==='Login to order'">{{'addToCartItems.loginToOrder'|cxTranslate}}</span>
                                <span class="reserve-now-button"
                                    *ngIf="buttonName==='Reserve now'">{{'addToCartItems.reserveNow'|cxTranslate}}</span>
                            </button>
                        </section>
                    </section>
                </section>

                <!-- The End -->
                <section>
                    <section *ngIf="userDetails == undefined" class="col-md-4 col-sm-4 col-xs-12 button-area">
                        <button type="button" class="btn blue-button" (click)="goToLogin()">
                            <span *ngIf="buttonName==='Add to cart'">{{'addToCartItems.addCart'|cxTranslate}}</span>
                            <span
                                *ngIf="buttonName==='Login to order'">{{'addToCartItems.loginToOrder'|cxTranslate}}</span>
                            <span class="reserve-now-button"
                                *ngIf="buttonName==='Reserve now'">{{'addToCartItems.reserveNow'|cxTranslate}}</span>
                        </button>
                    </section>

                    <section *ngIf="userDetails !== undefined">

                        <section *ngIf="!productDetails?.discontinued && productDetails?.available"
                            class="products-accordion clear-fix pt2">
                            <section class="panel-group">
                                <section class="panel panel-default" *ngIf="productDetails?.volumePrices?.length > 0">
                                    <section class="panel-heading panel-endborder">
                                        <section class="first-level-acc">
                                            <label><img src="../../assets/icons/price-master.png" class="accordion-img"
                                                    alt="">
                                                {{'plp.volumePricing'|cxTranslate}}</label>
                                            <span data-toggle="collapse" data-parent="#accordion" href="#pdp-list1"
                                                class="acc-plus-icon fa" aria-expanded="panelVolumeExpanded"
                                                (click)="panelVolumeExpanded = !panelVolumeExpanded"
                                                [ngClass]="panelVolumeExpanded?'fa-minus':'fa-plus collapsed'"></span>
                                        </section>
                                    </section>
                                    <section id="pdp-list1" class="panel-collapse collapse"
                                        aria-expanded="panelVolumeExpanded"
                                        (click)="panelVolumeExpanded = !panelVolumeExpanded"
                                        [ngClass]="panelVolumeExpanded?'in':''">
                                        <section class="panel-body">
                                            <section class="discount-list" id="vol-price-pdp">
                                                <table aria-describedby="pdpTable">
                                                    <thead>
                                                        <th id=""></th>
                                                    </thead>
                                                    <tbody
                                                        *ngFor="let prices of productDetails?.volumePrices; let i=index">
                                                        <tr>
                                                            <td>{{prices?.minQuantity}} - {{prices?.maxQuantity}}
                                                                {{'plp.units'|cxTranslate}}:
                                                            </td>
                                                            <td>{{prices?.value | localCurrency}}</td>
                                                            <td><span *ngIf="i>0"
                                                                    class="save-price">{{'plp.save'|cxTranslate}}
                                                                    {{productDetails?.volumePrices[0].value -
                                                                    productDetails?.volumePrices[i].value |
                                                                    localCurrency}}
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </section>
                                        </section>
                                    </section>
                                </section>
                            </section>
                        </section>

                    </section>
                    <section class=" products-accordion clear-fix pt2 ">
                        <section class="panel-group ">
                            <!-- update2 -->
                            <section class="panel panel-default " *ngIf="productDetails?.productUpdates ">
                                <section class="panel-heading ">
                                    <section class="first-level-acc ">
                                        <label><img src="../../assets/icons/info.png " class="accordion-img "
                                                alt=" ">{{'plp.productUpdate'|cxTranslate}}
                                        </label> <span data-toggle="collapse " data-parent="#accordion "
                                            href="#pdp-list3 " class="acc-plus-icon fa "
                                            aria-expanded="panelProductExpanded "
                                            (click)="panelProductExpanded=! panelProductExpanded "
                                            [ngClass]="panelProductExpanded? 'fa-minus': 'fa-plus collapsed' "></span>
                                    </section>
                                </section>
                                <section id="pdp-list3 " class="panel-collapse collapse "
                                    aria-expanded="panelProductExpanded "
                                    (click)="panelProductExpanded=! panelProductExpanded "
                                    [ngClass]="panelProductExpanded? 'in': '' ">
                                    <section class="panel-body productupdate-p ">
                                        {{productDetails?.productUpdates}}</section>
                                </section>
                            </section>
                        </section>
                    </section>
                </section>
            </section>
        </section>
    </section>

</section>
