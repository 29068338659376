import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import * as config from '../shared/config/app.config';
import { BaseService } from '../shared/services/base.service';
import { ExcelDownloadService } from '../shared/services/excel-download.service';

@Injectable({
  providedIn: 'root',
})
export class AccountOverviewService {
  public userAccountIdDetailsData = new Subject<any>();

  constructor(
    private readonly baseService: BaseService,
    public readonly excelService: ExcelDownloadService
  ) {}

  public getAccountId() {
    this.baseService.get(config.GET_ACCOUNT_ID).subscribe((res) => {
      if (res !== null && res !== undefined && res?.length !== 0) {
        this.userAccountIdDetailsData.next(res);
      }
    });
  }

  public downloadXls(data, name, filenameNoChange = true) {
    this.excelService.exportAsExcelFile(data, name, filenameNoChange);
  }
}
