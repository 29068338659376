import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LocationStripComponent } from './location-strip.component';
import {RouterModule} from '@angular/router';
import {I18nModule, provideConfig} from '@spartacus/core';
import {translationChunksConfig} from '@spartacus/assets';


@NgModule({
  declarations: [LocationStripComponent],
  imports: [
    CommonModule,
    RouterModule,
    I18nModule
  ],
  exports: [LocationStripComponent],
  providers: [
    provideConfig({
      i18n: {
        backend: {
          loadPath: 'assets/i18n-assets/{{lng}}/{{ns}}.json',
        },
        chunks: translationChunksConfig,
      },
    }),
  ]
})
export class LocationStripModule { }

