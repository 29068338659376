import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { translationChunksConfig } from '@spartacus/assets';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';
import { FluDashboardBannerModule } from '../shared/modules/flu-dashboard-banner/flu-dashboard-banner.module';
import { NeedAssistanceModule } from '../shared/modules/need-assistance/need-assistance.module';
import { AccountConfirmationInformationComponent } from './account-registration/account-confirmation-information/account-confirmation-information.component';
import { AccountHeaderComponent } from './account-registration/account-header/account-header.component';
import { AccountRegistrationComponent } from './account-registration/account-registration.component';
import { BillingInformationComponent } from './account-registration/billing-information/billing-information.component';
import { ContactsInformationComponent } from './account-registration/contacts-information/contacts-information.component';
import { PhoneMaskDirective } from './account-registration/phone-mask.directive';
import { PhoneMobileMaskDirective } from './account-registration/phone-mobile-mask.directive';
import { ShippingInformationComponent } from './account-registration/shipping-information/shipping-information.component';
import { EmailVerificationComponent } from './email-verification/email-verification.component';
import { AccountInformationComponent } from './user-registration/account-information/account-information.component';
import { UserConfirmationComponent } from './user-registration/user-confirmation/user-confirmation.component';
import { UserHeaderComponent } from './user-registration/user-header/user-header.component';
import { UserInformationComponent } from './user-registration/user-information/user-information.component';
import { UserRegistrationComponent } from './user-registration/user-registration.component';

const globalSettings: RecaptchaSettings = {
  siteKey: '6Lf73t8UAAAAALmqQmiI5laJTYQ1p3XrZO2xK0T9',
};
const startIndex = 6;
const totalCharsNeeded = 2;
const language = location.pathname.substr(startIndex, totalCharsNeeded);

@NgModule({
  declarations: [
    AccountRegistrationComponent,
    UserRegistrationComponent,
    BillingInformationComponent,
    ShippingInformationComponent,
    ContactsInformationComponent,
    AccountConfirmationInformationComponent,
    AccountHeaderComponent,
    PhoneMaskDirective,
    PhoneMobileMaskDirective,
    AccountInformationComponent,
    UserInformationComponent,
    UserHeaderComponent,
    UserConfirmationComponent,
    EmailVerificationComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    I18nModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgbModule,
    NeedAssistanceModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    FluDashboardBannerModule,
    ConfigModule.withConfig({
      cmsComponents: {
        RegistrationPageComponent: {
          component: AccountRegistrationComponent,
        },
        CustomerRegistrationPageComponent: {
          component: UserRegistrationComponent,
        },
        UserRegistrationPageComponent: {
          component: UserConfirmationComponent,
        },
        AccountRegistrationPageComponent: {
          component: AccountConfirmationInformationComponent,
        },
        EmailVerificationPageComponent: {
          component: EmailVerificationComponent,
        },
      },
      i18n: {
        backend: { loadPath: 'assets/i18n-assets/{{lng}}/{{ns}}.json' },
      },
      chunks: translationChunksConfig,
    } as CmsConfig),
  ],
  providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: globalSettings,
    },
  ],
  exports: [PhoneMaskDirective,PhoneMobileMaskDirective],
})
export class CustomUserRegistrationModule {}
