import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LocalCurrencyPipe } from './localcurency';
import { SafeHtmlPipe } from './safehtml';

@NgModule({
    declarations: [LocalCurrencyPipe, SafeHtmlPipe],
    imports: [CommonModule],
    exports: [LocalCurrencyPipe, SafeHtmlPipe]
})

export class MainPipeModule { }
export { SafeHtmlPipe };

