<ng-container *ngIf="isUserLoggedIn">
  <label class="searchbox" [class.dirty]="!!searchInput.value">
    <input
      #searchInput
      [ngModel]="searchValue"
      [placeholder]="'homescreen.placeholder' | cxTranslate"
      autocomplete="off"
      aria-describedby="initialDescription"
      aria-controls="results"
      aria-autocomplete="both"
      (click)="open()"
      (focus)="open()"
      (input)="search(searchInput.value)"
      (keydown.escape)="onBlur()"
      (keydown.enter)="
        close($event, true);
        launchSearchResult($event, searchInput.value);
        updateChosenWord(searchInput.value)
      "
      value="{{ chosenWord }}"
    />
  
    <button
      role="presentation"
      class="search-icon"
      [disabled]="searchInput.value.length === 0"
    >
      <cx-icon
        [type]="iconTypes.SEARCH"
        (click)="launchSearchResult($event, searchInput.value)"
      ></cx-icon>
    </button>
  </label>
  
  <div
    *ngIf="
      closeDropDown && !hideSearchHistory && !quickOrder && results$
        | async as result
    "
    class="results"
    id="results"
    role="listbox"
  >
    <ul class="products" *ngIf="result.products" tabindex="0">
      <li class="drop-down-header">
        <span class="drop-down-title">
          {{ "searchProduct.matchingProducts" | cxTranslate }}
        </span>
        <a
          class="push-right all-results-link"
          (click)="launchSearchResult($event, searchInput.value)"
          >{{ "searchProduct.seeAll" | cxTranslate }}</a
        >
      </li>
      <li *ngFor="let product of result.products">
        <a
          [class.has-media]="config.displayProductImages"
          [routerLink]="['/product', product.code, product.code]"
          (blur)="close($event)"
          (click)="close($event, true)"
        >
          <cx-media
            *ngIf="config.displayProductImages && product?.images"
            [container]="product?.images?.PRIMARY"
            format="thumbnail "
            role="presentation "
            class=""
          ></cx-media>
          <img
            *ngIf="config.displayProductImages && product?.images === undefined"
            [src]="fallbackImage"
            format="thumbnail "
            role="presentation "
            class="thumbnail-img"
            alt="defImg"
          />
          <div class="name" [innerHTML]="product.nameHtml"></div>
        </a>
      </li>
    </ul>
  </div>
  <div
    *ngIf="
      closeDropDown && hideSearchHistory && !quickOrder && value$ | async as item
    "
    role="listbox"
    class="searchHistory"
    id="searchHistory"
  >
    <ul
      class="products resetListStyle"
      tabindex="0"
      *ngIf="item.recentSearchText"
    >
      <li class="drop-down-header">
        <span class="drop-down-title history-font-size">{{
          "searchProduct.recentSearches" | cxTranslate
        }}</span>
      </li>
      <li
        class="itemHistory"
        *ngFor="let item of item.recentSearchText"
        (click)="goToSearch(item)"
      >
        <a (blur)="close($event)" (click)="close($event, true)"> {{ item }}</a>
      </li>
    </ul>
  </div>
  
  <div
    *ngIf="quickOrder && showQuickOrder && results$ | async as result"
    class="results"
    id="quickOrder"
    role="listbox"
    (blur)="onBlur()"
  >
    <ul
      class="products"
      *ngIf="result?.products?.length === 0 && result?.message != null"
      tabindex="0"
    >
      <li>
        <a class="resultnot-found">
          {{ "searchProduct.noResultsFound" | cxTranslate }}
        </a>
      </li>
    </ul>
    <ul class="products" *ngIf="result.products" tabindex="0">
      <li *ngFor="let product of result.products">
        <a>
          <div
            class="name"
            [innerHTML]="product.nameHtml"
            (click)="quickOrderClick(product)"
          ></div>
        </a>
      </li>
    </ul>
  </div>
  </ng-container>