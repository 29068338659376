<div class="container">
  <div class="row">
    <div class="col-lg-12 col-xl-12"><span
        class="orderMsg-Confirmation">{{'addToCartItems.orderUpdated'|cxTranslate}}</span></div>
    <div [ngClass]="{'col-lg-3 col-xl-3':languageUsed!='fr',
    'col-lg-2 col-xl-2':languageUsed==='fr'}">
      <span class="activeSeasonId">
        {{activeSeasonId}} {{'selectVaccines.season'|cxTranslate}}
      </span>
    </div>
    <div class="bc-Dash-Div col-lg-4" [ngClass]="{'en-div  col-xl-3 ':languageUsed!='fr',
    'offset-lg-6 offset-xl-6  col-xl-4':languageUsed==='fr'}">
      <span class="back-To-Dashbaord" (click)='backToDashBoard()'>
        <img src='assets/img/chevron-left.svg' class="svg-Images" alt="left">
        {{'selectVaccines.back_to_db'|cxTranslate}} </span>
      <span class="dlete-Cart" (click)="downloadAsExcelFlu()">
        <img src='../../assets/icons/excel.png' class="excel-Img" alt="xls">
        {{'selectVaccines.xls'|cxTranslate}}</span>
      <span class="print" (click)="OnFluPrint()">
        <img src='assets/img/print.svg' class="svg-Images" alt="print">
        {{'selectVaccines.print'|cxTranslate}}</span>
    </div>

  </div>
</div>
<!-- ==================LEFT Section=================================== -->


<section class="container influenzaCategory-Container">
  <section>
    <aside class="col-lg-4 col-md-4 col-sm-12 right-navigation float-right 
    totals-width ord-tot-prnt p0">
      <section class="container estimation-Container">
        <section class="row est-Bground">
          <section class="col-md-12 col-lg-12 col-xl-12 text-center">
            <span>{{totalProducts ? totalProducts : '' }} {{'selectVaccines.units'|cxTranslate}} | {{totalDoses ?
              totalDoses
              : ''}} {{'selectVaccines.doses'|cxTranslate}}</span>
            <div class="est-total">
              {{'orderConfirmation.estimatedTotal'|cxTranslate}} {{estTotal ? (estTotal | localCurrency): ''}}
            </div>
          </section>
        </section>
      </section>
    </aside>
    <section class="
          col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8
          border-Right
          float-left
        ">
      <section class="container">
        <section class="row">
          <section class="col-md-7 col-lg-7 col-xl-7">
            <section class="row shipping-Bill-Main-Section-Location">
              <section class="col-sm-6 col-md-6 col-lg-6 loc-left">
                <section class="ship-address">
                  <section id="location-select">
                    <section class="location-icon">
                      <span class="glyph-icon">
                        <i class="fas fa-map-marker-alt icon mr-3"></i>
                      </span>
                    </section>
                    <section class="location-Details margin-top">
                      <span class="selected-loc">{{ selectedData?.companyName }} ({{ selectedData?.unitID }})
                      </span>
                    </section>
                  </section>
                </section>
              </section>
            </section>
          </section>
          <section class="col-md-5 col-lg-5 col-xl-5">
            <section class="row marginTop-OrderRow">
              <section class="col-12 col-sm-12 col-lg-12 col-xl-12">
                <span class="order-label">{{'orderConfirmation.po'|cxTranslate}}:
                  <span *ngIf="purchaseOrderNumber else NA">{{purchaseOrderNumber}}</span>
                  <ng-template #NA>
                    WWW:{{orderDate | slice:0:10 |date:'dd/MM/yyyy'}}
                  </ng-template>
                </span>
              </section>
            </section>
          </section>
          <section class="
                col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12
                checkOut-Header-Section
              ">
            <hr />
          </section>
        </section>
      </section>
      <section class="row shipping-Bill-Main-Section">
        <!-- =============Sold and order=========================== -->
        <section class="
              col-12 col-sm-12 col-md-12 col-xl-3 col-lg-3
              shipTo-Main-Section
            ">
          <section class="row">
            <section class="col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12">
              <span class="shipTo-Header">{{'orderConfirmation.soldTo'|cxTranslate}}</span>
            </section>
            <section class="col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12">
              <span class="data"> {{ selectedData?.unitID }}</span>
            </section>
            <section class="col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12 mt-3">
              <span class="shipTo-Header">{{'orderConfirmation.orderDate'|cxTranslate}}</span>
            </section>
            <section class="col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12">
              <span class="data">{{orderDate| slice:0:10 | date:'dd/MM/yyyy'}}</span>
            </section>
            <section class="col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12 mt-3" *ngIf="estimatedDeliveryDate != ''">
              <span class="shipTo-Header">{{'orderConfirmation.reqDeliveryDate'|cxTranslate}}</span>
            </section>
            <section class="col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12" *ngIf="estimatedDeliveryDate != ''">
              <span class="data">{{estimatedDeliveryDate | slice:0:10 |date:'dd/MM/yyyy'}}</span>
            </section>
          </section>
        </section>

        <!-- =================================Shipto============================ -->
        <section class="
              col-12 col-sm-12 col-md-12 col-xl-3 col-lg-3
              shipTo-Main-Section
            ">
          <section class="row">
            <section class="col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12">
              <span class="shipTo-Header">{{'orderConfirmation.shipTo'|cxTranslate}}</span>
            </section>
            <section class="col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12">
              <span class="">{{
                selectedData?.companyName ? selectedData?.companyName : ""
                }}</span>
            </section>
            <section class="col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12">
              <span class="">{{ selectedData?.line1 ? selectedData?.line1 : "" }} ,
                {{ selectedData?.line2 ? selectedData?.line2 : "" }}</span>
            </section>
            <section class="col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12">
              <span class="">{{ selectedData?.town ? selectedData?.town : "" }} ,
                {{
                selectedData?.postalCode ? selectedData?.postalCode : ""
                }}</span>
            </section>
          </section>
        </section>

        <!-- ====================TOTAL=========================== -->
        <section class="
              col-12 col-sm-12 col-md-12 col-xl-5 col-lg-5
              total-Price-Section
            ">
          <section class="row">
            <section class="container sub-Total-Section">

              <section class="row">
                <section class="col-8 col-sm-6 col-md-6 col-xl-6 col-lg-6">
                  <span class="shipTo-Header">{{'orderConfirmation.subTotal'|cxTranslate}}</span>
                </section>
                <section class="col-4 col-sm-6 col-md-6 col-xl-6 col-lg-6">
                  <span class="float-right-check">
                    {{dataForFluConfirmation[0]?.subTotal?.value|localCurrency}}</span>
                </section>
              </section>
              <section class="row salesTax-row" *ngIf="dataForFluConfirmation[0]?.totalTax?.value > 0 ">
                <section class="col-8 col-sm-6 col-md-6 col-xl-6 col-lg-6">
                  <span>{{'orderConfirmation.salesTax'|cxTranslate}}</span>
                </section>
                <section class="col-4 col-sm-6 col-md-6 col-xl-6 col-lg-6">
                  <span class="float-right-check">
                    {{dataForFluConfirmation[0]?.totalTax?.value|localCurrency}}</span>
                </section>
              </section>


            </section>
            <!-- ======================= -->
            <section class="container total-Cost">
              <section class="row">
                <section class="col-8 col-sm-6 col-md-6 col-xl-6 col-lg-6">
                  <span
                    class="shipTo-Header total-Amount-Span">{{'orderConfirmation.totalWithSaving'|cxTranslate}}</span>
                </section>
                <section class="col-4 col-sm-6 col-md-6 col-xl-6 col-lg-6">
                  <span class="float-right-check">
                    {{dataForFluConfirmation[0]?.totalPriceWithTax?.value|localCurrency}}</span>
                </section>
              </section>
            </section>

            <!-- ======================= -->
          </section>

        </section>
        <section class="charges-Msg-Div" *ngIf="freightProduct">
          <span>{{ "addToCartItems.shippingChargesNote" | cxTranslate }} </span>
        </section>
      </section>
      <!-- ==================PRODUCT ENTRIES======================== -->

      <section class="row cart-Row" *ngFor="let productEntry of productEntries">
        <!-- ====================Image======================================== -->
        <section class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 prod-Image-Section">
          <a (click)="productDetails(productEntry)">
            <img class="prod-Image" *ngIf="productEntry.thumbNailImg" src="{{baseUrl}}{{productEntry.thumbNailImg}}"
              alt="">

            <img class="prod-Image" *ngIf="!productEntry?.thumbNailImg" [src]="fallbackImage" alt="">
          </a>
        </section>
        <!-- ====================Product Detia======================================== -->
        <section class="col-11 col-sm-11 col-md-5 col-lg-6 col-xl-5 prod-Disc-Div"
          id="{{stdCartType?'stdCart':'fluCart'}}">
          <section class="container">
            <section class="row">
              <section class="col-md-12 col-lg-12 col-xl-12">
                <a (click)="productDetails(productEntry)">
                  <h2 class="prodcut-Name" [innerHTML]="productEntry?.product?.name">

                  </h2>
                </a>
              </section>
              <section class="col-sm-12 col-md-11 col-lg-8" *ngIf="stdCartType">
                <section class="table-responsive">
                  <table class="table" aria-describedby="productsData">
                    <thead>
                      <tr>
                        <th scope="col">{{'orderConfirmation.unitsDoses'|cxTranslate}}</th>
                        <th scope="col">{{'selectVaccines.yourPrice'|cxTranslate}}</th>
                        <th scope="col">{{'orderConfirmation.subTotal'|cxTranslate}}</th>
                        <th scope="col">{{'orderConfirmation.estimatedDelivery'|cxTranslate}}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{{productEntry?.quantity}}/{{productEntry?.product?.quantityPerUnit*productEntry?.quantity}}
                        </td>
                        <td>{{productEntry?.product?.price?.value | localCurrency}}</td>
                        <td>{{productEntry?.product?.price?.value*productEntry?.quantity | localCurrency}}</td>
                        <td>{{productEntry?.estimatedDeliveryDate | slice:0:10 | date:'dd/MM/yyyy'}}</td>
                      </tr>

                    </tbody>
                  </table>
                </section>
              </section>
              <section class="col-md-12 col-lg-12 col-xl-12 product-Desc-Section">
                <p [innerHTML]="productEntry?.product?.description"></p>
              </section>
              <section class="col-md-12 col-lg-12 col-xl-12 prod-No-Section">
                {{'selectVaccines.product_number'|cxTranslate}}: <span
                  class="prdct-No">{{productEntry?.product?.code}}</span>
              </section>
            </section>
          </section>
        </section>
        <!-- ============================Price=========================================== -->
        <section class="col-8 col-sm-8 col-md-3 col-lg-3 col-xl-3 price-Main-Section" *ngIf="!stdCartType">
          <section class="container">
            <section class="row">
              <section class="col-md-12 col-lg-12 col-xl-12">
                <span class="your-Price">{{'selectVaccines.yourPrice'|cxTranslate}}</span>
              </section>
              <section class="col-md-12 col-lg-12 col-xl-12">
                <span class="product-Price">{{productEntry?.basePrice?.value | localCurrency}}</span>
              </section>
              <section class="col-md-12 col-lg-12 col-xl-12">
                <span class="est-Delivery-Date">{{'orderConfirmation.estimatedDelivery'|cxTranslate}}</span>
                <section *ngIf="productEntry?.product.onsn!=='ON'">{{'orderConfirmation.tbd'|cxTranslate}}</section>
                <section *ngIf="productEntry?.product.onsn==='ON'">{{productEntry?.estimatedDeliveryDate | slice:0:10
                  |date:'dd/MM/yyyy'}}</section>
              </section>
            </section>
          </section>
        </section>
        <!-- ==============================unites========================================== -->
        <section class=" col-4 col-sm-4 col-md-2 col-lg-2 col-xl-2 units-Main-Section" *ngIf="!stdCartType">
          <section class="container p0">
            <section class="prod-units">
              <b class="">{{'selectVaccines.units'|cxTranslate}}</b>

              <section class="text-center">
                <input class="units-Input" type="text" value="{{productEntry?.quantity}}" readonly />
              </section>
              <section class="">
                {{productEntry?.product?.quantityPerUnit*productEntry?.quantity}}
                {{'selectVaccines.doses'|cxTranslate}}</section>
            </section>
          </section>
        </section>
      </section>

      <!-- ==================//ProductEntries//======================== -->
    </section>
    <!-- ==================RIGHT Section=================================== -->
    <section class="
          col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4
          right-Section-Top-Col-Span
          float-right
        ">
      <app-flu-confirmation-right-content></app-flu-confirmation-right-content>

    </section>
  </section>
</section>