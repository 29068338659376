<section class="container-fluid accountField-ContainerFluid">

  <section class="row accnt-Div ">
    <section class="col-lg-12 col-md-12 col-sm-12 col-xs-12 preference-content">
      <form action="">
        <section class="">
          <p class="locview-info">{{'account.select'| cxTranslate}}</p>
          <section class="col-sm-7 col-md-7 col-lg-7   locleft">
            <section class="ship-address">
              <section id="location-select">
                <section class="location-icon">
                  <span class="glyph-icon">
                    <i class="fas fa-map-marker-alt icon mr-3"></i>
                  </span>
                </section>
                <section class="location-Details">
                  <span class="selected-loc">
                    {{selectedData?.companyName}} {{"("+selectedData?.unitID+")"}} </span>
                  <span class="change-loc" role="button" *ngIf="changeLocation"
                    (click)="open(content)">{{'account.changeLocation'|
                    cxTranslate}}</span>
                </section>
                <section class="custome-modal">
                  <ng-template #content let-modal>
                    <section class="modal-header">
                      <button type="button" class="close" aria-label="Close" (click)='onCancel()'
                        (click)="modal.dismiss('Cross click')">
                        <img alt="" src="../../assets/icons/close.png">
                      </button>
                      <h4 class="modal-title" id="modal-basic-title">{{'account.selectLocation'| cxTranslate}}</h4>
                    </section>
                    <section class="modal-body">

                      <section class="input-group loc-SearchBar">
                        <span class="input-group-btn">
                          <button class="btn" type="button">
                            <span class="loc-Search-Icon">

                            </span>
                          </button>
                          <input type="text" #clientName (keyup)="SearchClientName(clientName.value)"
                            class="form-control searchboxnew"
                            placeholder="{{'account.filterByLocation'| cxTranslate}}	" />
                        </span>
                      </section>
                      <hr />

                        <section class="custom-control custom-radio radio_grp  w-100 m-0">
                          <table *ngFor="let data of searchedClientList;  let i = index" aria-describedby="locTable">
                            <tr class="sample-header">
                              <th scope="col"></th>
                              <th scope="col"></th>
                            </tr>
                            <tr class="line-location"
                              [ngClass]="{'label-location': (i !== searchedClientList.length-1)}">

                              <input type="radio" (change)='onOptionsSelected(data)' [id]="data.id"
                                [checked]="selectedData.id == data.id" name="customRadio"
                                class="custom-control-input" />
                              <label class="custom-control-label secloct" [for]="data.id">
                                {{data?.companyName}} {{"("+data?.unitID+")"}}
                              </label>
                              <p class="labelpp"> {{data?.line1}}, {{data?.line2}}, {{data?.town}},
                                {{data?.region.isocodeShort}}, {{data?.postalCode}}
                              </p>

                            </tr>
                          </table>
                        </section>


                    </section>
                    <section class="modal-footer">
                      <button type="button" class="btn location-done" (click)='onSubmit()'
                        (click)="modal.close('Save click')">
                        {{'account.done'| cxTranslate}}
                      </button>
                    </section>
                  </ng-template>


                </section>
              </section>

            </section>

          </section>
        </section>
        <section class="shipping-address shipping-address-loc">
          <section class="col-xs-12 col-sm-12 col-md-12  Fields-container ">
            <section class="contact-details name-edit">
              <h3>{{'account.shippingAddress'| cxTranslate}}</h3>
              <section class="post-edit">
                <section>
                  <section class="Fields-container">
                    <label class="Fields-label">{{'account.facilityName'| cxTranslate}}</label>
                    <span>{{selectedData?.companyName || "--"}}</span>
                  </section>
                  <section class="Fields-container">
                    <label class="Fields-label">{{'account.address'| cxTranslate}}</label>
                    <span>{{selectedData?.line1 || "--"}}, {{selectedData?.line2 || "--"}}</span>
                  </section>
                  <section class="Fields-container">
                    <label class="Fields-label">{{'account.city'| cxTranslate}}</label>
                    <span>{{selectedData?.town || "--"}}</span>
                  </section>
                  <section class="Fields-container">
                    <label class="Fields-label">{{'account.province'| cxTranslate}}</label>
                    <span>{{selectedData?.region?.isocodeShort || "--"}}</span>

                  </section>
                  <section class="Fields-container">
                    <label class="Fields-label">{{'account.postalCode'| cxTranslate}}</label>
                    <span>{{selectedData?.postalCode || "--"}}</span>
                  </section>
                  <!-- <section class="Fields-container">
                    <section class="phone">
                      <section class="row">
                        <section class="col-md-4 col-lg-8 col-xl-8">
                          <label class="Fields-label">{{'account.phone'| cxTranslate}}</label>
                          <span id="phone-mask-text" maxlength="12" autocomplete="off">
                            {{selectedData?.phone || "--"}}</span>

                        </section>
                        <section class="col-md-4 col-lg-4 col-xl-4">
                          <label class="Fields-label">{{'account.extension'| cxTranslate}}</label>
                           <span> {{selectedData?.extension || "--"}}</span>
                        </section>
                      </section>
                    </section>
                  </section> -->
                  <section class="Fields-container">
                    <label class="Fields-label">{{'account.mobile'| cxTranslate}}</label>
                    <span>{{selectedData?.cellphone || "--"}}</span>
                  </section>
                  <section class="Fields-container">
                    <label class="Fields-label">{{'account.landLine'| cxTranslate}}</label>
                    <span>{{selectedData?.phone || "--"}}</span>
                  </section>
                  <section class="Fields-container">
                    <label class="Fields-label">{{'account.practiceFacility'| cxTranslate}}</label>
                    <span>{{usergetDataObj?.practiceOrFacilityType || "--"}}</span>

                  </section>
                </section>
              </section>
            </section>
          </section>
        </section>
        <hr>
        <!-- Contact Details -->
        <section class="shipping-address shipping-address-loc">
          <section class="col-xs-12 col-sm-12 col-md-12  Fields-container ">
            <section class="contact-details name-edit">
              <h3>{{'account.contactDetails'| cxTranslate}}</h3>
              <section class="post-edit"><!-- Director -->
                <h4>{{'account.directorTitle'| cxTranslate}}</h4>
                <section>
                  <section class="Fields-container">
                    <label class="Fields-label">{{'account.directorName'| cxTranslate}}</label>
                    <span>{{selectedDirectoryData?.firstName || "--"}} {{ selectedDirectoryData?.lastName || "--"}}</span>
                  </section>
                  <section class="Fields-container">
                    <label class="Fields-label">{{'account.directorEmail'| cxTranslate}}</label>
                    <span>{{selectedDirectoryData?.email || "--"}}</span>
                  </section>
                  <section class="Fields-container">
                    <label class="Fields-label">{{'account.directorPhone'| cxTranslate}}</label>
                    <span>{{selectedDirectoryData?.phone || "--"}}</span>
                  </section>
                </section>
              </section>
              <section class="post-edit"><!-- Regulatory Director -->
                <h4>{{'account.regulatoryDirectorTitle'| cxTranslate}}</h4>
                <section>
                  <section class="Fields-container">
                    <label class="Fields-label">{{'account.regulatoryDirectorName'| cxTranslate}}</label>
                    <span>{{selectedRegulatoryDirectoryData?.firstName || "--"}} {{ selectedRegulatoryDirectoryData?.lastName || "--"}}</span>
                  </section>
                  <section class="Fields-container">
                    <label class="Fields-label">{{'account.regulatoryDirectorEmail'| cxTranslate}}</label>
                    <span>{{selectedRegulatoryDirectoryData?.email || "--"}}</span>
                  </section>
                  <section class="Fields-container">
                    <label class="Fields-label">{{'account.regulatoryDirectorPhone'| cxTranslate}}</label>
                    <span>{{selectedRegulatoryDirectoryData?.phone || "--"}}</span>
                  </section>
                </section>
              </section>
              <section class="post-edit"><!-- CFO -->
                <h4>{{'account.cfoTitle'| cxTranslate}}</h4>
                <section>
                  <section class="Fields-container">
                    <label class="Fields-label">{{'account.cfoName'| cxTranslate}}</label>
                    <span>{{selectedCfoData?.firstName || "--"}} {{ selectedCfoData?.lastName || "--"}}</span>
                  </section>
                  <section class="Fields-container">
                    <label class="Fields-label">{{'account.cfoEmail'| cxTranslate}}</label>
                    <span>{{selectedCfoData?.email || "--"}}</span>
                  </section>
                  <section class="Fields-container">
                    <label class="Fields-label">{{'account.cfoPhone'| cxTranslate}}</label>
                    <span>{{selectedCfoData?.phone || "--"}}</span>
                  </section>
                </section>
              </section>
              <section class="post-edit"><!-- Primary Contact -->
                <h4>{{'account.primaryContactTitle'| cxTranslate}}</h4>
                <section>
                  <section class="Fields-container">
                    <label class="Fields-label">{{'account.primaryContactName'| cxTranslate}}</label>
                    <span>{{selectedPrimaryContactData?.firstName || "--"}} {{ selectedPrimaryContactData?.lastName || "--"}}</span>
                  </section>
                  <section class="Fields-container">
                    <label class="Fields-label">{{'account.primaryContactEmail'| cxTranslate}}</label>
                    <span>{{selectedPrimaryContactData?.email || "--"}}</span>
                  </section>
                  <section class="Fields-container">
                    <label class="Fields-label">{{'account.primaryContactPhone'| cxTranslate}}</label>
                    <span>{{selectedPrimaryContactData?.phone || "--"}}</span>
                  </section>
                </section>
              </section>
            </section>
          </section>
        </section>
      </form>
    <!-- </section> -->
  </section>

</section>
