import { Pipe, PipeTransform } from '@angular/core';
import { formatCurrency, getCurrencySymbol } from '@angular/common';
@Pipe({
    name: 'localCurrency',
})
export class LocalCurrencyPipe implements PipeTransform {
    transform(
        value: number,
        currencyCode: string = 'DZD',
        locale: string = 'ru-RU',
    ): string | null {
        const options = { style: 'currency', currency: currencyCode };
        const formatter = new Intl.NumberFormat(locale, options);
        return formatter.format(value)
    }
}