import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {BaseService} from '../../../services/base.service';
import {CustomPdpService} from "../../../../custom-pdp/custom-pdp.service";

@Injectable({
  providedIn: 'root'
})
export class SelectWhsPopupService {
  constructor(public baseService: BaseService, private readonly customPdpService: CustomPdpService) {
  }

  private enableParentSource = new BehaviorSubject<boolean>(true);
  enableParent$ = this.enableParentSource.asObservable();

  private b2bUnitsByAccountTypeSubscription = new BehaviorSubject<any>(null);
  b2bUnitsByAccountType$ = this.b2bUnitsByAccountTypeSubscription.asObservable();

  private currentCustomerSubscription = new BehaviorSubject<any>(undefined);
  currentCustomer$ = this.currentCustomerSubscription.asObservable();

  enableParent(): void {
    this.enableParentSource.next(true);
  }

  disableParent(): void {
    this.enableParentSource.next(false);
  }

  getB2BUnitsByAccountType(): any {
    this.baseService
      .get(`/b2bUnits`, {fields: 'DEFAULT'})
      .subscribe((value) => {
        if (value !== null && value !== undefined) {
          this.b2bUnitsByAccountTypeSubscription.next(value);
        }
      });
  }

  async updateWholesalerForCurrentCustomer(wholesalerId: string): Promise<void> {
    await this.baseService
      .patch(`/b2bUnits`, wholesalerId)
      .toPromise();
    this.customPdpService.scope = Math.random().toString();
  }

  fetchCurrentCustomer(): void {
    this.baseService
      .get(`/users/` + 'current')
      .subscribe((res) => {
        if (res !== null && res !== undefined) {
          this.currentCustomerSubscription.next(res);
        }
      });
  }
}
