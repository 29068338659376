<form (ngSubmit)="onSubmit()" [formGroup]="form">
    <section class="customer-login">
        <section class="home-banner">
            <h5>{{'loginDetails.customerLogin'|cxTranslate}}</h5>
        </section>
        <section class="login-details">
            <section class="col-xs-12 col-sm-12 col-md-12 fields-container p0">
                <label class="fields-label">{{'loginDetails.userName'|cxTranslate}}</label>
                <input type="text" class="fields-input" formControlName="userId" [(ngModel)]="login" (ngModelChange)="hideEmailVerificationError()">
                <cx-form-errors [control]=" form.get( 'userId' ) "></cx-form-errors>
            </section>
            <section class=" col-xs-12 col-sm-12 col-md-12 fields-container p0">
                <label class="fields-label">{{'loginDetails.password'|cxTranslate}}</label>
                <input type="password" class=" fields-input " formControlName="password" [(ngModel)]="password" (ngModelChange)="hideEmailVerificationError()">
                <cx-form-errors [control]=" form.get( 'password' ) "></cx-form-errors>
              <span *ngIf="!emailVerified" style="color: red" [innerHTML]="'loginDetails.emailNotVerified'|cxTranslate"></span>
            </section>
            <section class=" forgot-text p0">
                <button type=" submit " [disabled]=" form.disabled " class=" btn btn-default blue-button loginBtn ">
                    {{'loginDetails.login'|cxTranslate}}
                </button>
                <span><a
                    href=" javascript: void(0); " (click)='gotoLoginHelp()'>
                    {{'loginDetails.userName'|cxTranslate|lowercase}}</a></span>
                    <span class=" grey-span ">{{'loginDetails.or'|cxTranslate}}</span>
                    <span><a href=" javascript: void(0);"
                        (click)='gotoLoginHelp()'>{{'loginDetails.password'|cxTranslate|lowercase}}?</a></span>
                    <span class=" grey-span forgot-btn">{{'loginDetails.forgot'|cxTranslate}}?</span>
            </section>
            <hr>
            <section class=" new-customer p0">
                <h6>{{'loginDetails.newUserLabel'|cxTranslate}} </h6>
                <p class=" existing-user ">
                    <a [routerLink]="[ '/' , 'customer-register' ]" href=" javascript: void(0); ">{{'loginDetails.newUserExistingAccount'|cxTranslate}}</a>
                </p>
                <!-- <p><a [routerLink]="[ '/' , 'register' ]" href=" javascript: void(0); ">{{'loginDetails.newAccount'|cxTranslate}}</a></p> -->
                <p class="contact-customer">{{'loginDetails.customerService'|cxTranslate}} <a href="mailto:ContactPharmadrive@sanofi.com"> {{'loginDetails.customerServiceEmail'|cxTranslate}} </a> </p>
            </section>
        </section>
    </section>
    <section class=" Fields-btn-group col-xs-12 visible-xs mob-login new-customer p0">
        <button type=" button "  [routerLink]="[ '/' , 'login' ]" class=" btn btn-default blue-button focus " data-toggle=" modal " data-target=" #mob-Modal " tabindex=" 0 " data-di-id=" di-id-bde3b1e1-54925d6b ">{{'loginDetails.login'|cxTranslate}}</button>
        <!-- <a [routerLink]="[ '/' , 'customer-register' ]" role=" presentation " data-di-id=" di-id-f1f329d5-b2fa61f4 ">
            <button type=" button " class=" btn btn-default white-button ">{{'loginDetails.newUserExistingAccount'|cxTranslate}}</button>
        </a> -->
        <hr>
        <h6>{{'loginDetails.newUserLabel'|cxTranslate}} </h6>
        <p class="existing-user">
          <a [routerLink]="[ '/' , 'customer-register' ]" href=" javascript: void(0); ">{{'loginDetails.newUserExistingAccount'|cxTranslate}}</a>
        </p>
        <p class="contact-customer">{{'loginDetails.customerService'|cxTranslate}} <a href="mailto:ContactPharmadrive@sanofi.com"> {{'loginDetails.customerServiceEmail'|cxTranslate}} </a> </p>
    </section>
</form>
