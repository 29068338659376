<section class="container influenzaCategory-Container checkout-Container-rwd">
  <section>
    <!-- =====================estimation Section for ALL dEVICES======================= -->
    <aside class="
        col-lg-4 col-md-4 col-sm-12
        right-navigation
        float-right
        totals-width
        ord-tot-prnt
        p0
      ">
      <section class="container est-Main-Container est-checkout-Container">
        <section class="row">
          <section class="col-md-12 col-lg-12 col-xl-12 text-center unit-Doses-Header">
            <span *ngIf="!stdCartType">{{ totalInfluenzaProducts }} {{ "plp.units" | cxTranslate }} |
              {{ totalInfluenzaDoses }} {{ "plp.doses" | cxTranslate }}</span>
          </section>
          <section class="col-md-12 col-lg-12 col-xl-12 text-center order-header">
            <span class="est-Header" *ngIf="stdCartType; else fluCartOrderTotal">
              {{ "addToCartItems.orderTotal" | cxTranslate }}:
              {{ standardCartDetails?.totalPriceWithTax?.value | localCurrency }}
              {{ "plp.cad" | cxTranslate }}
            </span>
            <ng-template #fluCartOrderTotal><span class="est-Header">
                {{ "addToCartItems.estTotal" | cxTranslate }}:
                {{ standardCartDetails?.totalPriceWithTax?.value | localCurrency }}
                {{ "plp.cad" | cxTranslate }}
              </span></ng-template>
          </section>
          <section class="col-md-12 col-lg-12 col-xl-12 cnt-btn">
            <button class="btn continue-Btn " [ngClass]="agentEmulating && !uploadedFile ? 'disableCTA' :''" (click)="checkUser()">
              {{ "addToCartItems.continue" | cxTranslate }}
            </button>
          </section>
          <!-- modal for continue  -->
          <ng-template #continueCheckout class="checkout-popover">
            <section class="checkout-modal">
              <div class="modal-dialog">
                <div class="modal-content overlay">
                  <div class="modal-header overlay-heading">

                    <h4 class="modal-title checkout-title" *ngIf="stdCartType; else fluOrderHeader">
                      {{ "addToCartItems.readyToPlaceOrder" | cxTranslate }}
                    </h4>
                    <ng-template #fluOrderHeader>
                      <h4 class="modal-title flu-checkout-title">
                        {{"orderConfirmation.readyToReserve" | cxTranslate }}
                      </h4>
                    </ng-template>
                  </div>

                  <div class="modal-body text-center confirm-order">
                    <h5 *ngIf="stdCartType; else fluEstTotal">
                      {{ "addToCartItems.orderTotal" | cxTranslate }}
                    </h5>
                    <ng-template #fluEstTotal>
                      <h5>
                        {{ "addToCartItems.estTotal" | cxTranslate }}
                      </h5>
                    </ng-template>
                    <span>{{ standardCartDetails?.totalPriceWithTax?.value | localCurrency }}
                      {{ "plp.cad" | cxTranslate }}
                    </span>

                    <div class="ship-location">
                      <h6>
                        {{ "addToCartItems.shipToLocation" | cxTranslate }}
                      </h6>
                      <p>{{ selectedData?.companyName }}</p>
                      <p *ngIf="stdCartType">{{ selectedData?.line1 ? selectedData?.line1 : "" }} ,
                        {{ selectedData?.line2 ? selectedData?.line2 : "" }}
                        {{ selectedData?.town ? selectedData?.town : "" }} ,
                        {{selectedData?.region?.isocodeShort ? selectedData?.region?.isocodeShort + ',': ""}}
                        {{selectedData?.postalCode ? selectedData?.postalCode : ""}}</p>
                    </div>
                    <ul [ngClass]="stdCartType ? 'place-order' : 'place-flu-order'">
                      <li *ngIf="stdCartType; else fluReservationContact">
                        <h6>
                          {{ "addToCartItems.orderContact" | cxTranslate }}
                        </h6>
                        <p class="popupname">{{ orderContact?.name }}</p>
                      </li>
                      <ng-template #fluReservationContact>
                        <li>
                          <h6 class="">
                            {{"orderConfirmation.reservationContact" | cxTranslate }}</h6>
                          <p class="con-name">{{ orderContact?.name }} </p>
                          <p class="con-name">{{ orderContact?.fax ? orderContact?.fax : '' }}</p>
                          <a class="email-Value email-outline" *ngIf="orderContact?.email"
                            href="{{'mailto:'+orderContact?.email}}">{{orderContact?.email}}</a>
                        </li>
                      </ng-template>
                    </ul>
                    <button type="submit" class="
                        btn btn-default
                        blue-button blue_white
                        order-confirm
                      " data-toggle="modal" data-target="#loading-order" id="orderConfirmbtn"
                      data-di-id="#orderConfirmbtn" (click)="placeOrder()" *ngIf="stdCartType; else fluButton">
                      {{ "addToCartItems.placeOrder" | cxTranslate }}

                    </button>
                    <ng-template #fluButton>
                      <button type="submit" class="
                        btn btn-default
                        blue-button blue_white
                        order-confirm
                      " data-toggle="modal" data-target="#loading-order" id="orderConfirmbtn"
                        data-di-id="#orderConfirmbtn" (click)="placeOrder()">
                        {{"orderConfirmation.yesReserve" | cxTranslate }}

                      </button>
                    </ng-template>
                    <button type="button" class="btn btn-default white-button checkout-edit" data-toggle="modal"
                      data-target="#overlay" data-di-id="di-id-35119eec-5a7dc742" (click)="editOrder()"
                      *ngIf="stdCartType; else fluEditButton">
                      {{
                      "addToCartItems.editOrder" | cxTranslate
                      }}
                    </button>
                    <ng-template #fluEditButton>
                      <button type="button" class="btn btn-default white-button checkout-edit" data-toggle="modal"
                        data-target="#overlay" data-di-id="di-id-35119eec-5a7dc742" (click)="editOrder()">
                        {{"orderConfirmation.editReservation" | cxTranslate }}
                      </button>

                    </ng-template>


                  </div>
                </div>
              </div>
            </section>
          </ng-template>

          <section class="col-md-12 col-lg-12 col-xl-12 edit-btn">
            <button class="btn edit-Btn" (click)="navigateToCart()">
              {{ "addToCartItems.editCart" | cxTranslate }}
            </button>
          </section>
          <section class="col-md-12 col-lg-12 col-xl-12 text-center declaration">

            <span>
              <span>
                <app-terms-conditions compName="TandC-checkout"></app-terms-conditions>
              </span>
              <!-- <span *ngIf="stdCartType; else fluCartTerm">
                <app-terms-conditions compName="TandC-checkout"></app-terms-conditions>
              </span> -->
              <!-- <ng-template #fluCartTerm>
                <app-terms-conditions compName="TandC-fluseasonviewpayment"></app-terms-conditions>
              </ng-template> -->
            </span>
          </section>
        </section>
      </section>

      <!-- File Upload -->
      <section class="container est-Main-Container est-checkout-Container" *ngIf="agentEmulating">
        <section class="row">
          <section class="col-md-12 col-lg-12 col-xl-12 text-center">
            <app-file-upload (dataOutput)="getUploadFile($event)"></app-file-upload>
          </section>
        </section>
      </section>

    </aside>
    <!-- =====================Left Section========================= -->
    <section class="
        col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8
        border-Right
        float-left
      ">
      <section class="row">
        <section class="
            col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12 checkOut-Header-Section ">
          <!-- =====================Credit Limit Error Section========================= -->
          <div class="creditlimit_error" *ngIf="showcreditlimiterror">
            <img class ="error_warning_icon" src="../../assets/icons/warning_red.png"/>
            <span class="error_content">{{'invoiceCredit.creditlimiterror'|cxTranslate}}</span>
            <img class="error_close_icon" (click)="creditlimiterrorclose();" src="../../assets/icons/close.png" />
          </div>
          <!-- =====================Credit Limit Error Section End===================== -->
          <ng-template #fluPayment>
            <section class="checkOut-Header">
              <span class="checkOut-Header-text">{{ "addToCartItems.verifyPayment" | cxTranslate }}</span>
              <section class="float-right sm-float-left p-left-flu" *ngIf="estimatedDate!=''">
                <p class="fw-bold text_indent">
                  {{ "addToCartItems.deliveryDate" | cxTranslate
                  }}<span class="light-color fw-normal ml1">{{
                    estimatedDate | slice:0:10 | date: "dd/MM/yyyy"
                    }}</span>
                </p>
              </section>
            </section>
          </ng-template>
          <section class="firstLevel-acc checkout-heading-acc row" *ngIf="stdCartType; else fluPayment">
            <div class="light-color col-md-6 col-sm-6 col-xs-12 ">
              <ng-template #deliveryLoc>
                <img src="../../assets/icons/location.svg" class="accordion-img" alt="loc" />
                <span class="shipTo-Header">{{ selectedData?.companyName }}
                  {{ '('+selectedData?.unitID+')' }}</span>
              </ng-template>
              <div *ngIf="standardCartDetails?.pickupDate; else deliveryLoc" class="pickupLocation row">
                <div class="col-md-12">
                  <img src="../../assets/icons/location.svg" class="accordion-img" alt="loc" />
                  <span class="shipTo-Header">{{'addToCartItems.pickLocation' | cxTranslate}}</span>
                </div>
                <div class="col-md-12">
                  <p class="pad-left-30">
                    {{'addToCartItems.sanofiName'|cxTranslate}}<br>
                    {{'addToCartItems.locationLine1'|cxTranslate}}<br>
                    {{'addToCartItems.locationLine2'|cxTranslate}}
                  </p>
                </div>
              </div>
            </div>
            <ng-template #deliveryDate>
                <!-- <p class="res-Name"> -->
                  <span class="res-Name shipTo-Header">{{ "addToCartItems.deliveryDate" | cxTranslate}}:</span>&nbsp;
                  <span>{{estimatedDate | slice:0:10 | date: "dd/MM/yyyy"}}</span>
                <!-- </p> -->
            </ng-template>
            <section class="col-md-6 col-sm-6 col-xs-12 pick-up-date" *ngIf="cartContainsDirectProducts">
                <span class="res-Name shipTo-Header" *ngIf="standardCartDetails?.pickupDate; else deliveryDate">{{"addToCartItems.pickupDate" | cxTranslate}}:</span> &nbsp;
                <span>{{ standardCartDetails?.pickupDate }} {{ standardCartDetails?.pickupTime }}</span>
            </section>
          </section>
          <hr class="hideHr-Line" />
        </section>
      </section>
      <!-- ===========================Shipping Address and Total Bill======================== -->
      <section class="row shipping-Bill-Main-Section">
        <section class="
            col-12 col-sm-12 col-md-12 col-xl-3 col-lg-3
            shipTo-Main-Section
          " *ngIf="standardCartDetails?.pickupDate === undefined">
          <section class="row">
            <section class="col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12">
              <span class="shipTo-Header">{{
                "addToCartItems.shipTo" | cxTranslate
                }}</span>
            </section>
            <section class="col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12">
              <span class="">{{
                selectedData?.companyName ? selectedData?.companyName : ""
                }}</span>
            </section>
            <section class="col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12">
              <span class="">{{ selectedData?.line1 ? selectedData?.line1 : "" }} ,
                {{ selectedData?.line2 ? selectedData?.line2 : "" }}</span>
            </section>
            <section class="col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12">
              <span class="">{{ selectedData?.town ? selectedData?.town : "" }} ,
                {{
                selectedData?.region?.isocodeShort
                ? selectedData?.region?.isocodeShort
                : ""
                }}
                {{
                selectedData?.postalCode ? selectedData?.postalCode : ""
                }}</span>
            </section>
          </section>
        </section>
        <!-- ====================PO=========================== -->
        <section class="col-12 col-sm-12 col-md-12 col-xl-4 col-lg-4 po-Section-iPad">
          <section class="row">
            <section class="col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12">
              <span class="shipTo-Header">PO#</span>
              {{ "addToCartItems.optional" | cxTranslate }}
            </section>
            <section class="col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12">
              <input type="text" class="text-Field-Styling" (keyup)="orderNoUpdate()" [(ngModel)]="orderNo"
                maxlength="20" />
            </section>
          </section>
        </section>
        <div class="col-sm-12 col-md-5 col-lg-4 col-xs-12 emptySection" *ngIf="standardCartDetails?.pickupDate">
          <div class="form-group">
            <div class="form-group"></div>
          </div>
        </div>
        <!-- ====================TOTAL=========================== -->
        <section class="
            col-12 col-sm-12 col-md-12 col-xl-5 col-lg-5
            total-Price-Section
          ">
          <section class="row">
            <section class="container sub-Total-Section">
              <section class="row">
                <section class="col-8 col-sm-8 col-md-6 col-xl-6 col-lg-6">
                  <span class="shipTo-Header">{{
                    "addToCartItems.subtotal" | cxTranslate
                    }}</span>
                </section>
                <section class="col-4 col-sm-4 col-md-6 col-xl-6 col-lg-6">
                  <span class="float-right-check">
                    {{ standardCartDetails?.subTotal?.value | localCurrency }}
                    {{ "plp.cad" | cxTranslate }}
                  </span>
                </section>
              </section>
            </section>

            <!-- ======================= -->


            <!-- ======================= -->
            <section class="container sub-Total-Section" *ngIf="
                standardCartDetails?.totalTax?.value > 0
              ">
              <section class="row">
                <section class="col-8 col-sm-8 col-md-6 col-xl-6 col-lg-6">
                  <span class="">{{
                    "addToCartItems.salesTax" | cxTranslate
                    }}</span>
                </section>
                <section class="col-4 col-sm-4 col-md-6 col-xl-6 col-lg-6">
                  <span class="float-right-check">
                    {{ standardCartDetails?.totalTax?.value | localCurrency }}
                    {{ "plp.cad" | cxTranslate }}</span>
                </section>
              </section>
            </section>

            <!-- ======================= -->
            <section class="container total-Cost">
              <section class="row">
                <section class="col-8 col-sm-8 col-md-6 col-xl-6 col-lg-6">
                  <span class="shipTo-Header total-Amount-Span" *ngIf="stdCartType; else fluSavings">{{
                    "addToCartItems.total" | cxTranslate }}</span>
                  <ng-template #fluSavings>
                    <span class="shipTo-Header total-Amount-Span">
                      {{ "addToCartItems.total" | cxTranslate }}</span>
                  </ng-template>
                </section>
                <section class="col-4 col-sm-4 col-md-6 col-xl-6 col-lg-6">
                  <span class="float-right-check">
                    {{
                    standardCartDetails?.totalPriceWithTax?.value | localCurrency
                    }}
                    {{ "plp.cad" | cxTranslate }}</span>
                </section>
              </section>
            </section>

            <!-- ======================= -->
          </section>
        </section>
        <section class="charges-Msg-Div" *ngIf="freightProduct">
          <span>{{ "addToCartItems.shippingChargesNote" | cxTranslate }} </span>
        </section>
      </section>

      <!-- ====================================cart items==================================================== -->
      <section class="row cart-Row" *ngFor="let productEntry of productEntries">
        <!-- ====================Image======================================== -->
        <section class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 prod-Image-Section">
          <a (click)="productDetails(productEntry)">
            <img class="prod-Image" *ngIf="productEntry?.PRIMARY !== undefined; else undefinedImg"
              src="{{ productEntry?.PRIMARY?.url }}" alt="" />
            <ng-template #undefinedImg>
              <img class="prod-Image" [src]="fallbackImage" alt="" />
            </ng-template>
          </a>
        </section>
        <!-- ====================Product Detia======================================== -->
        <section class="col-11 col-sm-11 col-md-5 col-lg-6 col-xl-5 prod-Disc-Div"
          id="{{ stdCartType ? 'stdCart' : 'fluCart' }}">
          <section class="container">
            <section class="row">
              <section class="col-md-12 col-lg-12 col-xl-12">
                <a (click)="productDetails(productEntry)">
                  <h2 class="prodcut-Name" [innerHTML]="productEntry?.product?.name">

                  </h2>
                </a>
              </section>
              <section class="col-sm-12 col-md-11 col-lg-8 table-prod-details" *ngIf="stdCartType">
                <section class="table-responsive">
                  <table class="table" aria-describedby="productTable">
                    <thead>
                      <tr>
                        <th scope="col">
                          {{ "plp.units" | cxTranslate }}/{{
                          "plp.doses" | cxTranslate
                          }}
                        </th>
                        <th scope="col">{{ "plp.yourPrice" | cxTranslate }}</th>

                        <th scope="col">{{ "addToCartItems.subtotal" | cxTranslate }}</th>
                        <th *ngIf="!productEntry?.wholesalerProduct" scope="col">
                          {{ "addToCartItems.estDeliveryDate" | cxTranslate }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {{ productEntry?.quantity }}/{{
                          productEntry?.product?.quantityPerUnit *
                          productEntry?.quantity
                          }}
                        </td>
                        <td>
                          {{ productEntry?.basePrice?.value | localCurrency }}
                          {{ "plp.cad" | cxTranslate }}
                        </td>
                        <td>
                          {{ productEntry?.totalPrice?.value | localCurrency }}
                          {{ "plp.cad" | cxTranslate }}
                        </td>
                        <td *ngIf="sessionStorage.getItem('accountType') !== 'INDIRECT'">
                          {{
                          productEntry?.estimatedDeliveryDate | slice:0:10
                          | date: "dd/MM/yyyy"
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </section>
              </section>
              <section class="col-md-12 col-lg-12 col-xl-12 product-Desc-Section">
                <p [innerHTML]="productEntry?.product?.description"></p>
              </section>
              <section class="col-md-12 col-lg-12 col-xl-12 prod-No-Section">
                {{ "plp.productNumber" | cxTranslate }}:
                <span class="prdct-No">{{ productEntry?.product?.code }}</span>
              </section>
              <section *ngIf="!!productEntry?.wholesalerDisplayName" class="col-md-12 col-lg-12 col-xl-12 prod-No-Section">
                {{ "plp.soldBy" | cxTranslate }}
                <span class="prdct-No">{{ productEntry?.wholesalerDisplayName }}</span>
              </section>
            </section>
          </section>
        </section>
        <!-- ============================Price=========================================== -->
        <section class="col-8 col-sm-8 col-md-3 col-lg-3 col-xl-3 price-Main-Section" *ngIf="!stdCartType">
          <section class="container">
            <section class="row">
              <section class="col-md-12 col-lg-12 col-xl-12">
                <span class="your-Price">{{
                  "plp.yourPrice" | cxTranslate
                  }}</span>
              </section>
              <section class="col-md-12 col-lg-12 col-xl-12">
                <span class="product-Price">{{ productEntry?.basePrice?.value | localCurrency }}
                  {{ "plp.cad" | cxTranslate }}</span>
              </section>
              <section class="col-md-12 col-lg-12 col-xl-12">
                <span class="est-Delivery-Date">{{
                  "addToCartItems.estDeliveryDate" | cxTranslate
                  }}</span>

                <section *ngIf="productEntry?.product.onsn!=='ON'">{{'orderConfirmation.tbd'|cxTranslate}}</section>
                <section *ngIf="productEntry?.product.onsn==='ON'">{{productEntry?.estimatedDeliveryDate | slice:0:10
                  |date:'dd/MM/yyyy'}}</section>
              </section>
            </section>
          </section>
        </section>
        <!-- ==============================unites========================================== -->
        <section class="col-4 col-sm-4 col-md-2 col-lg-2 col-xl-2 units-Main-Section" *ngIf="!stdCartType">
          <section class="container">
            <section class="row">
              <section class="
                  col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11
                  units-Section
                ">
                <span class="units">{{ "plp.units" | cxTranslate }}</span>
              </section>
              <section class="col-md-12 col-lg-12 col-xl-12">
                <input class="unit-Input" type="text" value="{{ productEntry?.quantity }}" readonly />
              </section>
              <section class="col-md-12 col-lg-12 col-xl-12 doses-Section-Div">
                <section class="doses-Section">
                  {{
                  productEntry?.product?.quantityPerUnit *
                  productEntry?.quantity
                  }}
                  Doses
                </section>
              </section>
            </section>
          </section>
        </section>
      </section>
    </section>
    <!-- =====================Right Section========================= -->
    <section class="
        col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4
        right-Section-Top-Col-Span
        float-right
      ">

      <!-- =====================order Summary ======================= -->
      <section class="container reservation-Container">
        <section class="row res-Contact">
          <div *ngIf="!stdCartType">
            <h6 [ngbPopover]="popContent" [autoClose]="false" #myPopover="ngbPopover" placement="top">

            </h6>
            <ng-template #popContent>
              <span>{{"orderConfirmation.resCheckMsg" | cxTranslate }}</span>
              <div class="text-center">
                <a class="dismissStyle" (click)="myPopover.close();">{{"orderConfirmation.dismiss" | cxTranslate }}</a>
              </div>
            </ng-template>
          </div>
          <section class="col-md-12 col-lg-12 col-xl-12">
            <section *ngIf="stdCartType; else fluCartContact" class="row">
              <section class="col-8 col-sm-8 col-md-8 order-Contact">
                {{ "addToCartItems.orderContact" | cxTranslate }}
              </section>
              <section class="col-3 col-sm-3 col-md-3 p0" *ngIf="sessionStorage.getItem('accountType') !== 'INDIRECT'">
                <img alt="" src="/assets/icons/editIcon.png" class="edit-Icon-Img" *ngIf="editNotClicked" />
                <span class="edit-Click" (click)="editContact()" *ngIf="editNotClicked">
                  {{ "addToCartItems.edit" | cxTranslate }}
                </span>
              </section>
            </section>
            <ng-template #fluCartContact><span class="payment-Method-Header">{{
                "addToCartItems.reservationContact" | cxTranslate
                }}</span></ng-template>
          </section>
          <section class="col-md-12 col-lg-12 col-xl-12"></section>
          <section class="col-md-12 col-lg-12 col-xl-12 reservation-Header-Msg-Div" *ngIf="!stdCartType">
            <span class="reservation-Header-Msg">{{
              "selectVaccines.contactMsg" | cxTranslate
              }}</span>
          </section>
        </section>
        <!-- ================================Contact Details====================== -->
        <section class="container pad-0" *ngIf="!editNotClicked" [formGroup]="editStdForm">
          <section class="row">
            <section class="col-md-12">
              <label class="edit-label">{{
                "addToCartItems.name" | cxTranslate
                }}</label>
            </section>
            <section class="col-md-12">
              <input class="form-control edit-Form-Styling" type="text"
                onkeypress="return /[a-zA-Z0-9-' ]+('[a-zA-Z0-9-])?[-a-zA-Z0-9]*$/i.test(event.key)"
                formControlName="firstName" [ngClass]="{
                  invalidClass:
                    editStdForm.get('firstName').invalid &&
                    (editStdForm.get('firstName').touched ||
                      editStdForm.get('firstName').dirty)
                }" />
              <span class="txt-error" *ngIf="
                  !editStdForm.get('firstName').value
                ">{{ "addToCartItems.fieldReq" | cxTranslate }}</span>
              <span class="txt-error" *ngIf="
                  (editStdForm.get('firstName').dirty &&
                  editStdForm.get('firstName').invalid) && editStdForm.get('firstName').value
                ">{{ "addToCartItems.invalidName" | cxTranslate }}</span>
            </section>
            <section class="col-md-12">
              <label class="edit-label">{{
                "addToCartItems.mobile" | cxTranslate
                }}</label>
            </section>
            <section class="col-md-12">
              <!-- <input class="form-control edit-Form-Styling" onkeypress="return /[0-9-]/i.test(event.key)" maxlength="13"
                type="text" appPhoneMobileMask placeholder="xxxx-xx-xx-xx" formControlName="mobile" [ngClass]="{
                  invalidClass:
                    editStdForm.get('mobile').invalid &&
                    (editStdForm.get('mobile').touched ||
                      editStdForm.get('mobile').dirty)
                }" /> -->
                <input class="form-control edit-Form-Styling" onkeypress="return /[0-9-]/i.test(event.key)" maxlength="10"
                type="text"  placeholder="xxxxxxxxxx" formControlName="mobile" [ngClass]="{
                  invalidClass:
                    editStdForm.get('mobile').invalid &&
                    (editStdForm.get('mobile').touched ||
                      editStdForm.get('mobile').dirty)
                }" />
              <span class="txt-error" *ngIf="
                  (editStdForm.get('mobile').dirty &&
                  editStdForm.get('mobile').invalid) && editStdForm.get('mobile').value
                ">{{ "addToCartItems.invalidPhoneno" | cxTranslate }}</span>
              <span class="txt-error" *ngIf="
                  !editStdForm.get('mobile').value
                ">{{ "addToCartItems.fieldReq" | cxTranslate }}</span>
            </section>
            <section class="col-md-12">
              <label class="edit-label">{{
                "addToCartItems.landline" | cxTranslate
                }}</label>
            </section>
            <section class="col-md-12">
              <input class="form-control edit-Form-Styling" onkeypress="return /[0-9-]/i.test(event.key)" maxlength="10"
                placeholder="xxxxxxxxxx" type="text" formControlName="phone" [ngClass]="{
                  invalidClass:
                    editStdForm.get('phone').invalid &&
                    (editStdForm.get('phone').touched ||
                      editStdForm.get('phone').dirty)
                }" />
              <!-- <input class="form-control edit-Form-Styling" onkeypress="return /[0-9-]/i.test(event.key)" maxlength="12"
                placeholder="xxx-xx-xx-xx" appPhoneMobileMask type="text" formControlName="phone" [ngClass]="{
                  invalidClass:
                    editStdForm.get('phone').invalid &&
                    (editStdForm.get('phone').touched ||
                      editStdForm.get('phone').dirty)
                }" /> -->
              <span class="txt-error" *ngIf="
                  editStdForm.get('phone').dirty &&
                  editStdForm.get('phone').invalid && editStdForm.get('phone').value
                ">{{ "addToCartItems.invalidPhoneno" | cxTranslate }}</span>
            </section>
            <section class="col-md-12">
              <label class="edit-label">{{
                "addToCartItems.email" | cxTranslate
                }}</label>
            </section>
            <section class="col-md-12 emails-ngSelect-Div">
              <ng-select class="w-100 email-Styling" [items]="emailsArray" bindLabel="emailId" bindValue="emailId"
                [multiple]="false" formControlName="emailId" [clearable]="false" [(ngModel)]="chosenEmail">
              </ng-select>
            </section>
            <section class="col-md-12 add-Email" *ngIf="chosenEmail === 'Add Other E-mail Address'">
              <input class="form-control edit-Form-Styling" type="email" formControlName="newemailId" maxlength="241"
                [ngClass]="{
                  invalidClass:
                    editStdForm.get('newemailId').invalid &&
                    (editStdForm.get('newemailId').touched ||
                      editStdForm.get('newemailId').dirty)
                }" />
              <span class="trash-div">
                <i class="fas fa-trash-alt pointer trash-align" (click)="delEmail()"></i>
              </span>
              <span class="txt-error" *ngIf="
                  editStdForm.get('newemailId').invalid &&
                  (editStdForm.get('newemailId').dirty ||
                    editStdForm.get('newemailId').touched) &&
                  editStdForm.get('newemailId').value !==0
                ">
                You have entered an invalid email address
              </span>
            </section>

            <section class="col-md-12">
              <button (click)="onCancelClick()" class="col-5 col-sm-5 col-md-4 btn cancel-Btn">
                {{ "addToCartItems.cancel" | cxTranslate }}
              </button>
              <button (click)="updateOrderContact()" class="col-5 col-sm-5 col-md-4 btn save-Btun">
                {{ "addToCartItems.save" | cxTranslate }}
              </button>
            </section>
          </section>
        </section>
        <!-- edit contat form end-->
        <section class="row contact-Main-Row" *ngIf="editNotClicked">
          <section class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 resName-Div">
            <span class="res-Name">{{
              "addToCartItems.name" | cxTranslate
              }}</span>
          </section>
          <section class="col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8 resName-Value-Div">
            <span class="">{{ orderContact?.name }}</span>
          </section>
          <section class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 resPhone-Div" *ngIf="stdCartType">
            <span class="res-Phone">{{
              "addToCartItems.mobile" | cxTranslate
              }}</span>
          </section>
          <section class="col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8 resPhone-Value-Div" *ngIf="stdCartType">
            <span class="">{{
              orderContact?.mobileNo ? orderContact?.mobileNo : ""
              }}</span>
          </section>
          <section class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 resPhone-Div"
            *ngIf="!stdCartType && orderContact?.fax">
            <span class="res-Phone">{{
              "selectVaccines.fax" | cxTranslate
              }}</span>
          </section>
          <section class="col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8 resPhone-Value-Div"
            *ngIf="!stdCartType && orderContact?.fax">
            <span class="">{{orderContact?.fax}}</span>
          </section>
          <section class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 resPhone-Div" *ngIf="orderContact?.mobileNo">
            <span class="res-Phone">{{
              "addToCartItems.landline" | cxTranslate
              }}</span>
          </section>
          <section class="col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8 resPhone-Value-Div" *ngIf="orderContact?.mobileNo">
            <span class="">{{ orderContact?.phoneNo }}</span>
          </section>
          <section class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 resEmail-Div">
            <span class="res-Email">{{
              "addToCartItems.email" | cxTranslate
              }}</span>
          </section>
          <section class="col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8 resEmail-Value-Div" *ngIf="stdCartType">
            <a href="{{'mailto:'+orderContact?.email}}" class="email-Value">{{ orderContact?.email }}</a>
          </section>
          <section class="col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8 resEmail-Value-Div" *ngIf="!stdCartType">
            <a href="{{'mailto:'+(orderContact?.email ? orderContact?.email : userEmail )}}" class="email-Value">{{
              orderContact?.email ? orderContact?.email : userEmail}}</a>
          </section>
          <section class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 resName-Div" *ngIf="!stdCartType">
            <span class="res-Name">{{
              "selectVaccines.placedBy" | cxTranslate
              }}</span>
          </section>
          <section class="col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8 resName-Value-Div" *ngIf="!stdCartType">
            <span class="">{{userFname ? userFname : '' }} {{userLname ? userLname : ''}}</span>
          </section>
        </section>
        <!-- ================================Address Details====================== -->
        <section class="row">
          <section class="col-md-6 col-lg-12 col-xl-6 billing-Main-Div">
            <section class="container">
              <section class="row">
                <section class="col-md-12 col-lg-12 col-xl-12 billing-Address-Div">
                  <span class="billing-Address-Header">{{
                    "addToCartItems.billingAddress" | cxTranslate
                    }}</span>
                </section>
                <section class="col-md-12 col-lg-12 col-xl-12">
                  <span>{{ "addToCartItems.accountNo" | cxTranslate }}:
                    {{ standardCartDetails?.billingAddress?.unitID ?
                    standardCartDetails?.billingAddress?.unitID : "" }}</span>
                </section>
                <section class="col-md-12 col-lg-12 col-xl-12">
                  <span>{{ standardCartDetails?.billingAddress?.companyName ?
                    standardCartDetails?.billingAddress?.companyName : "" }}</span>
                </section>
                <section class="col-md-12 col-lg-12 col-xl-12">
                  <span>{{ standardCartDetails?.billingAddress?.line1 ?
                    standardCartDetails?.billingAddress?.line1 : "" }},
                    {{ standardCartDetails?.billingAddress?.line2 ?
                    standardCartDetails?.billingAddress?.line2 : "" }}</span>
                </section>
                <section class="col-md-12 col-lg-12 col-xl-12">
                  <span>{{ standardCartDetails?.billingAddress?.town ?
                    standardCartDetails?.billingAddress?.town : "" }},
                    {{
                    standardCartDetails?.billingAddress?.region?.isocodeShort
                    ? standardCartDetails?.billingAddress?.region?.isocodeShort
                    : ""
                    }}
                    {{
                    standardCartDetails?.billingAddress?.postalCode ?
                    standardCartDetails?.billingAddress?.postalCode : ""
                    }}</span>
                </section>
              </section>
            </section>
          </section>
          <section class="col-md-6 col-lg-12 col-xl-6 soldTo-Main-Div">
            <section class="container">
              <section class="row">
                <section class="col-md-12 col-lg-12 col-xl-12 sold-Address-Div">
                  <span class="sold-Address-Header">{{
                    "addToCartItems.soldToAddress" | cxTranslate
                    }}</span>
                </section>
                <section class="col-md-12 col-lg-12 col-xl-12">
                  <span>{{ "addToCartItems.accountNo" | cxTranslate }}:
                    {{ standardCartDetails?.soldtoAddress?.unitID ?
                    standardCartDetails?.soldtoAddress?.unitID : "" }}</span>
                </section>
                <section class="col-md-12 col-lg-12 col-xl-12">
                  <span>{{ standardCartDetails?.soldtoAddress?.companyName ?
                    standardCartDetails?.soldtoAddress?.companyName : "" }}</span>
                </section>
                <section class="col-md-12 col-lg-12 col-xl-12">
                  <span>{{ standardCartDetails?.soldtoAddress?.line1 ?
                    standardCartDetails?.soldtoAddress?.line1 : "" }},
                    {{ standardCartDetails?.soldtoAddress?.line2 ?
                    standardCartDetails?.soldtoAddress?.line2 : "" }}</span>
                </section>
                <section class="col-md-12 col-lg-12 col-xl-12">
                  <span>{{ standardCartDetails?.soldtoAddress?.town ?
                    standardCartDetails?.soldtoAddress?.town : "" }},
                    {{
                    standardCartDetails?.soldtoAddress?.region?.isocodeShort
                    ? standardCartDetails?.soldtoAddress?.region?.isocodeShort
                    : ""
                    }}
                    {{
                    standardCartDetails?.soldtoAddress?.postalCode ?
                    standardCartDetails?.soldtoAddress?.postalCode : ""
                    }}</span>
                </section>
              </section>
            </section>
          </section>
          <section class="col-md-12 manage-Billing-Div">
            <a target="_blank" (click)="billingAccount()">
              <span class="manage-Billing-info">{{
                "addToCartItems.billingInfo" | cxTranslate
                }}</span>
            </a>
          </section>
        </section>
      </section>
    </section>
  </section>
</section>
