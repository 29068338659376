import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { OccEndpointsService, RoutingService, TranslationService } from '@spartacus/core';
import { Subscription } from 'rxjs';
import * as _ from 'underscore';
import { CheckoutService } from '../../shared/modules/checkout/checkout.service';
import { CustomAddToCartService } from '../custom-add-to-cart.service';
import { ProductDetailsComponent } from '../custom-add-to-cart/product-details/product-details.component';

@Component({
  selector: 'app-standard-cart-confirmation',
  templateUrl: './standard-cart-confirmation.component.html',
  styleUrls: ['./standard-cart-confirmation.component.scss']
})
export class StandardCartConfirmationComponent implements OnInit,OnDestroy {
  @ViewChild('p2') public savePopover: NgbPopover;
  @ViewChild('p3') public savePopover2: NgbPopover;
orderConfirmationDetails:any;
sessionStorage = sessionStorage;
accountType: string = sessionStorage.getItem('accountType');
showDisclaimer = false;
screenwidth:any;
productEntries: any = [];
estimatedDate:any;
baseUrl: string;
fallbackImage = '../../assets/i18n-assets/sanofi_missing_product.jpg';
public images: any;
getuserData: any;
selectedData:any;
saveCartName:string = 'GENMED';
errorMsg:string;
cartId:string;
showCard:any;
cardTrans:any;
orderDetailsSub:Subscription;
invalidNameSub:Subscription;
saveOrderSub:Subscription;
showOrderNumber: boolean = false;
totalPrice: string;
ordersContainWholesalerProducts = false;

  constructor(private readonly checkoutService:CheckoutService,
    private readonly router: RoutingService,
    private readonly occEndPoint: OccEndpointsService,
    private readonly addToCart:CustomAddToCartService,
    private readonly cdr: ChangeDetectorRef,
    public datepipe: DatePipe,
    private readonly modalService: NgbModal,
    private readonly translation: TranslationService
    ) {
      this.screenwidth = window.screen.width;
    }

  closeDisclaimerAlert() {
    this.showDisclaimer = false;
  }

  ngOnInit(): void {
    this.baseUrl = this.occEndPoint.getBaseUrl({
      baseUrl: true,
      prefix: false,
      baseSite: false,
    });
    this.translation.translate('addToCartItems.card').subscribe((res)=>{
      this.cardTrans=res;
    });
     this.orderDetailsSub=this.checkoutService.updatedStdOrderDetails$.subscribe((res)=>{
       if(res!= undefined){
        this.orderConfirmationDetails=res;
        this.productEntries=[];
        this.showCard='';
        if (this.orderConfirmationDetails.orders.length > 1) {
          this.showOrderNumber = true;
        }
        for (let order of this.orderConfirmationDetails.orders) {
          this.saveCartName = order.shippingAddress?.companyName;
          for (let entries of order.entries) {
            this.estimatedDate = entries?.estimatedDeliveryDate;
            const imagesFormat = _.clone(
              _.findWhere(entries.product?.images, { format: 'thumbnail' })
            );
            if (imagesFormat){
              imagesFormat.url = `${this.baseUrl}${imagesFormat?.url}`;
            }
            this.images = { imgPrimary: imagesFormat };
            // entries = { ...this.images };
            let poNumber;
            if (order.purchaseOrderNumber==='') {
              poNumber = `WWW:${this.datepipe.transform(order?.created,'dd/MM/yyyy')}`;
            } else {
              poNumber = order.purchaseOrderNumber;
            }
            if (!this.ordersContainWholesalerProducts && !!entries?.wholesalerProduct) {
              this.ordersContainWholesalerProducts = true;
              this.showDisclaimer = true;
            }
            if (!this.ordersContainWholesalerProducts) {
              this.productEntries.push(_.object(['Customer', 'Material ID', 'Product Name', 'Quantity', 'PO Number'], [order?.soldtoAddress?.unitID, entries?.product?.code, entries?.product?.name, entries?.quantity, poNumber]));
            } else {
              this.productEntries.push(_.object(['Customer', 'Material ID', 'Product Name', 'Quantity', 'PO Number', 'WHS'], [order?.soldtoAddress?.unitID, entries?.product?.code, entries?.product?.name, entries?.quantity, poNumber, entries?.wholesalerDisplayName]));
            }
          }
          if(order?.paymentInfo){
            this.showCard=this.orderConfirmationDetails?.paymentInfo?.cardNumber.substring(6,10);
          }
        }
      }
    });
    this.cdr.detectChanges();
  }
  billingAccount(): void {
    this.router.goByUrl('/my-account/profile');
    this.checkoutService.tabSelection('Account information');
  }
  toggle(popover,index,singleCancel) {
    if(!singleCancel) {
      if (popover.isOpen()) {
        popover.close();
      } else {
        popover.open();
      }
    } else {
      this.savePopover.close();
    }
    this.errorMsg='';
    this.saveCartName=this.orderConfirmationDetails?.orders[index].shippingAddress?.companyName;
  }

  saveOrder(pnum,multi,orderCode,index): void {
    const reg = /^[^`!@#~$%\^&*()_+\-={};':"\\|[\]\\.,<>/\/?~]*$/;
    if (this.saveCartName && RegExp(reg).test(this.saveCartName)) {
      this.addToCart.saveOrderDetails(this.saveCartName,orderCode);
      this.invalidNameSub=this.addToCart.invalidOrderName$.subscribe((res)=>{
        if(res){
          for(const error of res?.error?.errors){
            this.errorMsg=error?.message;
          }
        }
      });
      this.saveOrderSub=this.addToCart.saveOrderSuccess$.subscribe((res)=>{
        if(res){
          if (multi) {
            if(this.savePopover2){
              this.savePopover2.close();
              pnum.close();
            }
          } else {
            if(this.savePopover){
              this.savePopover.close();
            }
          }
          this.errorMsg='';
          this.saveCartName=this.orderConfirmationDetails?.orders[index].shippingAddress?.companyName;
        }
      });
    } else {
      if(this.saveCartName){
        this.errorMsg= 'Save cart name is not valid.';
      } else {
        this.errorMsg = 'Cart name cannot be blank';
      }
    }
  }
  exportXls() {
    // const cartProducts=[];
    // this.productEntries.forEach( (entries,i) => {
    //   cartProducts.push({
    //     'Customer':this.orderConfirmationDetails?.orders[i]?.soldtoAddress?.unitID,
    //     'Material ID':entries?.product?.code,
    //     'Quantity':entries?.quantity,
    //     'PO Number':`WWW:${this.datepipe.transform(this.orderConfirmationDetails?.orders[i]?.created,'dd/MM/yyyy')}`
    //   });
    // });
    // for(const entries of this.productEntries){
    //   cartProducts.push({
    //     'Customer':this.orderConfirmationDetails?.soldtoAddress?.unitID,
    //     'Material ID':entries?.product?.code,
    //     'Quantity':entries?.quantity,
    //     'PO Number':`WWW:${this.datepipe.transform(this.orderConfirmationDetails?.created,'dd/MM/yyyy')}`
    //   });
    // }
    this.addToCart.downloadXls(this.productEntries, 'Order confirmation');
  }
  omit_special_char(event) {
    this.errorMsg='';
    const reg = /^[^`@~$%\^&+={}|[\]\\<>/]*$/;
    return new RegExp(reg).test(event.key);
  }
  productDetails(productDetails): void {
    const modalRef = this.modalService.open(ProductDetailsComponent, {
      windowClass: 'product-details-modal',
    });
    modalRef.componentInstance.productDetails = productDetails;
    modalRef.componentInstance.standardCartCode = '';
    modalRef.componentInstance.unitSection = 'NoUnits';
  }
  ngOnDestroy() {
    this.saveOrderSub?.unsubscribe();
    this.invalidNameSub?.unsubscribe();
    this.orderDetailsSub?.unsubscribe();
  }
}
