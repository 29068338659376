<div class="acc-order-details">
  <div class="row account-overview-header">
    <div class="col-xs-4 col-sm-5 col-md-7">
      <h3 class="orders">{{ "accountOrders.title" | cxTranslate }}</h3>
    </div>
  </div>
  <app-loader *ngIf="!isLoadingDone"></app-loader>
  <div
    class="text-center no-orders"
    *ngIf="!!ordersList && ordersList.length === 0 && isLoadingDone"
  >
    {{ "accountOrders.no_orders_found" | cxTranslate }}
  </div>

  <section
    class="payment-section"
    *ngIf="!!ordersList && ordersList.length > 0 && isLoadingDone"
  >
    <!--table starts-->
    <table
      aria-describedby="creditsTable"
      id="applyCreditsTable"
      class="credits-table table table-striped"
    >
      <thead>
        <tr>
          <th
            scope="col"
            (click)="sortColumn($event, 'placed', ordersList, 'Date')"
          >
            {{ "accountOrders.date_label" | cxTranslate }}&nbsp;
            <i class="my-icon fas fa-caret-down"></i>
          </th>
          <th
            scope="col"
            (click)="sortColumn($event, 'code', ordersList, 'normal')"
          >
            {{ "accountOrders.order_no_label" | cxTranslate }}
            <i class="my-icon fas fa-caret-down"></i>
          </th>
          <th
            scope="col"
            (click)="sortColumn($event, 'status', ordersList, 'normal')"
          >
            {{ "accountOrders.order_status_label" | cxTranslate }}
            <i class="my-icon fas fa-caret-down"></i>
          </th>
          <th
            scope="col"
            (click)="
              sortColumn($event, 'purchaseOrderNumber', ordersList, 'normal')
            "
          >
            {{ "accountOrders.order_po_num_label" | cxTranslate }}
            <i class="my-icon fas fa-caret-down"></i>
          </th>
          <th
            scope="col"
            (click)="sortColumn($event, 'shipToId', ordersList, 'normal')"
          >
            {{ "accountOrders.order_ship_to_label" | cxTranslate }}
            <i class="my-icon fas fa-caret-down"></i>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of ordersList; let i = index">
          <td>
            <span>{{ data.placedDateObj | date: "dd/MM/yyyy" }}</span>
          </td>
          <td>
            <a
              [routerLink]="['/my-account/order', data.guid]"
              href="javascript: void(0);"
            >
              {{ data.guid }}</a
            >
          </td>
          <td class="status-txt">
            <span>{{ data.statusDisplay }} </span>
          </td>
          <td>
            <span class="po-size">{{ data.purchaseOrderNumber }}</span>
          </td>
          <td>
            <span *ngIf="!!data?.shipToName">{{ data.shipToName }}</span>
            <br *ngIf="!!data?.shipToName" />
            <span>{{ data.shipToId }}</span>
          </td>
        </tr>
      </tbody>
    </table>
    <table
      aria-describedby="ordersTable"
      id="applyOrdersTable"
      class="credits-table table table-striped"
    >
      <tr class="sample-header">
        <th scope="col"></th>
        <th scope="col"></th>
      </tr>
      <tr *ngFor="let data of ordersList; let i = index">
        <td class="two-column-lay header">
          {{ "accountOrders.date_label" | cxTranslate }}
        </td>
        <td class="two-column-lay">
          {{ data?.placedDateObj | date: "dd/MM/yyyy" }}
        </td>
        <td class="two-column-lay header">
          {{ "accountOrders.order_no_label" | cxTranslate }}
        </td>
        <td class="two-column-lay">
          <a
            [routerLink]="['/my-account/order', data?.guid]"
            href="javascript: void(0);"
          >
            {{ data?.code }}</a
          >
        </td>
        <td class="two-column-lay header">
          {{ "accountOrders.order_status_label" | cxTranslate }}
        </td>
        <td class="two-column-lay status-txt">{{ data?.statusDisplay }}</td>
        <td class="two-column-lay header">
          {{ "accountOrders.order_po_num_label" | cxTranslate }}
        </td>
        <td
          class="two-column-lay po-size"
          [ngClass]="{
            'po-more-list':
              !!data?.purchaseOrderNumber &&
              data?.purchaseOrderNumber?.length > 20
          }"
        >
          {{ data?.purchaseOrderNumber }}
        </td>
        <td class="two-column-lay header">
          {{ "accountOrders.order_ship_to_label" | cxTranslate }}
        </td>
        <td
          class="two-column-lay shipTo-height"
          [ngClass]="{ last: !!data?.shipToName === true }"
        >
          <span *ngIf="!!data?.shipToName">{{ data?.shipToName }}</span>
          <br *ngIf="!!data?.shipToName" />
          <span>{{ data?.shipToId }}</span>
        </td>
      </tr>
    </table>
  </section>
  <!--table ends-->
  <div
    class="invoice-btngrps"
    *ngIf="!!ordersList && ordersList.length > 0 && isLoadingDone"
  >
    <button
      type="button"
      class="btn btn-primary oi-blue-button btnpaymentinvoicecredit"
      data-di-id="di-id-41a05d18-6b04a424"
      [routerLink]="['/my-account/orders']"
    >
      {{ "accountOrders.button_text" | cxTranslate }}
    </button>
  </div>
</div>
