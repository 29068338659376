import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { OutletPosition, provideOutlet } from '@spartacus/storefront';
import { FluDashboardBannerModule } from '../shared/modules/flu-dashboard-banner/flu-dashboard-banner.module';
import { ResourcesBannerModule } from '../shared/modules/resources-banner/resources-banner.module';
import { BrowseByTopicComponent } from './browse-by-topic/browse-by-topic.component';
import { DisplayImgComponent } from './display-img/display-img.component';
import { HealthcareProviderInformationComponent } from './healthcare-provider-information/healthcare-provider-information.component';
import { PatientEducationComponent } from './patient-education/patient-education.component';

@NgModule({
  declarations: [PatientEducationComponent, HealthcareProviderInformationComponent, BrowseByTopicComponent, DisplayImgComponent],
  imports: [
    CommonModule,
    ResourcesBannerModule,
    FluDashboardBannerModule,
    NgSelectModule,
    FormsModule
  ],
  providers: [
    provideOutlet({
      id: 'patientEducation-Horizontal',
      position: OutletPosition.REPLACE,
      component: PatientEducationComponent,
    }),
    provideOutlet({
      id: 'patientEducation-vertical',
      position: OutletPosition.REPLACE,
      component: BrowseByTopicComponent,
    })
  ],
})
export class ToolsAndResourcesModule { }
